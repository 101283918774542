import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import * as React from "react";

import { Card } from "../../scripts/components/card";

export const DeleteAccount: React.FC = () => {
  return (
    <Card>
      <Box textAlign="center" my={7}>
        <Typography variant="h5" component="div">
          <Box mb={3} fontWeight="bold">
            退会手続き
          </Box>
        </Typography>
        <Box mb={3}>
          <Typography variant="body2" component="div">
            退会に関しては以下の退会申請フォームより申請ください。
            <br />
            担当者が申請内容を確認し、アカウントの削除後、その旨をメールにて通知致します。
            <br />
            <Typography component="span" variant="body2" color="secondary">
              退会手続きをされますと登録データは全て削除されます
            </Typography>
            ので、ご注意ください。
          </Typography>
        </Box>
        <Box justifyContent="center" display="flex">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="medium"
            href="https://form.run/@propally-1619689827"
            target="_blank"
            rel="noopener"
          >
            退会申請フォームへ
          </Button>
        </Box>
      </Box>
    </Card>
  );
};
