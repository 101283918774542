import { makeStyles, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import * as React from "react";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Line, ComposedChart, ResponsiveContainer } from "recharts";
import { useRecoilValueLoadable } from "recoil";

import { Loading } from "../../../../pages/loading";
import { useDevice } from "../../../../utilities/sp/use_device";
import { YearlyCashflowResultsOnSelectedPeriod } from "../../../../view_models/balancesheet_selectors";
import { ColorScheme } from "../colorscheme";

export const PrBalanceChart: React.FC = () => {
  const { isMobile } = useDevice();

  const loadable = useRecoilValueLoadable(YearlyCashflowResultsOnSelectedPeriod);
  if (loadable.state === "loading") {
    return <Loading />;
  }
  if (loadable.state === "hasError") {
    throw loadable.contents;
  }
  const yearly_results_on_selected_period = loadable.contents;

  const data = yearly_results_on_selected_period.map((element) => {
    const income = Math.round(
      element.result.rent_income +
        element.result.management_fee_income +
        element.result.security_deposit +
        element.result.key_money +
        element.sales_amount_by_selling
    );

    const expence = Math.round(
      element.result.management_cost +
        element.result.building_management_cost +
        element.result.repair_fund_cost +
        element.result.misc_cost +
        (element.result.loan_interest + element.result.loan_principal + element.result.loan_prepayment) +
        element.result.outsourcing_fee_of_new_lease_contract +
        element.result.outsourcing_fee_of_updating_lease_contract +
        element.result.large_scale_repair_fund_cost +
        (element.price_of_properties - element.price_of_loan) + // 頭金
        element.price_of_misc_expences_at_bought +
        element.property_acquisition_tax +
        (element.result.misc_onetime_cost +
          element.result.property_tax +
          element.result.fire_insurance_cost +
          element.result.earthquake_insurance_cost) +
        (element.expenses_by_selling + element.loan_prepayment_by_selling) +
        element.result.tax_on_real_estates
    );

    return {
      year: element.month.year(),
      income: income,
      expence: 0 - expence,
      total_balance: income - expence,
    };
  });

  const Graph: React.FC = () => {
    return (
      <ResponsiveContainer width="100%" height={240}>
        <ComposedChart data={data} stackOffset="sign">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" />
          <YAxis unit="万" tickFormatter={(props) => Math.ceil(props / 10000).toLocaleString()} />
          <Tooltip
            contentStyle={{
              background: ColorScheme.Black,
              borderRadius: 6,
              color: "white",
              textAlign: "center",
            }}
            formatter={(props) => props.toLocaleString()}
            content={<CustomTooltip />}
          />
          <Legend verticalAlign="bottom" align="left" layout="horizontal" />

          <Bar
            dataKey="income"
            name="収入"
            legendType="square"
            isAnimationActive={false}
            strokeWidth={3}
            fill={ColorScheme.PrBlue}
            stackId="month"
          />
          <Bar
            dataKey="expence"
            name="支出"
            legendType="square"
            isAnimationActive={false}
            strokeWidth={3}
            fill={ColorScheme.Strawberry}
            stackId="month"
          />
          <Line
            dataKey="total_balance"
            name="収支"
            legendType="square"
            isAnimationActive={false}
            strokeWidth={2}
            // fill={ColorScheme.Orange}
            stroke={ColorScheme.Orange}
            // dot={true}
            // activeDot
            connectNulls
          />
        </ComposedChart>
      </ResponsiveContainer>
    );
  };

  return isMobile ? (
    <Box style={{ overflowX: "scroll" }}>
      <Box width={yearly_results_on_selected_period.length * 25} mt={2}>
        {/* years * 25 px */}
        <Graph />
      </Box>
    </Box>
  ) : (
    <Graph />
  );
};

const useStyles = makeStyles((theme) => ({
  background: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.background.paper,
    padding: "2px 8px",
    borderRadius: 4,
  },
}));

const CustomTooltip = ({ active, payload }) => {
  const classes = useStyles();
  if (active && payload && payload.length) {
    return (
      <Box className={classes.background}>
        {/* 収入 */}
        <Typography variant="caption">{`${payload[0].name} : ${Math.round(
          payload[0].payload.income / 10000
        ).toLocaleString()}万円`}</Typography>

        {/* 支出 */}
        <Typography variant="caption">{`${payload[1].name} : ${Math.round(
          payload[1].payload.expence / 10000
        ).toLocaleString()}万円`}</Typography>

        {/* 年間収支 */}
        <Typography variant="caption">{`年間収支 : ${Math.round(
          payload[2].payload.total_balance / 10000
        ).toLocaleString()}万円`}</Typography>

        {/* 累積収支 */}
      </Box>
    );
  }

  return null;
};
