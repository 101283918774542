import { makeStyles, Box } from "@material-ui/core";
import dayjs from "dayjs";
import * as React from "react";
import { useHistory } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { LeaseContract } from "../../../../../../api/generated";
import { PropallyApiFactory } from "../../../../../../module/custom_api_factory";
import { EditPropertyHistoryItem } from "../../../../../../scripts/components/renewal_v1/property/edit_property_history_item";
import { PropertyManagementDetailSnackbarState, useRequestInitialLoading } from "../../../../../../view_models/atoms";
import { SelectedProperty } from "../../../../../../view_models/property_selectors";

import { LeaseContractHistoryEditor } from "./history_edit";

const useStyles = makeStyles((theme) => ({
  background: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
    padding: 16,
    marginBottom: 8,
  },
  iconRoot: {
    padding: 0,
  },
  textLink: {
    width: 80,
    textAlign: "center",
  },
}));

interface props {
  leaseContract: LeaseContract;
}

export const LeaseContractHistoryItem: React.FC<props> = ({ leaseContract }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isShowEdior, setIsShowEditor] = React.useState(false);
  const requestInitialLoading = useRequestInitialLoading();
  // 物件詳細でのスナックバー表示
  const setManagementDetailSnackBarState = useSetRecoilState(PropertyManagementDetailSnackbarState);
  // 選択済み物件取得
  const selected_property = useRecoilValue(SelectedProperty);

  // 編集ボタンクリック時の処理
  const onClickEdit = React.useCallback(() => {
    setIsShowEditor(true);
  }, []);
  // 削除ボタンクリック時の処理
  const onClickDelete = React.useCallback(async () => {
    const fp = PropallyApiFactory();
    try {
      const response = await fp.v1PropertiesPropertyIdLeaseContractsLeaseContractIdDelete(
        selected_property.id,
        leaseContract?.id,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        requestInitialLoading();
      }
    } catch (e) {
      console.error(e);
      setManagementDetailSnackBarState({
        message: "物件情報を変更できませんでした",
        status: "error",
        isOpen: true,
      });
      history.push("/management/detail");
    }
  }, [selected_property.id, selected_property.lease_contracts]);

  const historyContents = React.useMemo(() => {
    // 開始日・終了日
    const startToEndMonth = `${dayjs(leaseContract.starts_at).format("YYYY/MM")} ~ ${dayjs(
      leaseContract.ends_at
    ).format("YYYY/MM")}`;
    // 家賃収入
    const monthlyFee = leaseContract?.monthly_fee ? `${leaseContract?.monthly_fee.toLocaleString()}円` : "-円";
    const monthlyManagementFee = leaseContract?.monthly_management_fee
      ? `${leaseContract?.monthly_management_fee.toLocaleString()}円`
      : "-円";

    // 賃貸借契約開始時における収入 / 賃貸借契約開始時における支出
    const typeOfIncomeBeginningContractMap = {
      KeyMoney: "礼金",
      SecurityDeposit: "更新料",
    };
    const typeOfIncomeBeginningContract = leaseContract?.type_of_the_income_of_the_beginning_of_contract
      ? typeOfIncomeBeginningContractMap[leaseContract?.type_of_the_income_of_the_beginning_of_contract]
      : "礼金";
    let income = "-";
    let typeOfOutcomeBeginningContract = "";
    let outcome = "-";
    if (leaseContract?.type_of_the_income_of_the_beginning_of_contract === "KeyMoney") {
      income = `${leaseContract?.key_money.toLocaleString()}ヶ月分`;
      typeOfOutcomeBeginningContract = "新規賃貸契約手数料";
      outcome = leaseContract?.outsourcing_fee_rate_of_new_lease_contract
        ? `${leaseContract.outsourcing_fee_rate_of_new_lease_contract.toLocaleString()}ヶ月分`
        : "-";
    } else if (leaseContract?.type_of_the_income_of_the_beginning_of_contract === "SecurityDeposit") {
      income = `${leaseContract?.security_deposit.toLocaleString()}ヶ月分`;
      typeOfOutcomeBeginningContract = "賃貸契約更新時手数料";
      outcome = leaseContract?.outsourcing_fee_rate_of_updating_lease_contract
        ? `${leaseContract.outsourcing_fee_rate_of_updating_lease_contract.toLocaleString()}ヶ月分`
        : "-";
    }

    return [
      { label: "賃貸借契約の開始日・終了日", value: startToEndMonth },
      { label: "家賃収入 (月)", value: monthlyFee },
      { label: "管理費収入 (月)", value: monthlyManagementFee },
      { label: "賃貸借契約開始時における収入", value: typeOfIncomeBeginningContract },
      { label: typeOfIncomeBeginningContract, value: income },
      { label: typeOfOutcomeBeginningContract, value: outcome },
    ];
  }, [leaseContract]);

  return (
    <Box className={classes.background}>
      {isShowEdior ? (
        <LeaseContractHistoryEditor
          leaseContract={leaseContract}
          onCancel={() => setIsShowEditor(false)}
          onSave={() => setIsShowEditor(false)}
        />
      ) : (
        <EditPropertyHistoryItem contents={historyContents} onEdit={onClickEdit} onDelete={onClickDelete} />
      )}
    </Box>
  );
};
