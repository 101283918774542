import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { useHistory } from "react-router";

import OfferInvestmentImage from "../../../../../assets/image/offer_investment.png";
import OfferInvestmentNotRecievedImage from "../../../../../assets/image/offer_investment_not_recieved.png";
import { useGetInvestmentProposals } from "../../../../hooks/useGetInvestmentProposals";
import { NoContentView } from "../../../../scripts/components/renewal_v1/no_content";
import { OfferInvestmentCard } from "../../../../scripts/components/renewal_v1/offer/investment_card";
import CustomPagination from "../../../../scripts/components/renewal_v1/pagination";
import { calculatePaginationInvestmentProposal } from "../../../../utilities/renewal_v1/pagination";
import { Loading } from "../../../loading";

const useStyles = makeStyles({
  image: {
    width: 80,
    height: "auto",
  },
});

export const OffferInvestmentTab: FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const { investmentProposals, loading } = useGetInvestmentProposals();

  // ページネーション
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  // ページネーションのためのロジック
  const { currentInvestmentProposals, totalPages } = calculatePaginationInvestmentProposal(
    investmentProposals,
    currentPage
  );

  if (loading) {
    return <Loading />;
  }

  if (investmentProposals.length === 0) {
    return (
      <NoContentView
        mainMessage="まだ投資オファーが届いていません"
        subMessage={
          "年収や勤続年数が更新されている場合は\nぜひ情報も更新してください！\n最適なオファーが届きやすくなります。"
        }
        buttonText="アカウント情報を更新する"
        image={OfferInvestmentNotRecievedImage}
        onClick={() => history.push("/account")}
      />
    );
  }

  return (
    <Box>
      <Box pb={3} display="flex" alignItems="center">
        <img src={OfferInvestmentImage} alt="receiving_investment_offer" className={classes.image} />
        <Box>
          <Box mb={1}>
            <Typography variant="h4" color="primary">
              あなたに投資オファーが届いています！
            </Typography>
          </Box>
          <Typography variant="body2">
            あなたの情報をもとに、エージェントからオファーが届きました。気になるオファーがあれば、積極的に話を聞いてみましょう。
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2}>
        {currentInvestmentProposals.map((investmentProposal) => (
          <Grid item xs={12} sm={6} key={investmentProposal.id}>
            <OfferInvestmentCard
              profileImagePath={investmentProposal.agent?.agent_profile_image?.image_url || ""}
              proposalDate={investmentProposal?.proposal_date}
              companyImagePath={investmentProposal?.agent?.company?.company_logo_image?.image_url || ""}
              companyName={investmentProposal?.agent?.company?.name}
              representativeName={investmentProposal?.agent?.family_name + investmentProposal?.agent?.first_name}
              shortAppealMessage={investmentProposal?.agent.company.short_appeal_message}
              proposalMessage={investmentProposal?.proposal_content}
              isUserApproved={investmentProposal?.is_user_approved}
              isNew={!investmentProposal?.is_user_read}
              isRead={investmentProposal?.is_user_read}
              onClick={() => history.push(`/investment/${investmentProposal.id}`)}
            />
          </Grid>
        ))}
      </Grid>
      <Box pt={2} pb={12}>
        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </Box>
    </Box>
  );
};
