import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import * as React from "react";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 48,
    width: "100%",
    marginBottom: 4,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  documentIcon: {
    marginRight: 12,
    color: theme.palette.text.secondary,
  },
  arrowIcon: {
    width: 16,
    height: 16,
  },
}));

interface props {
  title: string;
  value?: string;
  isDocument?: boolean;
  showFinalTaxReturn?: boolean;
  hasDocument?: boolean;
  documentType?: number;
}

export const AccordionDetailItemRow: React.FC<props> = ({
  title,
  value,
  isDocument = false,
  showFinalTaxReturn = false,
  hasDocument = false,
  documentType,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const handleNavigation = () => {
    if (isDocument) {
      // ページ遷移のロジックをここに追加
      history.push(`/property_document/${documentType}`);
    }
  };
  // ケース
  // - デフォルト: クリックできない、アイコンなし
  //   - 確定申告文字列あり
  // - ドキュメントあり: クリックできる、アイコンあり

  // ドキュメントありの場合
  if (isDocument) {
    return (
      <Box className={classes.root} onClick={handleNavigation}>
        {hasDocument ? (
          <Box display="flex" alignItems="center">
            <DescriptionOutlinedIcon className={classes.documentIcon} />
            <Typography variant="h4" color="textSecondary">
              {title}
            </Typography>
          </Box>
        ) : (
          <Typography variant="h4" color="textSecondary">
            {title}
          </Typography>
        )}
        <IconButton>
          <ArrowForwardIosIcon classes={{ root: classes.arrowIcon }} />
        </IconButton>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Typography variant="h4" color="textSecondary">
        {title}
      </Typography>
      {showFinalTaxReturn ? (
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Typography variant="body1" color="textPrimary">
            {value}
          </Typography>
          <Typography variant="caption" color="textPrimary">
            確定申告の際に利用できます
          </Typography>
        </Box>
      ) : (
        <Typography variant="body1" color="textPrimary">
          {value}
        </Typography>
      )}
    </Box>
  );
};
