import { makeStyles, Box } from "@material-ui/core";
import dayjs from "dayjs";
import * as React from "react";
import { useHistory } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { MonthlyCashflowResult } from "../../../../../../api/generated";
import { PropallyApiFactory } from "../../../../../../module/custom_api_factory";
import { EditPropertyHistoryItem } from "../../../../../../scripts/components/renewal_v1/property/edit_property_history_item";
import { PropertyManagementDetailSnackbarState, useRequestInitialLoading } from "../../../../../../view_models/atoms";
import { SelectedProperty } from "../../../../../../view_models/property_selectors";

import { costType } from "./history";
import { ManagementCostHistoryEditor } from "./history_editor";

const useStyles = makeStyles((theme) => ({
  background: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
    padding: 16,
    marginBottom: 8,
  },
}));

export const ManagementCostHistoryItem: React.FC<{
  monthlyCashflowResult: MonthlyCashflowResult;
  costType: costType;
}> = ({ monthlyCashflowResult, costType }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isShowEdior, setIsShowEditor] = React.useState(false);
  const requestInitialLoading = useRequestInitialLoading();
  // 物件詳細でのスナックバー表示
  const setManagementDetailSnackBarState = useSetRecoilState(PropertyManagementDetailSnackbarState);
  // 選択済み物件取得
  const selected_property = useRecoilValue(SelectedProperty);

  // 編集ボタンクリック時の処理
  const onClickEdit = React.useCallback(() => {
    setIsShowEditor(true);
  }, []);
  // 削除ボタンクリック時の処理
  const onClickDelete = React.useCallback(async () => {
    const fp = PropallyApiFactory();
    const costTypeKeyMap = {
      賃貸管理費: "management_cost",
      建物管理費: "building_management_cost",
      修繕積立金: "repair_fund_cost",
      その他経費: "misc_cost",
    };
    const costTypeKey = costTypeKeyMap[costType];
    try {
      const response = await fp.v1PropertiesPropertyIdMonthlyCashflowResultsPatch(
        selected_property.id,
        {
          month: monthlyCashflowResult.month,
          [costTypeKey]: null,
        },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        requestInitialLoading();
      }
    } catch (e) {
      console.error(e);
      setManagementDetailSnackBarState({
        message: "物件情報を変更できませんでした",
        status: "error",
        isOpen: true,
      });
      history.push("/management/detail");
    }
  }, [costType, monthlyCashflowResult.month, selected_property.id]);

  const getCostByType = (costType: string, data: MonthlyCashflowResult) => {
    switch (costType) {
      case "賃貸管理費":
        return data.management_cost;
      case "建物管理費":
        return data.building_management_cost;
      case "修繕積立金":
        return data.repair_fund_cost;
      case "その他経費":
        return data.misc_cost;
      default:
        return 0;
    }
  };

  if (getCostByType(costType, monthlyCashflowResult) === undefined) {
    return null;
  }

  return (
    <Box className={classes.background}>
      {isShowEdior ? (
        <ManagementCostHistoryEditor
          monthlyCashflowResult={monthlyCashflowResult}
          costType={costType}
          onCancel={() => setIsShowEditor(false)}
          onSave={() => setIsShowEditor(false)}
        />
      ) : (
        <EditPropertyHistoryItem
          contents={[
            { label: "支払開始月", value: dayjs(monthlyCashflowResult.month).format("YYYY/MM") },
            {
              label: "金額",
              value: getCostByType(costType, monthlyCashflowResult)
                ? `${getCostByType(costType, monthlyCashflowResult).toLocaleString()}円`
                : "-円",
            },
          ]}
          onEdit={onClickEdit}
          onDelete={onClickDelete}
        />
      )}
    </Box>
  );
};
