// TODO: スタイルの修正と仕様の確認
import { TextField, FormControl, InputAdornment, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import * as React from "react";

import CustomFormLabel from "./form_label";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    width: "100%",
  },
  adornment: {
    color: theme.palette.text.secondary,
  },
  inputLabel: {
    display: "flex",
    alignItems: "center",
  },
  inputRoot: {
    paddingRight: 0,
  },
  input: {
    padding: "10px 14px",
  },
}));

interface CustomFormProps {
  label: string;
  value: string;
  onChange: (_event: React.ChangeEvent<HTMLInputElement>) => void;
  helperText?: string;
  required?: boolean;
  placeHolder?: string;
}

const CustomForm: React.FC<CustomFormProps> = ({
  label,
  value,
  onChange,
  helperText = "",
  required = false,
  placeHolder = "",
}) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl} variant="outlined">
      <CustomFormLabel label={label} required={required} helperText={helperText} info="追加情報" />
      <TextField
        className={classes.textField}
        variant="outlined"
        value={value}
        onChange={onChange}
        placeholder={placeHolder}
        InputProps={{
          classes: { root: classes.inputRoot, input: classes.input },
          endAdornment: (
            <>
              <InputAdornment position="end" className={classes.adornment}>
                %
              </InputAdornment>
              {/* TODO: セレクトなのか？入力なのか？確認 */}
              <IconButton size="small">
                <ArrowDropDownIcon />
              </IconButton>
            </>
          ),
        }}
      />
    </FormControl>
  );
};

// サンプルコンポーネントでカスタムフォームを使用する
const PercentageFormExample: React.FC = () => {
  const [value, setValue] = React.useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <div>
      <CustomForm label="ラベル" value={value} onChange={handleChange} required placeHolder="10" />
      <CustomForm
        label="ラベル"
        value={value}
        onChange={handleChange}
        helperText="テキストが入ります"
        required
        placeHolder="10"
      />
    </div>
  );
};

export default PercentageFormExample;
