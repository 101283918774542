import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import React, { ChangeEvent, FC, useState } from "react";
import { useHistory } from "react-router";

import { useGetNearbyPrices } from "../../../hooks/useGetNearbyPrices";
import { useGetProperties } from "../../../hooks/useGetProperties";
import { NavigationPath } from "../../../scripts/components/renewal_v1/enums";
import NavigationBar from "../../../scripts/components/renewal_v1/navigation";
import CustomPagination from "../../../scripts/components/renewal_v1/pagination";
import { PropertyCardNearbyPrice } from "../../../scripts/components/renewal_v1/property/card_nearby_price";
import { CustomSelector } from "../../../scripts/components/renewal_v1/selecter";
import { PropertyOperationTemplate } from "../../../scripts/components/renewal_v1/template/property_operation_template";
import { calculatePaginationNearbyPrice } from "../../../utilities/renewal_v1/pagination";
import { useDevice } from "../../../utilities/sp/use_device";
import { Loading } from "../../loading";

const useStyles = makeStyles({
  content: {
    margin: "0 auto",
    maxWidth: 716,
  },
});

export const ManagementDetailNearbyPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const { isMobile } = useDevice();
  const { properties } = useGetProperties();
  const { fetchNearbyPrices, nearbyPrices, loading } = useGetNearbyPrices();

  const [selectedPropertyId, setSelectedPropertyId] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  const options = properties.map((property) => {
    return { value: property.id, label: property.name };
  });

  const selectNearbyPrices = (propertyId?: string) => {
    setSelectedPropertyId(propertyId);

    const id = parseInt(propertyId);
    if (!isNaN(id)) {
      fetchNearbyPrices(id);
      setCurrentPage(1);
    }
  };

  // ページネーションのためのロジック
  const { currentNearbyPrices, totalPages, propertiesPerPage } = calculatePaginationNearbyPrice(
    nearbyPrices,
    currentPage
  );

  return (
    <PropertyOperationTemplate title="周辺の成約事例" onBack={() => history.goBack()}>
      {/* TODO 画面内の余白もtemplateに入れるべきだが、他画面で共通で使用しているため、暫定的に画面内で対応 */}
      <Box p={3} className={classes.content}>
        <Box>
          <Typography variant="body2" align="center">
            周辺の成約事例を見ることで、
            <br />
            物件の対象エリアの相場感を確認することができます。
            <br />
            確認したい物件を選択してください。
          </Typography>

          <CustomSelector
            value={selectedPropertyId}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => selectNearbyPrices(e.target.value)}
            placeHolder="物件を選択してください"
            isPlaceHolderGray
            options={options}
            required
          />
        </Box>
        {loading ? (
          <Loading />
        ) : (
          <Box>
            {!isMobile && (
              <Box pt={1} pb={3} display="flex">
                <Typography variant="h2">周辺の成約事例</Typography>
              </Box>
            )}
            {nearbyPrices && !nearbyPrices.length ? (
              <Typography variant="body2">
                同エリアで情報が見つかりませんでした。
                <br />
                対象物件の市区町村が未登録の場合は登録してください。
              </Typography>
            ) : (
              <Grid container spacing={2}>
                {currentNearbyPrices.map((price, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Grid item xs={12} sm={6} key={index}>
                    <PropertyCardNearbyPrice
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      price={`${Math.round(Number(price.TradePrice) / 10000).toLocaleString()}`}
                      type={price.Type}
                      prefecture={price.Prefecture}
                      address={price.Municipality + price.DistrictName}
                      year={price.BuildingYear}
                      structure={price.Structure}
                      area={price.Area}
                      transactionPeriod={price.Period}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            {nearbyPrices && nearbyPrices.length > propertiesPerPage && (
              <Box pt={2} pb={12}>
                <CustomPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={(page: number) => setCurrentPage(page)}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>

      {/* TODO: PropertyOperationTemplateにPCフッターが内包されているため局所的な対応をしてしまっているが、汎用的な定義で再現されるように直す */}
      {isMobile && <NavigationBar path={NavigationPath.SUCCESSFUL_CASES} />}
    </PropertyOperationTemplate>
  );
};
