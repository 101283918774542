import { Box, Typography, makeStyles } from "@material-ui/core";
import * as React from "react";

import { StyledModal } from "../../../../scripts/components/renewal_v1/styled_modal";

const useStyles = makeStyles((theme) => ({
  messageContent: {
    padding: "24px 24px 0 24px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
    borderTop: "1px solid #ccc",
    padding: theme.spacing(2, 3, 2, 0),
  },
  button: {
    minWidth: 100,
  },
  description: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  link: {
    textDecoration: "none",
    cursor: "pointer",
    padding: "8px 7px",
    "&:hover": {
      borderBottom: "none",
    },
  },
}));

interface DraftPropertyModalProps {
  onClose: () => void;
  onClickSave: () => Promise<void>;
  onClickCancel: () => void;
}
export const DraftPropertyModal: React.FC<DraftPropertyModalProps> = ({ onClose, onClickSave, onClickCancel }) => {
  const classes = useStyles();
  const handleClick = React.useCallback(() => {
    document.body.style.overflow = "auto"; // Enable scrolling
    onClose();
  }, [onClose]);

  // スクロール制御
  React.useEffect(() => {
    document.body.style.overflow = "hidden"; // Disable scroll when modal is open
    return () => {
      document.body.style.overflow = "auto"; // Enable scroll when modal is unmounted
    };
  }, []);

  const handleCancel = React.useCallback(() => {
    onClickCancel();
    onClose();
  }, []);
  const handleSave = React.useCallback(() => {
    onClickSave();
    onClose();
  }, []);

  return (
    <StyledModal onClickOutside={handleClick}>
      <Box className={classes.messageContent}>
        <Box textAlign="center" mb={2}>
          <Typography variant="h3">
            入力情報を下書きとして
            <br />
            保存しますか？
          </Typography>
        </Box>
        <Typography className={classes.description}>
          「保存する」を押した場合、次回物件登録時に入力情報を利用することができます。前回保存した下書きは削除されます。
        </Typography>
      </Box>
      <Box className={classes.buttonContainer}>
        <Typography variant="button" color="primary" className={classes.link} onClick={handleCancel}>
          保存しない
        </Typography>
        <Typography variant="button" color="primary" className={classes.link} onClick={handleSave}>
          保存する
        </Typography>
      </Box>
    </StyledModal>
  );
};
