import dayjs, { Dayjs } from "dayjs";
import minMax from "dayjs/plugin/minMax";

import { LeaseContract } from "../api/generated/api";

import { PropertyHolder } from "./atoms";

dayjs.extend(minMax);

/**
 * @param target_month
 * @param contracts
 * @return Array<LeaseContract> ordered and filtered set of lease_contracts for a given target_month.
 */
export const getCurrentContracts = (target_month: Dayjs, contracts: Array<LeaseContract>): Array<LeaseContract> => {
  return contracts
    .filter((contract) => {
      const start_at = dayjs(contract.starts_at);
      const end_at = dayjs(contract.ends_at);
      const start_m = start_at.startOf("month");
      const end_m = end_at.endOf("month");
      return (
        (start_m.isSame(target_month) || start_m.isBefore(target_month)) &&
        (end_m.isSame(target_month) || end_m.isAfter(target_month))
      );
    })
    .sort((a, b) => {
      return dayjs(a.starts_at).unix() - dayjs(b.starts_at).unix();
    });
};

export const getCurrentContract = (contracts: Array<LeaseContract>): LeaseContract | null => {
  return (
    contracts.find((contract) => {
      const now = dayjs();
      const start_at = dayjs(contract.starts_at);
      const end_at = dayjs(contract.ends_at);
      return (start_at.isSame(now) || start_at.isBefore(now)) && (end_at.isSame(now) || end_at.isAfter(now));
    }) || null
  );
};

export const getMonthlyFeeFromContracts = (
  target_month: Dayjs,
  lease_contracts: Array<LeaseContract>,
  property_bought_at: Dayjs
): {
  monthly_fee: number;
  monthly_management_fee: number;
} => {
  const contracts = getCurrentContracts(target_month, lease_contracts);
  return contracts.reduce(
    (sum, contract) => {
      const start_at = dayjs(contract.starts_at);
      const end_at = dayjs(contract.ends_at);

      // 今月の計算開始日: 同月のうち、契約開始日, 物件購入日, 月初日のうち最もあとの日
      const start_day = dayjs.max(
        [start_at, property_bought_at, target_month.startOf("month")].filter((e) => {
          return e.format("YYYY/MM") === target_month.format("YYYY/MM");
        })
      );
      // 今月の計算終了日: 同月のうち、契約終了日, 月最終日のうち最もさきの日
      const end_day = dayjs.min(
        [end_at, target_month.endOf("month")].filter((e) => {
          return e.format("YYYY/MM") === target_month.format("YYYY/MM");
        })
      );

      const days_in_the_month = target_month.daysInMonth();
      const days_under_the_contract = end_day.diff(start_day, "day") + 1;
      return {
        monthly_fee: sum.monthly_fee + days_under_the_contract * (contract.monthly_fee / days_in_the_month),
        monthly_management_fee:
          sum.monthly_management_fee + days_under_the_contract * (contract.monthly_management_fee / days_in_the_month),
      };
    },
    { monthly_fee: 0, monthly_management_fee: 0 }
  );
};

export const getInitialPayments = (holders: Array<PropertyHolder>): number => {
  return holders.reduce((previous, current) => {
    return (
      previous +
      (current.property.total_price_of_property - current.property.loan_amount) +
      (current.property.misc_expences_at_bought || 0)
    );
  }, 0);
};
