import { useState, useEffect } from "react";

import { InvestmentProposal } from "../api/generated";
import { PropallyApiFactory } from "../module/custom_api_factory";

export const useGetInvestmentProposalDetails = (id: number) => {
  const [proposalData, setProposalData] = useState<InvestmentProposal>(null);
  const [loading, setLoading] = useState(false);

  const fp = PropallyApiFactory();

  const fetchInvestmentProposalById = async () => {
    try {
      setLoading(true);

      const data = await fp.v1InvestmentProposalsInvestmentProposalIdGet(id, { withCredentials: true });

      if (data.status === 200) {
        setProposalData(data.data);
        setLoading(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchInvestmentProposalById();
  }, []);

  return { fetchInvestmentProposalById, proposalData, loading };
};
