import { useEffect } from "react";
import { useSetRecoilState } from "recoil";

import { PropallyApiFactory } from "../module/custom_api_factory";
import { investmentUnreadCount } from "../view_models/proposal_unread_counts";

export const useUpdateInvestmentRead = (id: number) => {
  const setInvestmentUnreadCount = useSetRecoilState(investmentUnreadCount);

  const fp = PropallyApiFactory();
  const updateInvestmentReadById = async () => {
    // 既読にする
    try {
      const data = await fp.v1InvestmentProposalsInvestmentProposalIdReadPatch(id, { withCredentials: true });
      if (data.status === 200) {
        console.log("read investment proposals", data.data);
      }
    } catch (e) {
      console.error(e);
    }
    // 未読数を取得しatomの更新
    const response = await fp.v1InvestmentProposalUnreadCountGet({
      withCredentials: true,
    });
    setInvestmentUnreadCount(response.data as { unread_count: number });
  };

  useEffect(() => {
    updateInvestmentReadById();
  }, []);
};
