import { AccountRegisterFormSchema } from "./types";

export const DEFAULT_ACCOUNT_REGISTER_FORM_SCHEMA: AccountRegisterFormSchema = {
  birth_year: "",
  birth_month: "",
  address_prefecture: "",
  current_residence_type_id: "",
  home_loan_amount: "",
  real_estate_loan_amount: "",
  monthly_rent_payment_amount: "",
  job_category: null,
  job_industry: null,
  job_continuous_period: "",
  annual_income: null,
  financial_asset: "",
  interest_tag_ids: [],
  purpose_of_use_free_text: "",
  purpose_of_use_ids: [],
  inflow_route_free_text: "",
  inflow_route_ids: [],
  holding_property_count: null,
};
