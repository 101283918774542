import Box from "@material-ui/core/Box";
import * as React from "react";

import { useDevice } from "../../../../utilities/sp/use_device";
import { Sidebar } from "../../sidebar";
import { Navbar } from "../../sp/navbar";
import { NavigationPath } from "../enums";
import NavigationBar from "../navigation";

type Props = {
  children: React.ReactNode;
};

// 過去のバージョンのテンプレート
export const PreviousVersionTemplate: React.FC<Props> = ({ children }) => {
  const { isMobile } = useDevice();

  return (
    // リニューアルに合わせてroutes.tsから移行
    <Box display={isMobile ? "inherit" : "flex"}>
      {isMobile ? <Navbar /> : <Sidebar />}
      <Box
        mx={isMobile ? 2 : 6}
        mb={8}
        width={isMobile ? "auto" : "calc(100% - 330px)"}
        style={{ marginTop: "calc(96px + env(safe-area-inset-top))" }}
      >
        {children}
      </Box>
      {isMobile && <NavigationBar path={NavigationPath.SETTINGS} />}
    </Box>
  );
};
