import { AddNewPropertyFormSchema } from "./type";

export const Layout = [
  "未選択",
  "1R",
  "1K",
  "1LK/1LDK",
  "2L/2DK",
  "2LK/2LDK",
  "3K/3DK",
  "3LK/3LDK",
  "4K/4DK",
  "4LK/4LDK",
  "5K/5DK",
  "5LK/5LDK",
  "6K/6DK",
]


// TODO use the schema validation with api.yml
export const DateFieldNames = [
  "built_at",
  "bought_at",
  "payment_starts_at",
  "starts_at",
  "ends_at",
  "property_acquisition_tax_payment_month",
];
export const DoubleFieldNames = [
  "occupied_area_m2",
  "key_money",
  "security_deposit",
  "outsourcing_fee_rate_of_new_lease_contract",
  "outsourcing_fee_rate_of_updating_lease_contract",
  "loan_interest_rate_percentage",
];

export const PRICE_KEYS = {
  total: "total_price_of_property",
  tax: "priceof_tax",
  building: "priceof_building",
  facilities: "priceof_facilities",
  land: "priceof_land",
} as const;

export const sampleResponse = {
  buildings: [
    {
      id: 5276,
      name: "グレンモアスワA館",
      built_at_year: 1995,
      floor_number: 6,
      station: {
        name: "八王子",
        cd: 1131230,
        line_cd: 11312,
      },
      area: {
        name: "東京都八王子市諏訪町",
      },
    },
    {
      id: 90700,
      name: "グレンモアスワB館",
      built_at_year: 1995,
      floor_number: 5,
      station: {
        name: "八王子",
        cd: 1130620,
        line_cd: 11306,
      },
      area: {
        name: "東京都八王子市諏訪町",
      },
    },
    {
      id: 173606,
      name: "プレサンスKOBEグレンツ",
      built_at_year: 2017,
      floor_number: 15,
      station: {
        name: "新開地",
        cd: 9963006,
        line_cd: 99630,
      },
      area: {
        name: "兵庫県神戸市兵庫区新開地３丁目",
      },
    },

  ],
  total_count: 16,
};

export const sampleRoomResponse = {
  "building": {
    "id": 173606,
    "name": "プレサンスKOBEグレンツ",
    "built_at_year": 2017,
    "floor_number": 15,
    "station": {
      "name": "新開地",
      "cd": 9963006,
      "line_cd": 99630,
    },
    "area": {
      "name": "兵庫県神戸市兵庫区新開地３丁目",
    },
  },
  "rooms": [
    {
      "name": "201",
      "floor_plan": "1K",
      "m2": 22.95,
      "floor_number": 2,
    },
    {
      "name": "202",
      "floor_plan": "1K",
      "m2": 22.18,
      "floor_number": 2,
    },
  ],
  "total_count": 138,
}


export const DEFAULT_ADD_NEW_PROPERTY_FORM_SCHEMA: AddNewPropertyFormSchema = {
  user_id: "",
  property_type: "",
  name: "",
  prefecture: "",
  city: "",
  town: "",
  block: "",
  room_number: "",
  floor: "",
  railway: "",
  station: "",
  walk: "",
  bought_at: "",
  bought_at_day: "",
  built_at: "",
  structure: "",
  occupied_area_m2: "",
  layout: "",
  property_image: "",
  total_price_of_property: "",
  priceof_building: "",
  priceof_facilities: "",
  priceof_land: "",
  priceof_tax: "",
  financial_institution: "",
  loan_amount: "",
  payment_starts_at: "",
  payment_period: "",
  rental_management_company: "",
  management_type: "",
  sales_status: "",
  property_acquisition_tax: "",
  property_acquisition_tax_payment_month: "",
  misc_expences_at_bought: "",
  created_at: "",
  updated_at: "",
  discarded_at: "",
  loan_interest_rate_percentage: "",
  monthly_fee: "",
  monthly_management_fee: "",
  type_of_the_income_of_the_beginning_of_contract: "",
  // 月間収支 - 収入
  security_deposit: "",
  key_money: "",
  // 月間収支 - 支出
  building_management_cost: "",
  repair_fund_cost: "",
  management_cost: "",
  misc_cost: "",
  type_of_the_outcome_of_the_beginning_of_contract: "",
  outsourcing_fee_rate_of_new_lease_contract: "",
  outsourcing_fee_rate_of_updating_lease_contract: "",
  // 月間収支 - 賃貸借契約期間
  starts_at: "",
  starts_at_day: "",
  ends_at: "",
  ends_at_day: "",
}
