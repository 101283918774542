import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import * as React from "react";

import { Card } from "../../scripts/components/card";

export const LinktoResetPassword: React.FC = () => {
  return (
    <Card>
      <Box textAlign="center" my={7}>
        <Typography variant="h5" component="div">
          <Box mb={3} fontWeight="bold">
            パスワードリセット
          </Box>
        </Typography>
        <Box mb={3}>
          <Typography variant="body2" component="div">
            パスワードの変更をご希望の場合は、以下からパスワードをリセットし、再設定してください。
          </Typography>
        </Box>
        <Box justifyContent="center" display="flex">
          <Button type="submit" variant="contained" color="primary" size="medium" href="password_reset/new">
            パスワードリセットへ
          </Button>
        </Box>
      </Box>
    </Card>
  );
};
