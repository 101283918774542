import { Box, Typography } from "@material-ui/core";
import * as React from "react";

import ButtonGroup from "../../scripts/components/renewal_v1/button";
import CheckboxExample from "../../scripts/components/renewal_v1/checkbox";
import SmsVerificationForm from "../../scripts/components/renewal_v1/code";
import FormExample from "../../scripts/components/renewal_v1/form";
import Header from "../../scripts/components/renewal_v1/header";
import InputExample from "../../scripts/components/renewal_v1/input";
import CustomPagination from "../../scripts/components/renewal_v1/pagination";
import PercentageFormExample from "../../scripts/components/renewal_v1/percentage_form";
import { PropertyCardExample } from "../../scripts/components/renewal_v1/property/card";
import { PropertyCardImageExample } from "../../scripts/components/renewal_v1/property/card_image";
import SelecterExample from "../../scripts/components/renewal_v1/selecter";
import StepIndicator from "../../scripts/components/renewal_v1/step_indicator";
import CircleStepIndicator from "../../scripts/components/renewal_v1/step_indicator_circle";
import { EntranceTemplate } from "../../scripts/components/renewal_v1/template/entrance_template";

interface ComponentBoxProps {
  componentName: string;
  children: React.ReactNode;
}
const ComponentBox: React.FC<ComponentBoxProps> = ({ componentName, children }) => {
  return (
    <Box py={2}>
      <Typography color="primary" style={{ fontWeight: "bold" }}>
        {componentName}
      </Typography>
      {children}
    </Box>
  );
};
export const ComponentTest: React.FC = () => {
  // ヘッダー
  const handleSave = () => {
    alert("保存ボタンがクリックされました");
  };

  const handleBack = () => {
    alert("戻るボタンがクリックされました");
  };

  // ページネーション
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const totalPages = 10;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // SMS認証
  const handleSubmit = async (code: string) => {
    try {
      console.log("Submitted code:", code);
      // API呼び出しのロジックをここに追加
      // 例: await verifyCodeApiCall(code);
    } catch (e) {
      alert("認証コードの確認に失敗しました。再度お試しください。");
    }
  };
  return (
    <EntranceTemplate title="コンポーネントテスト">
      <ComponentBox componentName="ボタン">
        <ButtonGroup />
      </ComponentBox>
      <ComponentBox componentName="SMS認証">
        <SmsVerificationForm onSubmit={handleSubmit} />
      </ComponentBox>
      <ComponentBox componentName="タイポグラフィ">
        <Typography variant="h1">Headline/h1</Typography>
        <Typography variant="h2">Headline/h2</Typography>
        <Typography variant="h3">Headline/h3</Typography>
        <Typography variant="h4">Headline/h4</Typography>
        <Typography variant="h5">Headline/h5</Typography>
        <Typography variant="h6">Headline/h6</Typography>

        <Typography variant="body1">Body/body1</Typography>
        <Typography variant="body2">Body/body2</Typography>

        <Typography variant="caption">Caption/caption</Typography>
        <Typography variant="button">Button/button</Typography>
      </ComponentBox>
      <ComponentBox componentName="セレクト">
        <SelecterExample />
      </ComponentBox>
      <ComponentBox componentName="インプット">
        <InputExample />
      </ComponentBox>
      <ComponentBox componentName="チェックボックス">
        <CheckboxExample />
      </ComponentBox>
      <ComponentBox componentName="パーセンテージフォーム">
        <PercentageFormExample />
      </ComponentBox>
      <ComponentBox componentName="フォーム">
        <FormExample />
      </ComponentBox>
      <ComponentBox componentName="ステップインディケーター">
        <StepIndicator steps={6} currentStep={3} />
      </ComponentBox>
      <ComponentBox componentName="サークルステップインディケーター">
        <CircleStepIndicator steps={4} currentStep={1} />
      </ComponentBox>
      <ComponentBox componentName="ヘッダー">
        <Header title="シミュレーション設定" onSave={handleSave} onBack={handleBack} />
      </ComponentBox>
      <ComponentBox componentName="ページネーション">
        <CustomPagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      </ComponentBox>
      <ComponentBox componentName="物件カード">
        <PropertyCardExample />
      </ComponentBox>
      <ComponentBox componentName="物件カード画像のみ">
        <PropertyCardImageExample />
      </ComponentBox>
    </EntranceTemplate>
  );
};
