import { Box, FormGroup, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useRecoilState } from "recoil";

import { IdName } from "../../../api/generated";
import { GetMasterInflowRoute } from "../../../api/get_master_data";
import { CustomButton } from "../../../scripts/components/renewal_v1/button";
import { CustomCheckbox } from "../../../scripts/components/renewal_v1/checkbox";
import { CustomInput } from "../../../scripts/components/renewal_v1/input";
import { RegistrationTemplate } from "../../../scripts/components/renewal_v1/template/registration_template";
import { UserRegistrationsAccoutFormState } from "../../../view_models/atoms";

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      borderBottom: "none",
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
  },
}));

interface RegisterReasonPageProps {
  totalSteps: number;
  currentStep: number;
  onBack: () => void;
  validate: () => Promise<boolean>;
}

const RegisterReasonPage: React.FC<RegisterReasonPageProps> = ({ totalSteps, currentStep, onBack, validate }) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext();
  const [masterInflowRoutes, setMasterInflowRoutes] = useState<Array<IdName>>([]);
  const [selectedInflowRouteIds, setSelectedInflowRouteIds] = useState<number[]>([]);
  const [serverError, setSeverError] = useState<string>("");
  const [formData] = useRecoilState(UserRegistrationsAccoutFormState);

  const handleNext = async () => {
    const isValid = await validate();
    const data = getValues();
    if (isValid) {
      console.log(data);
    }
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      {
        const r = await GetMasterInflowRoute();
        if (isMounted) {
          if (!r.success) {
            return setSeverError(r.error);
          }
          setMasterInflowRoutes(r.inflowRoute);
        }
      }

      return undefined;
    })();
    // formDataが存在する場合に初期値を設定
    control.setValue("inflow_route_ids", formData.inflow_route_ids);
    return () => {
      isMounted = false;
    };
  }, []);

  const watchValues = useWatch({
    name: ["inflow_route_ids"],
    control: control,
  });

  useEffect(() => {
    setSelectedInflowRouteIds(watchValues.inflow_route_ids);
  }, [watchValues.inflow_route_ids]);

  // アンケート用の関数に変更が必要
  const handleInflowRouteId = (checkedId) => {
    const { inflow_route_ids: selected_ids } = getValues();
    return selected_ids?.includes(checkedId)
      ? selected_ids?.filter((id) => id !== checkedId)
      : [...(selected_ids ?? []), checkedId];
  };

  return (
    <RegistrationTemplate
      title="どこでアプリを知っていただけましたか？"
      canSelectMultiple
      subMessage="※情報は公開されません"
      totalSteps={totalSteps}
      currentStep={currentStep}
    >
      {/* エラーメッセージ表示用 */}
      {serverError && (
        <Typography variant="caption" className={classes.errorMessage}>
          {serverError}
        </Typography>
      )}
      <Box mt={4}>
        <Controller
          name="inflow_route_ids"
          control={control}
          rules={{ validate: (v) => v?.length > 0 || "最低1つの項目を選択してください。" }}
          render={({ ...field }) => (
            <FormGroup>
              {masterInflowRoutes.map((route) => (
                <CustomCheckbox
                  {...field}
                  key={route.id}
                  label={route.name}
                  error={!!errors.inflow_route_ids}
                  onChange={() => field.onChange(handleInflowRouteId(route.id))}
                  checked={field.value?.includes(route.id)}
                />
              ))}
            </FormGroup>
          )}
        />
        {/* その他を選択したときにinputフォームを表示 */}
        {selectedInflowRouteIds.includes(6) && (
          <Controller
            name="inflow_route_free_text"
            control={control}
            rules={{ required: "入力してください。" }}
            render={({ ...field }) => (
              <CustomInput
                {...field}
                label="その他"
                error={errors.inflow_route_free_text?.message}
                onChange={field.onChange}
              />
            )}
          />
        )}
      </Box>
      {errors && (
        <Typography variant="caption" className={classes.errorMessage}>
          {errors.inflow_route_ids?.message}
        </Typography>
      )}
      <Box mt={3}>
        <CustomButton type="submit" customVariant="filled" onClick={handleNext}>
          次へ
        </CustomButton>
        <Box display="flex" margin="32px auto" justifyContent="center">
          <Typography variant="button" className={classes.link} onClick={onBack}>
            戻る
          </Typography>
        </Box>
      </Box>
    </RegistrationTemplate>
  );
};

export default RegisterReasonPage;
