/**
 * Userスキーマに関するEnum getter関数
 */
import { UserWayToProposeEnum, DegreeOfConsiderationEnum } from "../generated/api";

import { KeyValueObject } from "./key_value_object";

export const WAY_TO_PROPOSE_VALUES = {
  email: UserWayToProposeEnum.NUMBER_0,
  line: UserWayToProposeEnum.NUMBER_1,
} as const;

export const DEGREE_OF_CONSIDERATION_KEY_VALUES: KeyValueObject[] = [
  { key: DegreeOfConsiderationEnum.Considering, value: "検討している" },
  { key: DegreeOfConsiderationEnum.NotConsidered, value: "検討していない" },
];
