import dayjs from "dayjs";

import { MonthlyCashflowResult } from "../../api/generated";

/*
  過去履歴
 */
export const getFormatStartMonth = (result: MonthlyCashflowResult): string => {
  return result?.month ? dayjs(result?.month).format("YYYY/MM") : "-年/-月";
};

export const getFormatCost = (cost: number): string => {
  return cost ? `${cost.toLocaleString()}円` : "-円";
};

export const getLatestMonthlyCashflowResult = (results: MonthlyCashflowResult[], key: string): MonthlyCashflowResult => {
  return results
    .filter((result) => result[key] !== undefined)
    .sort((a, b) => dayjs(b.month).valueOf() - dayjs(a.month).valueOf())[0];
};
