import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React = require("react");
import { useForm } from "react-hook-form";

import { PropallyApiFactory } from "../module/custom_api_factory";
import { EntranceTemplate } from "../scripts/components/templates/entrance_template";

const postNewPasswords = async (data: { password: string; password_confirmation: string }) => {
  const params = new URLSearchParams(location.search.substring(1));
  const password_reset_token = params.get("prt");
  const fp = PropallyApiFactory();
  try {
    await fp.v1NewPasswordsPost({
      ...data,
      password_reset_token: password_reset_token,
    });
    return { success: true, error: "" };
  } catch (e) {
    if (e.response?.status === 404) {
      return {
        success: false,
        error: "通信に失敗しました。もう一度やり直してください",
      };
    }
    if (e.response?.status === 422) {
      return {
        success: false,
        error: "パスワード変更に失敗しました。入力内容を確認してください",
      };
    }
    return {
      success: false,
      error: "ネットワークエラーです。しばらくたってからお試しください",
    };
  }
};

export const PasswordReset: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState<string>("");
  const { register, handleSubmit, errors } = useForm();
  // 英字と数字2種類以上利用し、記号の入力は !"#$%&''()\-^@[;: を許可し、８文字以上72文字以内
  const passwordRegex = /^(?=.*?[a-z])(?=.*?\d)[a-z\d!"#$%&'()\-^@[;:]{8,72}$/i;

  const onClickNewPasswords = async (data) => {
    if (data.password !== data.password_confirmation) {
      setError("パスワードが一致しません");
      return;
    }

    const result = await postNewPasswords(data);
    if (result.success) {
      setOpen(true);
    }
    setError(result.error);
  };

  return (
    <EntranceTemplate title="パスワードリセット" isFooter>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        message="パスワードリセットに成功しました。新しいパスワードでもう一度ログインしてください"
      />
      <form onSubmit={handleSubmit(onClickNewPasswords)}>
        <Box mb={2} textAlign="left" mx={2} display="flex" alignItems="center">
          <Box mr={1}>
            <Typography variant="caption" color="textSecondary">
              新しいパスワード
            </Typography>
            <Typography variant="caption" color="secondary">
              ※半角英字と数字8文字以上
            </Typography>
          </Box>
        </Box>
        <Box m={2}>
          <TextField
            type="password"
            placeholder="password"
            name="password"
            variant="outlined"
            size="small"
            fullWidth
            inputRef={register({
              required: true,
              maxLength: { value: 72, message: "72文字以内で入力してください" },
              minLength: { value: 8, message: "8文字以上で入力してください" },
              pattern: {
                value: passwordRegex,
                message: "形式が正しくありません",
              },
            })}
            error={Boolean(errors.password)}
            helperText={Boolean(errors.password) && errors.password.message}
          />
        </Box>
        <Box mb={2} textAlign="left" mx={2}>
          <Typography variant="caption" color="textSecondary">
            新しいパスワードをもう一度入力してください
          </Typography>
        </Box>
        <Box m={2}>
          <TextField
            type="password"
            placeholder="password"
            name="password_confirmation"
            variant="outlined"
            size="small"
            fullWidth
            inputRef={register({
              required: true,
              maxLength: { value: 72, message: "72文字以内で入力してください" },
              minLength: { value: 8, message: "8文字以上で入力してください" },
              pattern: {
                value: passwordRegex,
                message: "形式が正しくありません",
              },
            })}
            error={Boolean(errors.password_confirmation)}
            helperText={Boolean(errors.password_confirmation) && errors.password_confirmation.message}
          />
        </Box>
        {Boolean(error) && (
          <Box color="secondary.main" mb={3}>
            {error}
          </Box>
        )}
        <Box mb={4} mx={2}>
          <Button type="submit" variant="contained" size="large" color="primary" fullWidth>
            パスワードを変更する
          </Button>
        </Box>
      </form>
      <Link variant="caption" href="/login">
        ログイン
      </Link>
      <Typography variant="caption">に戻る</Typography>
    </EntranceTemplate>
  );
};
