import { useState } from "react";

import { PropallyApiFactory } from "../module/custom_api_factory";

export const useApproveAssesmentProposal = (id: number) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string>("");
  const fp = PropallyApiFactory();

  const approveAssesmentProposal = async () => {
    try {
      setLoading(true);

      const data = await fp.v1AssessmentProposalsAssessmentProposalIdApprovedPatch(
        Number(id),
        { is_approved: true },
        { withCredentials: true }
      );
      if (data.status === 200) {
        setLoading(false);
      }
    } catch (e) {
      console.error(e);
      setErrors(e);
    }
  };

  return { approveAssesmentProposal, loading, errors };
};
