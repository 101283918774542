import * as React from "react";

const ConsiderationPropertyIcon = ({ width = "49", height = "48", fill = "#424242" }) => (
  <svg width={width} height={height} viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M39.12 37.8C40 36.42 40.5 34.76 40.5 33C40.5 28 36.5 24 31.5 24C26.5 24 22.5 28 22.5 33C22.5 38 26.5 42 31.5 42C33.24 42 34.88 41.5 36.26 40.64L42.5 46.78L45.28 44L39.12 37.8ZM31.5 38C28.74 38 26.5 35.76 26.5 33C26.5 30.24 28.74 28 31.5 28C34.26 28 36.5 30.24 36.5 33C36.5 35.76 34.26 38 31.5 38ZM10.5 40V24H4.5L24.5 6L44.5 24H40.86C39.16 22.22 36.96 20.94 34.5 20.36L24.5 11.38L14.5 20.38V36H18.86C19.2 37.44 19.78 38.78 20.56 40H10.5Z"
      fill={fill}
    />
  </svg>
);
export default ConsiderationPropertyIcon;
