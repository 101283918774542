import { makeStyles, Box, Typography } from "@material-ui/core";
import * as React from "react";

import { EditPropertyHistoryItem } from "./edit_property_history_item";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
    padding: 16,
    marginBottom: 8,
  },
  historyLink: {
    display: "flex",
    justifyContent: "center",
    padding: "7px 8px",
  },
}));

interface props {
  title: string;
  contents: { label: string; value: string }[];
  onClickShowMoreHistory: () => void;
}

export const LatestHistoryContent: React.FC<props> = ({ title, contents, onClickShowMoreHistory }) => {
  const classes = useStyles();
  return (
    <Box mb={4}>
      <Box mb={1}>
        <Typography variant="h4" color="textPrimary">
          {title}
        </Typography>
      </Box>
      <EditPropertyHistoryItem contents={contents} />
      <Box className={classes.historyLink}>
        <Typography variant="button" color="primary" onClick={onClickShowMoreHistory}>
          編集
        </Typography>
      </Box>
    </Box>
  );
};
