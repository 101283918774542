import * as React from "react";

const QuestionCircleIcon = ({ width = "24", height = "24", fill = "currentColor" }) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 0C4.03711 0 0 4.03711 0 9C0 13.9629 4.03711 18 9 18C13.9629 18 18 13.9629 18 9C18 4.03711 13.9629 0 9 0ZM9 1.5C13.1514 1.5 16.5 4.84863 16.5 9C16.5 13.1514 13.1514 16.5 9 16.5C4.84863 16.5 1.5 13.1514 1.5 9C1.5 4.84863 4.84863 1.5 9 1.5ZM9 4.5C7.35059 4.5 6 5.85059 6 7.5H7.5C7.5 6.66211 8.16211 6 9 6C9.83789 6 10.5 6.66211 10.5 7.5C10.5 8.07422 10.1309 8.58398 9.58594 8.76562L9.28125 8.85938C8.66895 9.06152 8.25 9.64746 8.25 10.2891V11.25H9.75V10.2891L10.0547 10.1953C11.209 9.81152 12 8.71582 12 7.5C12 5.85059 10.6494 4.5 9 4.5ZM8.25 12V13.5H9.75V12H8.25Z"
      fill="#212121"
    />
  </svg>
);

export default QuestionCircleIcon;
