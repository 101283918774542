import { Box } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";

import LogoImageBlack from "../../../../assets/image/logo_black.png";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.tertiary.dark,
    padding: theme.spacing(7, 0, 4, 0),
    color: theme.palette.background.paper,
    textAlign: "center",
    position: "relative",
    bottom: 0,
    width: "100%",
  },
  link: {
    margin: theme.spacing(0, 3),
    color: theme.palette.background.paper,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  copyright: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.disabled,
  },
  logoImage: {
    width: "100px",
    height: "auto",
  },
}));

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <img src={LogoImageBlack} alt="logo_black" className={classes.logoImage} />
        <Box my={3}>
          <Link href="https://propally.co.jp/" className={classes.link} target="blank">
            運営会社
          </Link>
          |
          <Link href="https://propally.co.jp/privacypolicy.html" className={classes.link} target="blank">
            プライバシーポリシー
          </Link>
          |
          <Link href="https://form.run/@propally-1619963249" className={classes.link} target="blank">
            お問い合わせ
          </Link>
        </Box>
        <Typography variant="caption" className={classes.copyright}>
          Copyright © 2020 Propally Co.,Ltd. All rights reserved.
        </Typography>
      </Container>
    </footer>
  );
};

export default Footer;
