import Grid from "@material-ui/core/Grid";
import * as React from "react";

import { Label } from "../../../../utilities/form/label";

type Props = {
  isMobile: boolean;
  label?: string;
  required?: boolean;
};

export const GridItem: React.FC<Props> = ({ children, isMobile, label, required = false }) => {
  if (isMobile) {
    return (
      <Grid item xs={12}>
        {label && <Label name={label} required={required} optional={!required} />}
        {children}
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={6}>
        {label && <Label name={label} required={required} optional={!required} />}
      </Grid>
      <Grid item xs={6}>
        {children}
      </Grid>
    </>
  );
};
