import { AppBar, Toolbar, IconButton, Typography, Button, makeStyles, Grid, Box } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import * as React from "react";

import { DropDownMenu, DropDownMenuItem } from "./dropdown_menu";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper, // AppBarの背景色を変更
    color: theme.palette.text.primary,
  },
  title: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%)",
  },
  toolBar: {
    minHeight: 96,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: "0px 0px 16px 0px",
    alignItems: "flex-end",
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
  },
  saveButton: {
    color: theme.palette.primary.main,
    padding: "0 24px 0 0",
    height: 32,
  },
  icon: {
    height: 32,
    width: 32,
  },
  backButton: {
    color: theme.palette.primary.main,
    padding: 0,
    margin: "0px 24px 0px 24px",
  },
  settingsButton: {
    color: theme.palette.tertiary.main,
    height: 32,
    width: 32,
    padding: 0,
    marginRight: 24,
  },
}));
interface props {
  title: string;
  onSave?: () => void;
  onBack: () => void;
  menuItems?: DropDownMenuItem[];
  isModalOpen?: boolean;
}

const Header: React.FC<props> = ({ title, onSave, onBack, menuItems, isModalOpen = false }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchorEl(null);
  };
  return (
    <AppBar position="fixed" elevation={0} className={classes.root} style={{ zIndex: isModalOpen ? "auto" : 1300 }}>
      <Toolbar className={classes.toolBar}>
        <Grid container alignItems="center">
          <Grid item>
            <IconButton className={classes.backButton} onClick={onBack}>
              <ArrowBackIcon className={classes.icon} />
            </IconButton>
          </Grid>
          <Grid item xs>
            <Typography variant="h3" className={classes.title}>
              {title}
            </Typography>
          </Grid>
          <Grid item>
            {onSave && (
              <Button color="inherit" className={classes.saveButton} onClick={onSave}>
                <Typography variant="h3">保存</Typography>
              </Button>
            )}
            {menuItems?.length > 0 && (
              <Box>
                <IconButton edge="end" className={classes.settingsButton} onClick={handleSettingsClick}>
                  <MoreVertIcon />
                </IconButton>
                {/* <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleSettingsClose}>
                  <MenuItem onClick={onClickSetting}>設定</MenuItem>
                </Menu> */}
                <DropDownMenu anchorEl={anchorEl} onClose={handleSettingsClose} itemOptions={menuItems} />
              </Box>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
