import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import React = require("react");

type Props = {
  name: string;
  required?: boolean;
  optional?: boolean;
  width?: string | number;
};

export const Label: React.FC<Props> = ({ name, required, optional, width }) => {
  return (
    <Box display="flex" {...(width && { width: width })}>
      <Typography variant="body1" component="div">
        <Box fontWeight="bold">{name}</Box>
      </Typography>
      {required && (
        <Typography color="secondary" variant="body1" component="div">
          <Box fontWeight="bold">（必須）</Box>
        </Typography>
      )}
      {optional && (
        <Typography color="textSecondary" variant="body1" component="div">
          <Box fontWeight="bold">（任意）</Box>
        </Typography>
      )}
    </Box>
  );
};
