import { Box, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";

import { StatusIcon } from "../status_icon";

const useStyles = makeStyles((theme) => ({
  status: {
    display: "flex",
    alignItems: "center",
  },
}));

interface OccupancyStatusProps {
  label: string;
  isActive: boolean;
}

export const OccupancyStatus: React.FC<OccupancyStatusProps> = ({ label, isActive }) => {
  const classes = useStyles();
  return (
    <Box className={classes.status}>
      <StatusIcon active={isActive} />
      <Typography>{label}</Typography>
    </Box>
  );
};
