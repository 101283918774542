import { Box, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";

import theme from "./theme";

const useStyles = makeStyles(() => {
  const inputSpaceInBetween = 8; // フォームフィールド間のスペース (px)
  const digitNumber = 6; // 文字数
  const containerWidth = 330; // コンテナの横幅 (px)
  const inputEachWidth = (containerWidth - inputSpaceInBetween * (digitNumber - 1)) / digitNumber; // 各入力フィールドの幅

  return {
    input: {
      width: `${inputEachWidth}px`,
      height: "48px",
      textAlign: "center",
      fontSize: "2rem",
      "& input": {
        textAlign: "center",
        padding: "0", // デフォルトのパディングを削除
        height: "48px", // 高さを設定
        lineHeight: "48px", // 高さに合わせて行の高さを設定
        fontSize: theme.typography.h1.fontSize, // フォントサイズを設定
        "@media (max-width:600px)": {
          fontSize: "24px",
        },
      },
    },
    inputWrapper: {
      display: "flex",
      "& > *:not(:first-child)": {
        marginLeft: `${inputSpaceInBetween}px`, // 左側のマージンを設定
      },
    },
    boxContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%", // 幅を100%に設定
    },
    errorMessage: {
      color: theme.palette.error.main,
      marginTop: theme.spacing(1),
      fontSize: theme.typography.caption.fontSize,
    },
  };
});

interface SmsVerificationFormProps {
  onSubmit: (_code: string) => Promise<void>;
  error?: string;
}

const SmsVerificationForm: React.FC<SmsVerificationFormProps> = ({ onSubmit, error = "" }) => {
  const classes = useStyles();
  const { control, handleSubmit, setValue, getValues } = useForm();
  const inputsRef = React.useRef([]);

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (value.match(/^[0-9]$/)) {
      setValue(`code${index}`, value);
      if (index < 5) {
        inputsRef.current[index + 1].focus();
      } else {
        handleSubmit((data) => onSubmit(Object.values(data).join("")))();
      }
    } else if (value === "") {
      setValue(`code${index}`, "");
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !getValues(`code${index}`) && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(Object.values(data).join("")))}>
      <Box className={classes.boxContainer}>
        <Box className={classes.inputWrapper}>
          {Array.from({ length: 6 }).map((_, index) => (
            <Controller
              key={index}
              name={`code${index}`}
              control={control}
              defaultValue=""
              render={({ ...field }) => (
                <TextField
                  inputRef={(el) => (inputsRef.current[index] = el)}
                  {...field}
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  variant="outlined"
                  className={classes.input}
                  inputProps={{
                    maxLength: 1,
                  }}
                />
              )}
            />
          ))}
        </Box>
      </Box>
      {error && (
        <Typography className={classes.errorMessage}>
          ※認証番号が間違っています。有効期限が過ぎている場合は、新しい認証番号を送信してください。
        </Typography>
      )}
    </form>
  );
};

export default SmsVerificationForm;
