import { Box, ThemeProvider, Typography, makeStyles } from "@material-ui/core";
import * as React from "react";
import Carousel from "react-material-ui-carousel";
import { useHistory } from "react-router";

import Onboarding1 from "../../../assets/image/onboarding_1.png";
import Onboarding2 from "../../../assets/image/onboarding_2.png";
import Onboarding3 from "../../../assets/image/onboarding_3.png";
import Onboarding4 from "../../../assets/image/onboarding_4.png";
import { PropallyApiFactory } from "../../module/custom_api_factory";
import { CustomButton } from "../../scripts/components/renewal_v1/button";
import Footer from "../../scripts/components/renewal_v1/footer";
import theme from "../../scripts/components/renewal_v1/theme";
import { useDevice } from "../../utilities/sp/use_device";

const useStyles = makeStyles(() => ({
  carousel: {
    padding: theme.spacing(0, 0, 8, 0),
  },
  carouselItem: {
    height: "460px",
  },
  title: {
    color: theme.palette.primary.dark,
  },
  image: {
    maxWidth: "100%",
    width: "390px",
    height: "auto",
    marginBottom: theme.spacing(4),
  },
}));

const OnboardingPage: React.FC = () => {
  const classes = useStyles();
  const { isMobile } = useDevice();
  const history = useHistory();

  const items = [
    {
      title: "不動産の専門家から<br/>不動産投資について話を聞ける",
      image: Onboarding1,
      imageAlt: "Onboarding 1",
    },
    {
      title: "シミュレーション機能で<br/>正しい物件の価値がわかる",
      image: Onboarding2,
      imageAlt: "Onboarding 2",
    },
    {
      title: "持っている物件全ての<br/>収支を一元管理できる",
      image: Onboarding3,
      imageAlt: "Onboarding 3",
    },
    {
      title: "独自審査に通過した<br/>信頼できるエージェントのみ在籍",
      subText: "※独自審査を通過した：自社基準を満たしている",
      image: Onboarding4,
      imageAlt: "Onboarding 4",
    },
  ];

  const onClickRegister = React.useCallback(() => {
    history.push("/registrations");
  }, []);

  const onClickLogin = React.useCallback(() => {
    history.push("/login");
  }, []);

  // TODO 要恒久対応
  // 以下暫定MEMO
  // 事象：Recoilの「SessionState」で管理している「logged_in」がおそらく意図した挙動していなそうで、ログイン中でも「/」にアクセスするとマイページにリダイレクトせずに毎回オンボーディングが表示されてしまう件の暫定対応
  // 暫定対応: ユーザ取得APIをGETして200であればログイン状態であるので、その場合にはマイページに遷移させる
  React.useEffect(() => {
    (async () => {
      const user = await PropallyApiFactory().v1UserGet({
        withCredentials: true,
      });
      if (user.status == 200) history.push("/mypage");
    })();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        bgcolor={theme.palette.background}
        pt={10}
        textAlign="left"
        minHeight="100vh"
        display="flex"
        flexDirection="column"
      >
        <Box width={isMobile ? "auto" : "390px"} px={isMobile ? 3 : 0} pb={15} margin="0 auto" flexGrow={1}>
          <Box className={classes.carousel}>
            <Carousel
              autoPlay={false}
              swipe={true}
              animation="slide"
              indicators={true}
              indicatorIconButtonProps={{
                style: {
                  color: theme.palette.primary.light,
                  padding: "8px",
                },
              }}
              activeIndicatorIconButtonProps={{
                style: {
                  color: theme.palette.primary.main,
                  padding: "8px",
                },
              }}
            >
              {items.map((item, i) => (
                <Box key={i} className={classes.carouselItem} textAlign="center">
                  <Box>
                    <Typography variant="h2">
                      <span dangerouslySetInnerHTML={{ __html: item.title }} className={classes.title} />
                    </Typography>
                    {item.subText && (
                      <Box mt={1.5}>
                        <Typography variant="h6">{item.subText}</Typography>
                      </Box>
                    )}
                  </Box>
                  <Box mt={8}>
                    <img src={item.image} alt={item.imageAlt} className={classes.image} />
                  </Box>
                </Box>
              ))}
            </Carousel>
          </Box>
          <Box>
            <CustomButton type="button" customVariant="filled" onClick={onClickRegister}>
              会員登録
            </CustomButton>
          </Box>
          <Box mt={2}>
            <CustomButton type="button" customVariant="outlinedGray" onClick={onClickLogin}>
              ログイン
            </CustomButton>
          </Box>
        </Box>
        {!isMobile && <Footer />}
      </Box>
    </ThemeProvider>
  );
};

export default OnboardingPage;
