import * as React from "react";

const OwnPropertyIcon = ({ width = "49", height = "48", fill = "currentColor" }) => (
  <svg width={width} height={height} viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M38.4996 4H18.4996C16.2936 4 14.4996 5.794 14.4996 8V19.172L5.08557 28.586C4.80595 28.8657 4.61554 29.222 4.53841 29.6099C4.46127 29.9978 4.50088 30.3999 4.65222 30.7653C4.80356 31.1307 5.05984 31.443 5.38866 31.6628C5.71748 31.8826 6.10407 31.9999 6.49957 32V42C6.49957 42.5304 6.71029 43.0391 7.08536 43.4142C7.46043 43.7893 7.96914 44 8.49957 44H40.4996C41.03 44 41.5387 43.7893 41.9138 43.4142C42.2889 43.0391 42.4996 42.5304 42.4996 42V8C42.4996 5.794 40.7056 4 38.4996 4ZM22.4996 40H10.4996V28.828L16.4996 22.828L22.4996 28.828V40ZM38.4996 40H26.4996V32C26.8955 32.0009 27.2828 31.8841 27.6122 31.6645C27.9417 31.4449 28.1984 31.1323 28.3499 30.7665C28.5014 30.4008 28.5408 29.9982 28.4631 29.61C28.3855 29.2217 28.1942 28.8653 27.9136 28.586L18.4996 19.172V8H38.4996V40Z"
      fill={fill}
    />
    <path
      d="M22.5 12H26.5V16H22.5V12ZM30.5 12H34.5V16H30.5V12ZM30.5 20.062H34.5V24H30.5V20.062ZM30.5 28H34.5V32H30.5V28ZM14.5 30H18.5V34H14.5V30Z"
      fill={fill}
    />
  </svg>
);
export default OwnPropertyIcon;
