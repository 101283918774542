/**
 *  シミュレーション設定の定数定義
 */

import { SimulationConfigHouseRentModeEnum, SimulationConfigMarketPriceModeEnum } from "../../../../api/generated"

import { SimulationConfigDefaultType } from "./types"


/**
 * ①楽観パラメータ
 * - 価格
 * ・物件価格変動（毎年）
 * ・【1】％上昇
 * ・market_price_change_ends_atは【50】で固定
 *
 * - 収支
 * ・空室期間: 現在の賃貸借契約期間以降【3】回の契約ごとに【1】ヶ月発生
 * ・家賃変動: 現在の賃貸借契約期間以降、契約ごとに現在より【3】%上昇
 */
export const SimulationConfigOptimistic: SimulationConfigDefaultType = {
  // 価格
  market_price_change_down_value: 1,
  market_price_mode: SimulationConfigMarketPriceModeEnum.Percentage,
  market_price_mode_sign: "positive",
  market_price_change_ends_at: 50,
  // 収支
  house_rent_spare_months_for_N_contracts: 3,
  house_rent_spare_months: 1,
  house_rent_price_change_down_value: -3,
  house_rent_mode: SimulationConfigHouseRentModeEnum.Percentage,
  house_rent_sign: "positive",
}

/**
 * ②中立パラメータ
 * - 価格
 * ・物件価格変動（毎年）
 * ・変わらない
 * ・market_price_change_ends_atは【50】で固定
 *
 * - 収支
 * ・空室期間: 現在の賃貸借契約期間以降【2】回の契約ごとに【1】ヶ月発生
 * ・家賃変動: 現在の賃貸借契約期間以降、契約ごとに現在より変更なし
 */
export const SimulationConfigNeutral: SimulationConfigDefaultType = {
  // 価格
  market_price_change_down_value: 0,
  market_price_mode: SimulationConfigMarketPriceModeEnum.Percentage,
  market_price_mode_sign: "positive",
  market_price_change_ends_at: 50,
  // 収支
  house_rent_spare_months_for_N_contracts: 2,
  house_rent_spare_months: 1,
  house_rent_price_change_down_value: 0,
  house_rent_mode: SimulationConfigHouseRentModeEnum.Percentage,
  house_rent_sign: "positive",
}

/**
 * ③悲観パラメータ
 * - 価格
 * ・物件価格変動（毎年）
 * ・【1】％下落
 * ・market_price_change_ends_atは【50】で固定
 *
 * - 収支
 * ・空室期間: 現在の賃貸借契約期間以降【1】回の契約ごとに【1】ヶ月発生
 * ・家賃変動: 現在の賃貸借契約期間以降、契約ごとに現在より【3】%下落
 */

export const SimulationConfigPessimistic: SimulationConfigDefaultType = {
  // 価格
  market_price_change_down_value: 1,
  market_price_mode: SimulationConfigMarketPriceModeEnum.Percentage,
  market_price_mode_sign: "negative",
  market_price_change_ends_at: 50,
  // 収支
  house_rent_spare_months_for_N_contracts: 1,
  house_rent_spare_months: 1,
  house_rent_price_change_down_value: 3,
  house_rent_mode: SimulationConfigHouseRentModeEnum.Percentage,
  house_rent_sign: "negative",
}
