import { ThemeProvider } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import * as React from "react";

import { useDevice } from "../../../../utilities/sp/use_device";
import Footer from "../footer";
import theme from "../theme";

type Props = {
  children: React.ReactNode;
};

export const AccountIllustrationTemplate: React.FC<Props> = ({ children }) => {
  const { isMobile } = useDevice();

  return (
    <ThemeProvider theme={theme}>
      <Box
        bgcolor={theme.palette.background}
        pt={15}
        textAlign="center"
        minHeight="100vh"
        display="flex"
        flexDirection="column"
      >
        <Box width={isMobile ? "auto" : "390px"} px={3} margin="0 auto" flexGrow={1}>
          <Box mt={4} mb={15}>
            {children}
          </Box>
        </Box>
        {!isMobile && <Footer />}
      </Box>
    </ThemeProvider>
  );
};
