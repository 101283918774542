import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import React = require("react");

import { EntranceTemplate } from "../scripts/components/templates/entrance_template";

export const RegistrationsComplete: React.FC = () => {
  return (
    <EntranceTemplate title="新規登録完了" isFooter>
      <Box mb={6} mt={2}>
        <Typography variant="body2" color="textSecondary">
          ご登録いただいたメールアドレス宛に、
          <br />
          完了メールをお送りいたしました。
        </Typography>
      </Box>
      <Link variant="caption" href="/login">
        ログイン
      </Link>
      <Typography variant="caption">に戻る</Typography>
    </EntranceTemplate>
  );
};
