import { makeStyles, Box } from "@material-ui/core";
import dayjs from "dayjs";
import * as React from "react";
import { useHistory } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { MonthlyCashflowResult } from "../../../../../../api/generated";
import { PropallyApiFactory } from "../../../../../../module/custom_api_factory";
import { EditPropertyHistoryItem } from "../../../../../../scripts/components/renewal_v1/property/edit_property_history_item";
import { PropertyManagementDetailSnackbarState, useRequestInitialLoading } from "../../../../../../view_models/atoms";
import { SelectedProperty } from "../../../../../../view_models/property_selectors";

import { propertyInfoExtraCostType } from "./history";
import { PropertyInfoExtraHistoryEditor } from "./history_editor";

const useStyles = makeStyles((theme) => ({
  background: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
    padding: 16,
    marginBottom: 8,
  },
}));

export const PropertyInfoExtraHistoryItem: React.FC<{
  monthlyCashflowResult: MonthlyCashflowResult;
  costType: propertyInfoExtraCostType;
}> = ({ monthlyCashflowResult, costType }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isShowEdior, setIsShowEditor] = React.useState(false);
  const requestInitialLoading = useRequestInitialLoading();
  // 物件詳細でのスナックバー表示
  const setManagementDetailSnackBarState = useSetRecoilState(PropertyManagementDetailSnackbarState);
  // 選択済み物件取得
  const selected_property = useRecoilValue(SelectedProperty);

  // 編集ボタンクリック時の処理
  const onClickEdit = React.useCallback(() => {
    setIsShowEditor(true);
  }, []);
  // 削除ボタンクリック時の処理
  const onClickDelete = React.useCallback(async () => {
    const fp = PropallyApiFactory();
    const costTypeKeyMap = {
      "固定資産税・都市計画税": "property_tax",
      "設備修繕、大規模修繕費用": "large_scale_repair_fund_cost",
      その他一過性の支出: "misc_onetime_cost",
    };
    const costTypeKey = costTypeKeyMap[costType];
    try {
      const response = await fp.v1PropertiesPropertyIdMonthlyCashflowResultsPatch(
        selected_property.id,
        {
          month: monthlyCashflowResult.month,
          [costTypeKey]: null,
        },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        requestInitialLoading();
      }
    } catch (e) {
      console.error(e);
      setManagementDetailSnackBarState({
        message: "物件情報を変更できませんでした",
        status: "error",
        isOpen: true,
      });
      history.push("/management/detail");
    }
  }, [costType, monthlyCashflowResult.month, selected_property.id]);

  const getCostByType = (costType: string, data: MonthlyCashflowResult) => {
    switch (costType) {
      case "固定資産税・都市計画税":
        return data.property_tax;
      case "設備修繕、大規模修繕費用":
        return data.large_scale_repair_fund_cost;
      case "その他一過性の支出":
        return data.misc_onetime_cost;
      default:
        return 0;
    }
  };

  if (getCostByType(costType, monthlyCashflowResult) === undefined) {
    return null;
  }

  return (
    <Box className={classes.background}>
      {isShowEdior ? (
        <PropertyInfoExtraHistoryEditor
          monthlyCashflowResult={monthlyCashflowResult}
          costType={costType}
          onCancel={() => setIsShowEditor(false)}
          onSave={() => setIsShowEditor(false)}
        />
      ) : (
        <EditPropertyHistoryItem
          contents={[
            { label: "支払い月", value: dayjs(monthlyCashflowResult.month).format("YYYY/MM") },
            {
              label: "金額",
              value: getCostByType(costType, monthlyCashflowResult)
                ? `${getCostByType(costType, monthlyCashflowResult).toLocaleString()}円`
                : "-円",
            },
          ]}
          onEdit={onClickEdit}
          onDelete={onClickDelete}
        />
      )}
    </Box>
  );
};
