import { Box, Chip, Grid, Typography, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { useRecoilValueLoadable, useSetRecoilState } from "recoil";

import NoOwnPropetyImage from "../../../../../assets/image/no_property_own.png";
import { CustomIconButton } from "../../../../scripts/components/renewal_v1/custom_icon_button";
import { NoContentView } from "../../../../scripts/components/renewal_v1/no_content";
import CustomPagination from "../../../../scripts/components/renewal_v1/pagination";
import { PropertyCardImage } from "../../../../scripts/components/renewal_v1/property/card_image";
import {
  getActualYieldRateByCashflowResult,
  getPropertyAddressString,
  getYieldRate,
} from "../../../../utilities/get_property_address_string";
import {
  getLatestMonthlyFeeFromProperty,
  isPropertyCurrentInUse,
} from "../../../../utilities/renewal_v1/calculate_property";
import { calculatePagination } from "../../../../utilities/renewal_v1/pagination";
import { useDevice } from "../../../../utilities/sp/use_device";
import { SelectedPropertyId, SelectedPropertyIds } from "../../../../view_models/atoms";
import { PropertyIdToThisMonthlyBalanceSheet } from "../../../../view_models/balancesheet_selectors";
import { OwnedProperties } from "../../../../view_models/property_selectors";
import { Loading } from "../../../loading";

import { AddNewPropertyModal } from "./add_new_property_modal";

const useStyles = makeStyles((theme) => ({
  chip: {
    marginLeft: "8px",
    backgroundColor: theme.palette.primary[50],
  },
}));

export const OwnPropertyTab: React.FC = () => {
  const classes = useStyles();
  const { isMobile } = useDevice();
  const setSelectedPropertyId = useSetRecoilState(SelectedPropertyId);
  const setSelectedPropertyIds = useSetRecoilState(SelectedPropertyIds);
  const properties_loadable = useRecoilValueLoadable(OwnedProperties);
  const this_month_sheet_map_loadable = useRecoilValueLoadable(PropertyIdToThisMonthlyBalanceSheet(null));
  const history = useHistory();
  // ページネーション
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  if (properties_loadable.state === "loading") {
    return <Loading />;
  }
  if (properties_loadable.state === "hasError") {
    throw properties_loadable.contents;
  }
  if (this_month_sheet_map_loadable.state === "loading") {
    return <Loading />;
  }
  if (this_month_sheet_map_loadable.state === "hasError") {
    throw this_month_sheet_map_loadable.contents;
  }

  const this_month_sheet_map = this_month_sheet_map_loadable.contents;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const properties = properties_loadable.contents;

  // ページネーションのためのロジック
  const { currentProperties, totalPages } = calculatePagination(properties, currentPage);

  const onClickAddProperty = (options?: { showModal: boolean }) => {
    if (options.showModal) {
      setIsModalOpen(true);
    } else {
      // 保有中物件
      history.push("/add_new_property");
    }
  };

  // 物件選択
  const onClickPropertyDetail = (propertyId: number) => {
    setSelectedPropertyId(propertyId);
    setSelectedPropertyIds([propertyId]);
    history.push("/management/detail");
  };

  if (properties.length === 0) {
    return (
      <NoContentView
        mainMessage="物件が登録されていません"
        subMessage="保有している物件を登録して収支管理しましょう。"
        buttonText="保有物件を登録する"
        image={NoOwnPropetyImage}
        onClick={onClickAddProperty}
      />
    );
  }

  return (
    <Box>
      <Box pb={3} display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex">
          <Typography variant="h2">保有中の物件</Typography>
          <Chip label={`${properties.length}件`} className={classes.chip} size="medium" />
        </Box>
        {!isMobile && (
          <CustomIconButton onClick={() => onClickAddProperty({ showModal: true })}>
            <AddIcon />
          </CustomIconButton>
        )}
      </Box>
      <Grid container spacing={2}>
        {currentProperties.map((propertyHolder, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <PropertyCardImage
              image={propertyHolder.cover_image_url || ""}
              title={propertyHolder.property.name}
              address={getPropertyAddressString(propertyHolder)}
              rent={getLatestMonthlyFeeFromProperty(propertyHolder.lease_contracts)}
              purchasePrice={propertyHolder.property.total_price_of_property}
              yield_rate={getYieldRate(
                this_month_sheet_map.get(propertyHolder.id).result.rent_income +
                  this_month_sheet_map.get(propertyHolder.id).result.management_fee_income,
                propertyHolder.property.total_price_of_property
              )}
              actual_yield_rate={getActualYieldRateByCashflowResult(
                propertyHolder,
                this_month_sheet_map.get(propertyHolder.id).result
              )}
              isActive={isPropertyCurrentInUse(propertyHolder.lease_contracts)}
              showStatus={true}
              onClick={() => onClickPropertyDetail(propertyHolder.property.id)}
            />
          </Grid>
        ))}
      </Grid>
      <Box pt={2} pb={12}>
        <CustomPagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      </Box>
      <AddNewPropertyModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </Box>
  );
};
