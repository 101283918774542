import { makeStyles, Box, Typography } from "@material-ui/core";
import * as React from "react";

import OfferAchievement from "../icon/offer_achievement";
import OfferAdviceAndFollowup from "../icon/offer_advice_and_followup";
import OfferPartners from "../icon/offer_partners";
import OfferStrengthOfPartnerFinancialInstitutions from "../icon/offer_strength_of_partner_financial_institutions";
import OfferStrengthOfPropertiesForPurchase from "../icon/offer_strength_of_properties_for_purchase";
import OfferStrengthOfPropertiesForSale from "../icon/offer_strength_of_properties_for_sale";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 16,
  },
}));

interface props {
  title:
    | "会社の実績"
    | "販売物件/物件買取の強み"
    | "提携金融機関"
    | "販売後の物件管理"
    | "自社ならではのアピールポイント";
  content: string;
}

export const OfferDetailContent: React.FC<props> = ({ title, content }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box mb={1 / 2} display="flex" flexDirection="row" alignItems="center">
        <Box mr={1 / 2}>
          {(() => {
            switch (title) {
              case "会社の実績":
                return <OfferAchievement />;
              case "販売物件/物件買取の強み":
                return <OfferStrengthOfPropertiesForSale />;
              case "販売物件/物件買取の強み":
                return <OfferStrengthOfPropertiesForPurchase />;
              case "提携金融機関":
                return <OfferStrengthOfPartnerFinancialInstitutions />;
              case "販売後の物件管理":
                return <OfferPartners />;
              case "自社ならではのアピールポイント":
                return <OfferAdviceAndFollowup />;
              default:
                return null;
            }
          })()}
        </Box>
        {/* icon */}
        <Typography variant="h5" color="primary">
          {title}
        </Typography>
      </Box>
      <Typography variant="body1" color="textPrimary">
        {content}
      </Typography>
    </Box>
  );
};
