import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import React = require("react");

import { PropallyApiFactory } from "../module/custom_api_factory";
import { EntranceTemplate } from "../scripts/components/templates/entrance_template";

const postEmailConfirmations = async () => {
  const params = new URLSearchParams(location.search.substring(1));
  const email_confirmation_token = params.get("ect");
  const fp = PropallyApiFactory();
  try {
    await fp.v1EmailConfirmationsPost({ email_confirmation_token: email_confirmation_token });
    return { success: true, error: "" };
  } catch (e) {
    if (e.response?.status === 404 || e.response?.status === 400) {
      return { success: false, error: "メールアドレスの確認に失敗しました。もう一度やり直してください。" };
    }
    return { success: false, error: "ネットワークエラーです。しばらくたってからお試しください。" };
  }
};

export const EmailConfirmation: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState<string>("");

  const onLoadEmailConfirmations = async () => {
    const result = await postEmailConfirmations();

    if (result.success) {
      setOpen(true);
    } else {
      setError(result.error);
    }
  };

  React.useEffect(() => {
    onLoadEmailConfirmations();
  }, []);

  return (
    <EntranceTemplate title="メールアドレス変更完了">
      <Snackbar open={open} autoHideDuration={3000} message="メールアドレスを変更しました。" />
      {Boolean(error) && (
        <Box color="secondary.main" mb={3}>
          {error}
        </Box>
      )}
      <Link variant="caption" href="/">
        トップ
      </Link>
      <Typography variant="caption">に戻る</Typography>
    </EntranceTemplate>
  );
};
