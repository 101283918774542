import {Base64EncodedImage} from "../api/generated";

export const fileToBase64EncodedImage = async (file: File): Promise<Base64EncodedImage> => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  await new Promise(resolve => reader.onload = () => resolve());
  return {
    name: file.name,
    type: file.type,
    data: `${reader.result}`.split(",")[1],
  };
};
