import * as React from "react";

interface props {
  width?: string;
  height?: string;
  fill?: string;
}

const BuildingIcon: React.FC<props> = ({ width = "16", height = "20", fill = "currentColor" }): React.JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.0006 -1.2567e-05H1.0006C-0.102402 -1.2567e-05 0.000597776 -0.103013 0.000597776 0.999987V19C0.000597776 19.2652 0.105955 19.5196 0.293491 19.7071C0.481028 19.8946 0.735381 20 1.0006 20H15.0006C15.2658 20 15.5202 19.8946 15.7077 19.7071C15.8952 19.5196 16.0006 19.2652 16.0006 19V0.999987C16.0006 -0.103013 16.1036 -1.2567e-05 15.0006 -1.2567e-05ZM14.5006 18.5L1.5006 18.5V1.49999L14.5006 1.49999V18.5Z"
      // fill="#424242"
      fill={fill}
    />
    <path
      d="M4 4H7V6H4V4ZM9 4H12V6H9V4ZM4 8H7V10H4V8ZM9 8.031H12V10H9V8.031ZM4 12H7V14H4V12ZM9 12H12V14H9V12Z"
      // fill="#424242"
      fill={fill}
    />
  </svg>
);
export default BuildingIcon;
