import { IconButton, IconButtonProps, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import React from "react";

interface CustomIconButtonProps extends IconButtonProps {
  float?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 64,
    height: 64,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  float: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(11),
  },
}));

export const CustomIconButton: React.FC<CustomIconButtonProps> = ({ float, ...props }) => {
  const classes = useStyles();

  return (
    <IconButton
      className={classNames(classes.root, {
        [classes.float]: float,
      })}
      {...props}
    />
  );
};
