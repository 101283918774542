import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import React = require("react");
import styled from "styled-components";

const hasLink = { color: "#5772FF" } as React.CSSProperties;

const ErrorBox = styled(Paper)`
  &.MuiPaper-root {
    max-width: 434px;
    padding: 20px;
    background: black;
    color: white;
  }
`;

// TODO: enqueueSnackbarは使われていないようなので問題なければ削除する
const NotifyNetworkErrorContent: React.FC<{
  enqueueSnackbar?: Function;
  closeSnackbar: Function;
  ErrorMessage?: React.FC;
}> = ({ closeSnackbar, ErrorMessage }) => {
  return (
    <ErrorBox square={false} variant="outlined" elevation={3}>
      <Box
        onClick={() => {
          closeSnackbar("network_error");
        }}
      >
        <Typography align="right">
          <DeleteOutlinedIcon fontSize="small" />
        </Typography>
      </Box>
      {ErrorMessage ? (
        <ErrorMessage />
      ) : (
        <Box mt={-2}>
          通信に失敗しました。以下をお試しください。
          <ul>
            <li>インターネット接続をご確認ください。</li>
            <li>
              ページを
              {/* eslint-disable-next-line */}
              <a href="javascript:void(0);" style={hasLink} onClick={() => window.location.reload()}>
                更新
              </a>
              してください。
            </li>
            <li>再ログインしてください。</li>
          </ul>
          上記でも解決しない場合は
          <a href="https://form.run/@propally-1619963249" style={hasLink}>
            報告フォーム
          </a>
          にてご報告ください。
        </Box>
      )}
    </ErrorBox>
  );
};

export const NotifyNetworkError = (
  enqueueSnackbar: Function,
  closeSnackbar: Function,
  ErrorMessage?: React.FC
): void => {
  enqueueSnackbar(
    <NotifyNetworkErrorContent
      enqueueSnackbar={enqueueSnackbar}
      closeSnackbar={closeSnackbar}
      ErrorMessage={ErrorMessage}
    />, {
    key: "network_error",
    persist: true,
  });
};
