import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import dayjs = require("dayjs");
import { Dayjs } from "dayjs";
import * as React from "react";

import { PropertiesSelector } from "../../../scripts/components/property_selector/properties_selector";

type Props = {
  startingYear: Dayjs;
  endingYear: Dayjs;
  setStartingYear: Function;
  setEndingYear: Function;
};

export const MobileNavigation: React.FC<Props> = ({ startingYear, endingYear, setStartingYear, setEndingYear }) => {
  return (
    <>
      <Box>
        <Box mb={2}>
          <Typography component="div" variant="body2" color="textSecondary">
            <Box mb={1} ml={2} fontWeight="fontWeightBold">
              表示期間：
            </Box>
          </Typography>
          <Box display="flex" justifyContent="center">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              bgcolor="white"
              borderRadius={8}
              py={1.5}
              px={2}
              style={{ width: "70%" }}
            >
              <Typography
                color="textSecondary"
                onClick={() => {
                  setStartingYear(dayjs(startingYear).subtract(12, "month"));
                  setEndingYear(dayjs(endingYear).subtract(12, "month"));
                }}
              >
                <ArrowBackIosIcon fontSize="small" color="inherit" style={{ fontSize: 15 }} />
              </Typography>
              <Typography color="primary" variant="caption">
                <Box fontWeight="bold" mx={1}>
                  {dayjs(startingYear).format("YYYY/MM")}
                  　〜
                  {dayjs(endingYear).format("YYYY/MM")}
                </Box>
              </Typography>
              <Typography
                color="textSecondary"
                onClick={() => {
                  setStartingYear(dayjs(startingYear).add(12, "month"));
                  setEndingYear(dayjs(endingYear).add(12, "month"));
                }}
              >
                <ArrowForwardIosIcon fontSize="small" color="inherit" style={{ fontSize: 15 }} />
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box mb={2}>
          <Typography component="div" variant="body2" color="textSecondary">
            <Box mb={1} ml={2} fontWeight="fontWeightBold">
              表示物件：
            </Box>
          </Typography>
          <Box display="flex" justifyContent="center">
            <PropertiesSelector width="80%" />
          </Box>
        </Box>
      </Box>
    </>
  );
};
