import { makeStyles, Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import * as React from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { MonthlyCashflowResult } from "../../../../../../api/generated";
import { PropallyApiFactory } from "../../../../../../module/custom_api_factory";
import { CustomForm } from "../../../../../../scripts/components/renewal_v1/form";
import { useRequestInitialLoading, PropertyManagementDetailSnackbarState } from "../../../../../../view_models/atoms";
import { SelectedProperty } from "../../../../../../view_models/property_selectors";

import { insuranceType } from "./history";

const useStyles = makeStyles(() => ({
  textLink: {
    width: 80,
    textAlign: "center",
  },
}));

interface props {
  monthlyCashflowResult?: MonthlyCashflowResult;
  insuranceType: insuranceType;
  onCancel: () => void;
  onSave?: () => void;
  onCreate?: () => void;
}

// 履歴編集用コンポーネント
export const InsuranceInfoHistoryEditor: React.FC<props> = ({
  monthlyCashflowResult,
  insuranceType,
  onCancel,
  onSave,
  onCreate,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { control, errors, getValues } = useForm<any>();
  const requestInitialLoading = useRequestInitialLoading();
  // 選択済み物件取得
  const selected_property = useRecoilValue(SelectedProperty);
  // 物件詳細でのスナックバー表示
  const setManagementDetailSnackBarState = useSetRecoilState(PropertyManagementDetailSnackbarState);

  const fp = PropallyApiFactory();

  const onSubmit = async () => {
    const values = getValues();
    const sendData = {
      ...values,
      month: `${values.month}-01`,
    };

    try {
      const result = await fp.v1PropertiesPropertyIdMonthlyCashflowResultsPatch(selected_property.id, sendData, {
        withCredentials: true,
      });
      if (result.status === 200) {
        requestInitialLoading();
      }
    } catch (e) {
      console.error(e);
      setManagementDetailSnackBarState({
        message: "物件情報を変更できませんでした",
        status: "error",
        isOpen: true,
      });
      history.push("/management/detail");
    }
  };

  const handleSave = async () => {
    if (onSave) {
      // エラーの場合エディターの非表示後、詳細ページに戻るためこの順序
      onSave();
      await onSubmit();
    } else if (onCreate) {
      await onSubmit();
      onCreate();
    }
  };

  return (
    <Box>
      <Box mb={2}>
        <Controller
          name="month"
          control={control}
          rules={{ required: "入力してください。" }}
          defaultValue={dayjs(monthlyCashflowResult?.month).format("YYYY-MM") || ""}
          render={({ ...field }) => (
            <CustomForm
              {...field}
              label="開始月"
              required
              type="month"
              error={!!errors.month}
              errorText={errors.month?.message}
            />
          )}
        />
        {insuranceType === "火災保険" && (
          <>
            <Controller
              name="fire_insurance_cost"
              control={control}
              rules={{ required: "入力してください。" }}
              defaultValue={monthlyCashflowResult?.fire_insurance_cost || ""}
              render={({ ...field }) => (
                <CustomForm
                  {...field}
                  label="火災保険料"
                  required
                  error={!!errors.fire_insurance_cost}
                  errorText={errors.fire_insurance_cost?.message}
                  placeHolder="100000000"
                  unitSuffix="円"
                />
              )}
            />

            <Controller
              name="fire_insurance_holding_period"
              control={control}
              rules={{ required: "入力してください。" }}
              defaultValue={monthlyCashflowResult?.fire_insurance_holding_period || ""}
              render={({ ...field }) => (
                <CustomForm
                  {...field}
                  label="火災保険期間(年数)"
                  required
                  error={!!errors.fire_insurance_holding_period}
                  errorText={errors.fire_insurance_holding_period?.message}
                  placeHolder="10"
                  unitSuffix="年"
                />
              )}
            />
          </>
        )}
        {insuranceType === "地震保険" && (
          <>
            <Controller
              name="earthquake_insurance_cost"
              control={control}
              rules={{ required: "入力してください。" }}
              defaultValue={monthlyCashflowResult?.earthquake_insurance_cost || ""}
              render={({ ...field }) => (
                <CustomForm
                  {...field}
                  label="地震保険料"
                  required
                  error={!!errors.earthquake_insurance_cost}
                  errorText={errors.earthquake_insurance_cost?.message}
                  placeHolder="100000000"
                  unitSuffix="円"
                />
              )}
            />
            <Controller
              name="earthquake_insurance_holding_period"
              control={control}
              rules={{ required: "入力してください。" }}
              defaultValue={monthlyCashflowResult?.earthquake_insurance_holding_period || ""}
              render={({ ...field }) => (
                <CustomForm
                  {...field}
                  label="地震保険期間(年数)"
                  required
                  error={!!errors.earthquake_insurance_holding_period}
                  errorText={errors.earthquake_insurance_holding_period?.message}
                  placeHolder="10"
                  unitSuffix="年"
                />
              )}
            />
          </>
        )}
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Typography variant="button" color="primary" className={classes.textLink} onClick={onCancel}>
          キャンセル
        </Typography>
        <Typography variant="button" color="primary" className={classes.textLink} onClick={handleSave}>
          保存
        </Typography>
      </Box>
    </Box>
  );
};
