import { Box, Typography } from "@material-ui/core";
import * as React from "react";

import { MoneyYenIcon, BuildingIcon } from "../icon";

interface props {
  proposalPrice: number;
  propertyName: string;
}

/**
 * 物件査定額:
 * PropertyAppraisedPrice component displays the appraised price of a property
 * along with the property name. It uses Material-UI for styling and layout.
 *
 * @param {number} proposalPrice - The appraised price of the property.
 * @param {string} propertyName - The name of the property.
 */
export const PropertyAppraisedPrice: React.FC<props> = ({ proposalPrice, propertyName }) => {
  return (
    <>
      <Box display="flex" alignItems="center">
        {/* icon */}
        <Box style={{ marginRight: 2 }}>
          <MoneyYenIcon width="16" height="16" fill="#757575" />
        </Box>
        <Typography variant="body2">物件査定額</Typography>
      </Box>
      <Box display="flex" alignItems="flex-end">
        <Typography variant="h2" color="primary" style={{ marginRight: 4 }}>
          ¥
        </Typography>
        <Typography variant="h1" color="primary">
          {proposalPrice ? proposalPrice.toLocaleString() : ""}
        </Typography>
      </Box>
      <Box mt={1} display="flex" alignItems="center">
        {/* icon */}
        <Box style={{ marginRight: 2 }}>
          <BuildingIcon width="16" height="16" fill="#757575" />
        </Box>
        <Typography variant="body2">対象物件：</Typography>
      </Box>
      <Typography variant="h4" color="textPrimary" style={{ marginTop: 2 }}>
        {propertyName}
      </Typography>
    </>
  );
};
