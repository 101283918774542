import * as React from "react";
import { useLocation, useHistory } from "react-router-dom";

export const PreviousLocationContext = React.createContext(null);

export const PreviousLocationProvider = ({ children }) => {
  const [previousLocation, setPreviousLocation] = React.useState<string>(null);
  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    return history.listen((newLocation, action) => {
      if (action === "PUSH") {
        setPreviousLocation(location.pathname);
      }
    });
  }, [history, location]);

  return <PreviousLocationContext.Provider value={previousLocation}>{children}</PreviousLocationContext.Provider>;
};

export const usePreviousLocation = () => {
  return React.useContext(PreviousLocationContext);
};
