import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";
import { Settings } from "@material-ui/icons";
import ApartmentIcon from "@material-ui/icons/Apartment";
import HomeIcon from "@material-ui/icons/Home";
import MailIcon from "@material-ui/icons/Mail";
import SearchIcon from "@material-ui/icons/Search";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useRecoilValue } from "recoil";

import { AppEventTokenEnum, isFirstAccess, sendEventToAppMeasurementTool } from "../../../utilities/capacitor_logic";
import { UserState } from "../../../view_models/atoms";
import { assessmentUnreadCount, investmentUnreadCount } from "../../../view_models/proposal_unread_counts";

import { NavigationPath } from "./enums";

interface NavigationItem {
  path: NavigationPath;
  url: string;
  onClickLink: () => void;
}

interface NavigationBarProps {
  path: NavigationPath;
  onAddButtonClick?: () => void;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ path }) => {
  const history = useHistory();
  const investmentUnreadCountValue = useRecoilValue(investmentUnreadCount);
  const assessmentUnreadCountValue = useRecoilValue(assessmentUnreadCount);
  const user = useRecoilValue(UserState);

  const [currentPath, setCurrentPath] = useState(path);

  const handleClickEvent = (eventTokenFirst: AppEventTokenEnum, eventTokenSecond: AppEventTokenEnum) => {
    sendEventToAppMeasurementTool(isFirstAccess(user?.created_at) ? eventTokenFirst : eventTokenSecond);
  };

  const navigationItems: NavigationItem[] = [
    {
      path: NavigationPath.HOME,
      url: "/mypage",
      onClickLink: () =>
        handleClickEvent(AppEventTokenEnum.MypageFirstAccess, AppEventTokenEnum.MypageSecondOnwardsAccess),
    },
    {
      path: NavigationPath.PROPERTY_MANAGEMENT,
      url: "/management",
      onClickLink: () =>
        handleClickEvent(
          AppEventTokenEnum.PropertyManagementFirstAccess,
          AppEventTokenEnum.PropertyManagementSecondOnwardsAccess
        ),
    },
    {
      path: NavigationPath.OFFER,
      url: "/offer",
      onClickLink: () =>
        handleClickEvent(AppEventTokenEnum.OfferFirstAccess, AppEventTokenEnum.OfferSecondOnwardsAccess),
    },
    {
      path: NavigationPath.SUCCESSFUL_CASES,
      url: "/management/detail/nearby",
      onClickLink: () =>
        handleClickEvent(
          AppEventTokenEnum.PropertyNearbyPriceFirstAccess,
          AppEventTokenEnum.PropertyNearbyPriceSecondOnwardsAccess
        ),
    },
    {
      path: NavigationPath.SETTINGS,
      url: "/account",
      onClickLink: () =>
        handleClickEvent(AppEventTokenEnum.SettingFirstAccess, AppEventTokenEnum.SettingSecondOnwardsAccess),
    },
  ];

  const handleNavigation = (_, path: NavigationPath) => {
    setCurrentPath(path);
    const targetItem = navigationItems.find((v) => v.path === path);
    targetItem.onClickLink();
    history.push(targetItem.url);
  };

  // オファーアイコンのバッジ制御
  const offerBadgeVisible = useMemo(() => {
    return investmentUnreadCountValue?.unread_count > 0 || assessmentUnreadCountValue?.unread_count > 0;
  }, [investmentUnreadCountValue, assessmentUnreadCountValue]);

  const useStyles = makeStyles(() => ({
    root: {
      position: "relative",
      top: "-10px",
      backgroundColor: "#E7EAF6",
      color: "#000000DE",
      borderRadius: "50%",
      width: "82px",
      height: "82px",
      zIndex: 1,
      flex: "none",
      "&.Mui-selected": {
        backgroundColor: "#103894",
        color: "#FFFFFF",
      },
    },
  }));

  const OfferButton = ({ label, currentPath, badgeVisible, ...props }) => {
    const classes = useStyles();
    const selected = currentPath === NavigationPath.OFFER;

    return (
      <BottomNavigationAction
        label={label}
        className={`${classes.root} ${selected ? "Mui-selected" : ""}`}
        icon={
          <Badge variant="dot" color="error" overlap="rectangular" invisible={!badgeVisible}>
            <MailIcon />
          </Badge>
        }
        {...props}
      />
    );
  };

  return (
    <>
      <BottomNavigation value={currentPath} onChange={handleNavigation} showLabels>
        <BottomNavigationAction label="ホーム" icon={<HomeIcon />} />
        <BottomNavigationAction id="tour-step2" label="物件管理" icon={<ApartmentIcon />} />
        <OfferButton
          id="tour-step3"
          label="オファー"
          currentPath={currentPath}
          onClick={() => handleNavigation(null, NavigationPath.OFFER)}
          badgeVisible={offerBadgeVisible}
        />
        <BottomNavigationAction id="tour-step4" label="成約事例" icon={<SearchIcon />} />
        <BottomNavigationAction label="設定" icon={<Settings />} />
      </BottomNavigation>
    </>
  );
};

export default NavigationBar;
