import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  step: {
    width: 16,
    height: 16,
    borderRadius: "50%",
    backgroundColor: theme.palette.grey[200],
    margin: theme.spacing(0.5),
  },
  activeStep: {
    backgroundColor: theme.palette.primary.main,
  },
  text: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
}));

interface CircleStepIndicatorProps {
  steps: number;
  currentStep: number;
}

const CircleStepIndicator: React.FC<CircleStepIndicatorProps> = ({ steps, currentStep }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {Array.from({ length: steps }).map((_, index) => (
        <Box key={index} className={`${classes.step} ${index === currentStep - 1 ? classes.activeStep : ""}`} />
      ))}
    </Box>
  );
};

export default CircleStepIndicator;
