import { Box, Button, Card, CardContent, Modal, Typography, makeStyles, styled } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";

import { ConsiderationIcon, OwnPropertyIcon } from "../../../../scripts/components/renewal_v1/icon";

const ModalBody = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
});

const useStyles = makeStyles((theme) => ({
  cardContent: {
    width: 400,
    [theme.breakpoints.down("sm")]: {
      width: 342,
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-around",
    gap: 16,
  },
  button: {
    height: 109,
    padding: "16px 8px",
    borderRadius: "4px",
    border: `1px solid ${theme.palette.grey[300]}`,
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      width: 139,
    },
    [theme.breakpoints.up("md")]: {
      width: 168,
    },
  },
}));

interface AddNewPropertyModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export const AddNewPropertyModal: React.FC<AddNewPropertyModalProps> = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalBody>
        <Card>
          <CardContent className={classes.cardContent}>
            <Box textAlign="center" mb={2}>
              <Typography variant="h3">
                登録したい物件の
                <br />
                保有状況を教えてください
              </Typography>
            </Box>
            <Box className={classes.buttonContainer}>
              <Button className={classes.button} onClick={() => history.push("/add_new_property")}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <OwnPropertyIcon />
                  <Typography variant="button">保有中</Typography>
                </Box>
              </Button>
              <Button
                className={classes.button}
                variant="outlined"
                onClick={() => history.push("/add_new_property/consideration")}
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <ConsiderationIcon />
                  <Typography variant="button">検討中</Typography>
                </Box>
              </Button>
            </Box>
          </CardContent>
        </Card>
      </ModalBody>
    </Modal>
  );
};
