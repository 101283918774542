import * as React from "react";

import { PreviousVersionTemplate } from "../scripts/components/renewal_v1/template/previous_version_template";
import { Tab } from "../scripts/components/tab";

import { BasicInfo } from "./account/basic_info";
import { DeleteAccount } from "./account/delete_account";
import { LinktoResetPassword } from "./account/link_to_reset_password";
import { LogoutTab } from "./account/logout";
import { ReportTab } from "./account/report";
import { UpdateEmail } from "./account/update_email";

export const Account: React.FC<any> = () => {
  return (
    // リニューアルに合わせてroutes.tsから移行
    <PreviousVersionTemplate>
      <div>
        <h1>アカウント</h1>
        <Tab
          pages={[
            { label: "基本情報", page: BasicInfo },
            { label: "メールアドレスの変更", page: UpdateEmail },
            { label: "パスワードの変更", page: LinktoResetPassword },
            { label: "ログアウト", page: LogoutTab },
            { label: "不備報告・改善要望", page: ReportTab },
            { label: "退会", page: DeleteAccount },
          ]}
          defaultLabel="基本情報"
        />
      </div>
    </PreviousVersionTemplate>
  );
};
