// 想定売却価格とローン残高推移設定
import { Box, FormControl, IconButton, Input, MenuItem, Select, Typography, makeStyles } from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import dayjs from "dayjs";
import * as React from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";

import {
  ReserveFundPriceChange,
  SimulationConfig,
  SimulationConfigHouseRentModeEnum,
  SimulationConfigMarketPriceChangeAtBoughtModeEnum,
  SimulationConfigMarketPriceModeEnum,
  SimulationConfigWithIdProposedByEnum,
  UserUpdate,
  UserUpdateWayToProposeEnum,
  SimulationPatternEnum,
} from "../../../../api/generated";
import { SimulationConfigWithId, SimulationConfigWithIdHouseRentModeEnum } from "../../../../api/generated/api";
import { PropallyApiFactory } from "../../../../module/custom_api_factory";
import { CustomButton } from "../../../../scripts/components/renewal_v1/button";
import { CustomForm } from "../../../../scripts/components/renewal_v1/form";
import CustomFormLabel from "../../../../scripts/components/renewal_v1/form_label";
import { SimConfigForm } from "../../../../scripts/components/renewal_v1/property/simulation/sim_config_form";
import { StyledModal } from "../../../../scripts/components/renewal_v1/styled_modal";
import {
  PropertyManagementDetailSnackbarState,
  UserState,
  useRequestInitialLoading,
} from "../../../../view_models/atoms";
import { SelectedProperty } from "../../../../view_models/property_selectors";
import { SelectedPropertySimulationState } from "../../../../view_models/simulation_selectors";

import { SimulationConfigBalanceCustomFormType } from "./types";

const useStyles = makeStyles((theme) => ({
  modalContent: {
    maxHeight: "80vh", // Adjust as needed
    overflowY: "auto",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  icon: {
    width: 16,
    height: 16,
  },
  inputUnderline: {
    "&:before": {
      border: "none",
    },
    "&:after": {
      border: "none",
    },
  },
  block: {
    marginBottom: 24,
  },
  formBlock: {
    backgroundColor: theme.palette.tertiary.light,
    padding: "8px 16px",
    borderRadius: 8,
    marginBottom: 8,
    position: "relative",
    "&:hover $deleteButton": {
      display: "block",
    },
  },
  deleteButton: {
    display: "none",
    position: "absolute",
    top: -8,
    right: -8,
    border: `1px solid ${theme.palette.tertiary.main}`,
    borderRadius: "100%",
    padding: 0,
  },
  cancel: {
    display: "flex",
    justifyContent: "center",
    padding: 16,
    color: theme.palette.primary.main,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      borderBottom: "none",
    },
  },
}));

interface props {
  onClose: () => void;
  onClickSave: () => void;
  onClickCancel: () => void;
}

export const SimulationConfigCustomBalanceModal: React.FC<props> = ({ onClose, onClickSave, onClickCancel }) => {
  const classes = useStyles();
  const history = useHistory();

  const user = useRecoilValue(UserState);
  const selected_property = useRecoilValue(SelectedProperty);
  const setManagementDetailSnackBarState = useSetRecoilState(PropertyManagementDetailSnackbarState);
  const requestInitialLoading = useRequestInitialLoading();
  const isSelectedPropertyEnableBalanceProposalSimulation = useRecoilValue(SelectedPropertySimulationState);

  const { control, handleSubmit, errors, trigger, watch } = useForm<SimulationConfigBalanceCustomFormType>();

  // 家賃変動率の単位
  const house_rent_mode = watch("house_rent_mode");

  // 修繕積立金変動
  const {
    fields: reserve_fund_fields,
    append: reserve_fund_append,
    remove: reserve_fund_remove,
  } = useFieldArray({
    control,
    name: "reserve_fund_price_changes",
  });

  const handleClick = React.useCallback(() => {
    console.log("Add property button clicked");
    document.body.style.overflow = "auto"; // Enable scrolling
    onClose();
  }, [onClose]);
  // スクロール制御
  React.useEffect(() => {
    document.body.style.overflow = "hidden"; // Disable scroll when modal is open
    return () => {
      document.body.style.overflow = "auto"; // Enable scroll when modal is unmounted
    };
  }, []);

  // 複数フォームの初期値設定
  const simConfOfRepresentativeProperty = React.useMemo(() => {
    if (!selected_property)
      return {
        market_price_change_ends_at: 0,
        reserve_fund_price_changes: [],
        house_rent_price_change_down_value: 0,
      } as SimulationConfigWithId;
    return (
      selected_property.simulation_configs.find((p) => {
        if (isSelectedPropertyEnableBalanceProposalSimulation) {
          return p.proposed_by === SimulationConfigWithIdProposedByEnum.Realtor;
        }
        return p.proposed_by !== SimulationConfigWithIdProposedByEnum.Realtor;
      }) || selected_property.simulation_configs[0]
    );
  }, [selected_property]);

  const ReserveFundPriceChanges = simConfOfRepresentativeProperty.reserve_fund_price_changes;

  React.useEffect(() => {
    reserve_fund_remove();
    ReserveFundPriceChanges.forEach((e) => {
      reserve_fund_append(e, false);
    });
    if (ReserveFundPriceChanges.length === 0) {
      reserve_fund_append({}, false);
    }
  }, [selected_property]);

  const handleCancel = React.useCallback(() => {
    onClickCancel();
    onClose();
  }, []);

  const formTypeToApiType = (data: SimulationConfigBalanceCustomFormType): SimulationConfig => {
    let house_rent_mode: SimulationConfigHouseRentModeEnum;
    if (data.house_rent_mode === "percentage") {
      house_rent_mode = SimulationConfigHouseRentModeEnum.Percentage;
    } else {
      house_rent_mode = SimulationConfigHouseRentModeEnum.Real;
    }
    // market_price_change_down_valueの正負
    let house_rent_price_change_down_value: number;
    if (data.house_rent_sign === "positive") {
      house_rent_price_change_down_value = Number(data.house_rent_price_change_down_value) * -1;
    } else {
      house_rent_price_change_down_value = Number(data.house_rent_price_change_down_value);
    }
    return {
      // 初期値
      simulation_pattern: SimulationPatternEnum.カスタム,
      market_price_change_down_value: 0,
      market_price_mode: SimulationConfigMarketPriceModeEnum.Percentage,
      market_price_change_ends_at: 50,
      market_price_change_at_bought_value: 0,
      market_price_change_at_bought_mode: SimulationConfigMarketPriceChangeAtBoughtModeEnum.Percentage,
      // data to update
      house_rent_spare_months_for_N_contracts: Number(data.house_rent_spare_months_for_N_contracts),
      house_rent_spare_months: Number(data.house_rent_spare_months),
      house_rent_price_change_down_value: house_rent_price_change_down_value,
      house_rent_mode: house_rent_mode,
      house_rent_price_change_ends_at: Number(data.house_rent_price_change_ends_at),
      reserve_fund_price_changes: data.reserve_fund_price_changes?.map((change) => {
        return {
          month: dayjs(change.month).format("YYYY-MM") + "-01",
          amount: Number(change.amount),
        } as ReserveFundPriceChange;
      }),
    };
  };

  const handleSave = async (data: SimulationConfigBalanceCustomFormType) => {
    const isValid = await trigger();
    if (isValid) {
      const fp = PropallyApiFactory();
      // 既存のシミュレーション設定を取得
      const user_simconf = selected_property.simulation_configs.find((c) => {
        return c.proposed_by !== SimulationConfigWithIdProposedByEnum.Realtor;
      });

      // シミュレーション設定の更新
      const simconf = formTypeToApiType(data);
      try {
        const response = await fp.v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdPatch(
          selected_property.id,
          user_simconf.id,
          simconf,
          { withCredentials: true }
        );
        if (response.status === 200) {
          console.log("シミュレーション設定を変更しました");
        }
      } catch (e) {
        console.log(e);
        setManagementDetailSnackBarState({
          message: "設定を適用できませんでした",
          status: "error",
          isOpen: true,
        });
        history.push("/management/detail");
      }

      // 所得税率の更新
      try {
        const sendValues: UserUpdate = {
          ...user,
          income_tax_rate: Number(data.income_tax_rate),
          way_to_propose: user.way_to_propose as unknown as UserUpdateWayToProposeEnum,
        };
        const userPatch = await fp.v1UserPatch(sendValues, {
          withCredentials: true,
        });
        if (userPatch.status === 200) {
          // setUser(...sendValues);
          console.log("所得税率を変更しました");
        }
      } catch (e) {
        console.error(e);
        setManagementDetailSnackBarState({
          message: "設定を適用できませんでした",
          status: "error",
          isOpen: true,
        });
        history.push("/management/detail");
      }

      requestInitialLoading();
      onClickSave();
      onClose();
      setManagementDetailSnackBarState({
        message: "設定を適用しました",
        status: "success",
        isOpen: true,
      });
    }
  };

  return (
    <StyledModal onClickOutside={handleClick}>
      <form onSubmit={handleSubmit(handleSave)}>
        <Box className={classes.modalContent}>
          <Box className={classes.header}>
            <Typography variant="h3">詳細設定</Typography>
            <IconButton onClick={handleClick}>
              <CloseOutlinedIcon classes={{ root: classes.icon }} />
            </IconButton>
          </Box>
          <Box p={3}>
            <Typography variant="h3">収支変動</Typography>
            {/* 空室期間 */}
            <Box mt={2}>
              <Box mb={1}>
                <CustomFormLabel
                  label="空室期間"
                  info="購入後空室となる期間を予想します"
                  helperText="現在の賃貸借期間以降"
                  required
                />
              </Box>
              <Box mb={1}>
                <Controller
                  name="house_rent_spare_months_for_N_contracts"
                  control={control}
                  rules={{ required: "入力してください。" }}
                  defaultValue={simConfOfRepresentativeProperty.house_rent_spare_months_for_N_contracts}
                  render={({ ...field }) => (
                    <SimConfigForm
                      {...field}
                      error={!!errors.house_rent_spare_months_for_N_contracts}
                      errorText={errors.house_rent_spare_months_for_N_contracts?.message}
                      onChange={field.onChange}
                      placeHolder="0"
                      unitSuffix="回の賃貸借契約ごとに"
                    />
                  )}
                />
              </Box>
              <Box mb={1}>
                <Controller
                  name="house_rent_spare_months"
                  control={control}
                  rules={{ required: "入力してください。" }}
                  defaultValue={simConfOfRepresentativeProperty.house_rent_spare_months}
                  render={({ ...field }) => (
                    <SimConfigForm
                      {...field}
                      error={!!errors.house_rent_spare_months}
                      errorText={errors.house_rent_spare_months?.message}
                      onChange={field.onChange}
                      placeHolder="0"
                      unitSuffix="ヶ月発生(空室率0.00%)"
                    />
                  )}
                />
              </Box>
            </Box>

            {/* 家賃変動率 */}
            <Box className={classes.block}>
              <Box mb={1}>
                <CustomFormLabel label="家賃変動率" info="家賃の変動を予想します" required />
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <Controller
                  name="house_rent_price_change_down_value"
                  control={control}
                  rules={{ required: "入力してください。" }}
                  defaultValue={simConfOfRepresentativeProperty.house_rent_price_change_down_value > 0
                    ? simConfOfRepresentativeProperty.house_rent_price_change_down_value
                    : simConfOfRepresentativeProperty.house_rent_price_change_down_value * -1}
                  render={({ ...field }) => (
                    <SimConfigForm
                      {...field}
                      error={!!errors.house_rent_price_change_down_value}
                      errorText={errors.house_rent_price_change_down_value?.message}
                      onChange={field.onChange}
                      placeHolder="10"
                      unitSuffix={house_rent_mode === "percentage" ? "%" : "円"}
                    />
                  )}
                />
                <Controller
                  name="house_rent_mode"
                  control={control}
                  rules={{ required: "入力してください。" }}
                  defaultValue={simConfOfRepresentativeProperty.house_rent_mode === SimulationConfigWithIdHouseRentModeEnum.Real ? "yen" : "percentage"}
                  render={({ ...field }) => (
                    <Select
                      {...field}
                      onChange={field.onChange}
                      IconComponent={ExpandMoreIcon}
                      style={{ marginLeft: 8 }}
                      input={<Input classes={{ underline: classes.inputUnderline }} />}
                    >
                      <MenuItem value="percentage">%</MenuItem>
                      <MenuItem value="yen">円</MenuItem>
                    </Select>
                  )}
                />
              </Box>
              <Box mb={1}>
                <Controller
                  name="house_rent_sign"
                  control={control}
                  defaultValue={simConfOfRepresentativeProperty.house_rent_price_change_down_value > 0 ? "negative" : "positive"}
                  render={({ ...field }) => (
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        {...field}
                        onChange={field.onChange}
                        error={!!errors.house_rent_sign}
                        IconComponent={() => (
                          <Box p={1}>
                            <ExpandMoreIcon />
                          </Box>
                        )}
                      >
                        <MenuItem key="positive" value="positive">
                          上昇
                        </MenuItem>
                        <MenuItem key="negative" value="negative">
                          下落
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Box>
              <Box mb={1}>
                <Controller
                  name="house_rent_price_change_ends_at"
                  control={control}
                  rules={{ required: "入力してください。" }}
                  defaultValue={simConfOfRepresentativeProperty.house_rent_price_change_ends_at}
                  render={({ ...field }) => (
                    <SimConfigForm
                      {...field}
                      error={!!errors.house_rent_price_change_ends_at}
                      errorText={errors.house_rent_price_change_ends_at?.message}
                      onChange={field.onChange}
                      placeHolder="0"
                      unitSuffix="回目の契約から変動ストップ"
                    />
                  )}
                />
              </Box>
            </Box>
            {/* 修繕積立金 TODO 仕様確認の上要修正 */}
            {/*<Box className={classes.block}>*/}
            {/*  <Box mb={1}>*/}
            {/*    <CustomFormLabel label="修繕積立金" info="将来的な修繕積立金変動を予想します" />*/}
            {/*  </Box>*/}
            {/*  {reserve_fund_fields.map((field, index) => (*/}
            {/*    <Box className={classes.formBlock} key={"reserve_fund" + field.id}>*/}
            {/*      <IconButton className={classes.deleteButton} onClick={() => reserve_fund_remove(index)}>*/}
            {/*        <CloseOutlinedIcon />*/}
            {/*      </IconButton>*/}
            {/*      <Controller*/}
            {/*        name={`reserve_fund_price_changes[${index}].month`}*/}
            {/*        control={control}*/}
            {/*        rules={{ required: true }}*/}
            {/*        defaultValue={new Date()}*/}
            {/*        render={({ ...field }) => <DatePickerComponent label="支払い月" {...field} />}*/}
            {/*      />*/}

            {/*      <Controller*/}
            {/*        name={`reserve_fund_price_changes[${index}].amount`}*/}
            {/*        control={control}*/}
            {/*        rules={{ required: "入力してください。" }}*/}
            {/*        defaultValue={""}*/}
            {/*        render={({ ...field }) => (*/}
            {/*          <CustomForm*/}
            {/*            {...field}*/}
            {/*            label="支払い金額"*/}
            {/*            error={!!errors.reserve_fund_price_changes}*/}
            {/*            // errorText={errors.reserve_fund_price_changes?.[index]?.message}*/}
            {/*            onChange={field.onChange}*/}
            {/*            placeHolder="10000000"*/}
            {/*            unitSuffix="円"*/}
            {/*          />*/}
            {/*        )}*/}
            {/*      />*/}
            {/*    </Box>*/}
            {/*  ))}*/}
            {/*  <CustomButton*/}
            {/*    onClick={() => reserve_fund_append({ month: new Date(), amount: "" })}*/}
            {/*    customVariant="outlinedGray"*/}
            {/*  >*/}
            {/*    追加する*/}
            {/*  </CustomButton>*/}
            {/*</Box>*/}

            {/* 所得税率 */}
            <Box>
              <Controller
                name="income_tax_rate"
                control={control}
                defaultValue={user.income_tax_rate}
                render={({ ...field }) => (
                  <CustomForm
                    {...field}
                    label="所得税率"
                    error={!!errors.income_tax_rate}
                    errorText={errors.income_tax_rate?.message}
                    onChange={field.onChange}
                    info="あなたの所得税率を入力してください"
                    placeHolder="0"
                    unitSuffix="%"
                    isShort
                  />
                )}
              />
            </Box>
            <Box mt={4}>
              <CustomButton customVariant="filled" type="submit">
                設定を適用する
              </CustomButton>
              <Typography variant="button" className={classes.cancel} onClick={handleCancel}>
                キャンセル
              </Typography>
            </Box>
          </Box>
        </Box>
      </form>
    </StyledModal>
  );
};
