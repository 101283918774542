import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import * as React from "react";
import styled from "styled-components";

export class Page {
  label: string;

  page: React.FC;
}

export type Pages = {
  pages: Array<Page>;
  defaultLabel: string;
  Navigation?: React.ReactNode;
  isToggle?: boolean;
  defaultToggle?: boolean;
  disableToggle?: boolean;
  commonNoticeText?: string;
};

const TabItem = styled(Box)`
  cursor: pointer;
`;

export const Tab: React.FC<Pages> = ({ pages, defaultLabel, Navigation, isToggle, defaultToggle, commonNoticeText }) => {
  const [activeLabel, setLabel] = React.useState(defaultLabel);
  const [open, setOpen] = React.useState<boolean>(isToggle ? defaultToggle : true);
  const targetPage = pages.find((page) => {
    return page.label === activeLabel;
  });
  const ActivePage = targetPage.page;

  const ToggleNavigation: React.FC = () => {
    return (
      <TabItem display="flex" alignItems="center" onClick={() => setOpen(!open)}>
        <Typography variant="body2" color="primary" component="div">
          <Box mr={0.5} fontWeight="bold">
            {open ? "閉じる" : "開く"}
          </Box>
        </Typography>
        {open ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
      </TabItem>
    );
  };
  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" {...((!isToggle || open) && { mb: 3 })}>
        <Box display="flex">
          {pages.map((page) => (
            <Typography
              variant="body2"
              component="span"
              key={page.label}
              {...(activeLabel === page.label ? { color: "primary" } : { color: "textSecondary" })}
            >
              <TabItem
                mr={2}
                pb={1}
                fontWeight="bold"
                onClick={() => setLabel(page.label)}
                {...(activeLabel === page.label && { borderBottom: 2 })}
              >
                {page.label}
              </TabItem>
            </Typography>
          ))}
        </Box>
        {Boolean(Navigation) && Navigation}
        {isToggle && <ToggleNavigation />}
      </Box>
      {commonNoticeText && <Box mb={2} fontWeight="bold" textAlign="left">{commonNoticeText}</Box>}
      {open && <ActivePage />}
    </>
  );
};
