import { Avatar, Box, Paper, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";

import NoImage from "../../../../assets/image/NoImage.svg";
import Image from "../../../../assets/image/onboarding_2.png";
import { ProposalMatching } from "../../../api/generated";
import { CustomButton } from "../../../scripts/components/renewal_v1/button";

const useStyles = makeStyles((theme) => ({
  image: {
    width: "158px",
    height: "114px",
  },
  profileImage: {
    width: 113,
    height: 113,
  },
  wrapper: {
    display: "flex",
    gap: theme.spacing(4),
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  content: {
    width: "113px",
  },
}));

type MatchingAgentSectionProps = {
  proposalMatchings: ProposalMatching[];
};

export const MatchingAgentSection = ({ proposalMatchings }: MatchingAgentSectionProps) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = (proposalMatching: ProposalMatching) => {
    if (proposalMatching.matching_type === "投資") {
      history.push(`investment/${proposalMatching.investment_proposal_id}`);
    }

    if (proposalMatching.matching_type === "査定") {
      history.push(`assessment/${proposalMatching.assessment_proposal_id}`);
    }
  };

  return (
    <Paper>
      <Box p={2}>
        <Box mb={2}>
          <Typography variant="h3">マッチ中のエージェント</Typography>
        </Box>
        {proposalMatchings.length ? (
          <Box className={classes.wrapper}>
            {proposalMatchings.map((matching) => (
              <Box key={matching.id} className={classes.content} onClick={() => handleClick(matching)}>
                <Avatar
                  src={matching.agent?.agent_profile_image?.image_url || NoImage}
                  alt="profile"
                  className={classes.profileImage}
                />
                <Box>
                  <Typography variant="h4">{matching?.agent?.company?.name}</Typography>
                  <Typography variant="body1" align="center">
                    {matching?.agent?.family_name + matching?.agent?.first_name}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <>
            <Typography>
              現在マッチ中のエージェントはいません。
              <br />
              プロパリーでは、
              <Typography variant="h4" component="span">
                審査を通過した 一定以上の実績を持つエージェント
              </Typography>
              が、あなたの情報をもとにオファーを送ります。
              <br />
              まずは話を聞いてみましょう!
            </Typography>
            <Box display="flex" justifyContent="space-between" mt={2}>
              <CustomButton customVariant="filled" onClick={() => history.push("/offer")}>
                オファーを確認する
              </CustomButton>
              <Box ml={2}>
                <img src={Image} alt="Illustration" className={classes.image} />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Paper>
  );
};
