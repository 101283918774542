import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";

import PropertySampleImage01 from "../../../../../assets/image/property_sample_image01.png";
import PropertySampleImage02 from "../../../../../assets/image/property_sample_image02.png";
import PropertySampleImage03 from "../../../../../assets/image/property_sample_image03.png";

import { OccupancyStatus } from "./occupancy_status";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 342,
    margin: "auto",
    borderRadius: "8px",
    overflow: "hidden",
    position: "relative",
  },
  media: {
    height: 200,
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0.32) 0%, rgba(0, 0, 0, 0) 53%, rgba(0, 0, 0, 0.32) 100%)",
    color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(2.5),
  },
  rentYen: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.caption.fontWeight,
    lineHeight: theme.typography.caption.lineHeight,
  },
  details: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
}));

interface PropertyCardImageProps {
  image: string;
  title: string;
  address: string;
  rent: number;
  purchasePrice: number;
  yield_rate: string;
  actual_yield_rate: string;
  isActive: boolean;
  showStatus?: boolean;
  onClick?: () => void;
}

export const PropertyCardImage: React.FC<PropertyCardImageProps> = ({
  image,
  title,
  address,
  rent,
  purchasePrice,
  yield_rate,
  actual_yield_rate,
  isActive,
  showStatus = false,
  onClick = () => {},
}) => {
  const classes = useStyles();

  const handleClick = () => {
    onClick();
  };
  // imageが空の場合は画像3枚からランダムで表示
  const sampleImages = [PropertySampleImage01, PropertySampleImage02, PropertySampleImage03];
  const selectedImage =
    image !== "/assets/image/NoImage.svg" && image !== ""
      ? image
      : sampleImages[Math.floor(Math.random() * sampleImages.length)];
  const rentFeeText = Math.round(rent).toLocaleString();
  const purchasePriceText = Math.round(purchasePrice / 10000).toLocaleString();

  return (
    <Card className={classes.root} onClick={handleClick}>
      <CardMedia className={classes.media} image={selectedImage} title={title} />
      <Box className={classes.overlay}>
        <Box>
          <Typography variant="h3">{title}</Typography>
          <Typography variant="h6">{address}</Typography>
        </Box>
        <Box className={classes.details}>
          <Box>
            <Typography variant="h6">賃料: </Typography>
            <Typography variant="h4">
              {rentFeeText}
              <span className={classes.rentYen}>円</span>
            </Typography>
            <Typography variant="h6">購入価格: ¥{purchasePriceText}万</Typography>
            <Typography variant="h6">
              表面利回り: {yield_rate}% 実質利回り: {actual_yield_rate}%
            </Typography>
          </Box>
          {showStatus && <OccupancyStatus label={isActive ? "入居中" : "空室中"} isActive={isActive} />}
        </Box>
      </Box>
    </Card>
  );
};

export const PropertyCardImageExample: React.FC = () => {
  return (
    <PropertyCardImage
      image="https://via.placeholder.com/345x180"
      title="レオパレス 201"
      address="東京都武蔵野市吉祥寺本町123-456 102号"
      rent={81000}
      purchasePrice={1500}
      yield_rate={"4.51"}
      actual_yield_rate="3.1"
      isActive={true}
    />
  );
};
