import { Box, Divider, Typography, makeStyles } from "@material-ui/core";
import * as React from "react";

import { UploadButtonComponent } from "../image_upload";
import { StyledModal } from "../styled_modal";

const useStyles = makeStyles((theme) => ({
  messageContent: {
    padding: "24px 24px 0 24px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    borderTop: "1px solid #ccc",
    alignItems: "center",
    height: 60,
  },
  button: {
    minWidth: 100,
  },
  description: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  link: {
    textDecoration: "none",
    cursor: "pointer",
    textAlign: "center",
    "&:hover": {
      borderBottom: "none",
    },
  },
  divider: {
    height: "100%",
    backgroundColor: theme.palette.divider,
    margin: "0 16px",
  },
}));

interface ChangePropertyImageModalProps {
  onClose: () => void;
  onClickChangeImage: (_file: File) => Promise<void>;
  onClickCancel: () => void;
}
export const ChangePropertyImageModal: React.FC<ChangePropertyImageModalProps> = ({
  onClose,
  onClickChangeImage,
  onClickCancel,
}) => {
  const classes = useStyles();
  const [postImageData, setPostImageData] = React.useState<File>(null);

  const handleChangeUploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }
    setPostImageData(file);
    event.target.value = "";
  };

  const handleClick = React.useCallback(() => {
    document.body.style.overflow = "auto"; // Enable scrolling
    onClose();
  }, [onClose]);

  // スクロール制御
  React.useEffect(() => {
    document.body.style.overflow = "hidden"; // Disable scroll when modal is open
    return () => {
      document.body.style.overflow = "auto"; // Enable scroll when modal is unmounted
    };
  }, []);

  const handleCancel = React.useCallback(() => {
    onClickCancel();
    onClose();
  }, []);
  const handleUpdateImage = React.useCallback(() => {
    onClickChangeImage(postImageData);
    onClose();
  }, [postImageData]);

  return (
    <StyledModal onClickOutside={handleClick}>
      <Box className={classes.messageContent}>
        <Box textAlign="center" mb={2}>
          <Typography variant="h3">画像を選択してください</Typography>
        </Box>
        <Box my={2} display="flex" justifyContent="center">
          <UploadButtonComponent name="property_image" handleFileChange={handleChangeUploadFile} />
        </Box>
      </Box>
      <Box className={classes.buttonContainer}>
        <Typography
          variant="button"
          color="textPrimary"
          className={`${classes.link} ${classes.button}`}
          onClick={handleCancel}
        >
          キャンセル
        </Typography>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Typography
          variant="button"
          color="error"
          className={`${classes.link} ${classes.button}`}
          onClick={handleUpdateImage}
        >
          変更する
        </Typography>
      </Box>
    </StyledModal>
  );
};
