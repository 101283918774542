import { Box, Typography, makeStyles } from "@material-ui/core";
import dayjs from "dayjs";
import * as React from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useRecoilState } from "recoil";

import { CustomButton } from "../../../../../scripts/components/renewal_v1/button";
import { CustomForm } from "../../../../../scripts/components/renewal_v1/form";
import CustomFormLabel from "../../../../../scripts/components/renewal_v1/form_label";
import { Toast } from "../../../../../scripts/components/renewal_v1/toast";
import { DatePickerComponent } from "../../../../../scripts/components/renewal_v1/year_month_picker";
import { AppEventTokenEnum, sendEventToAppMeasurementTool } from "../../../../../utilities/capacitor_logic";
import { getTaxRateAtBought } from "../../../../../utilities/renewal_v1/property_management";
import { ConsiderationPropertyRegistrationState } from "../../../../../view_models/atoms";
import { PRICE_KEYS } from "../const";

const useStyles = makeStyles((theme) => ({
  content: {
    width: 640,
    [theme.breakpoints.down("sm")]: {
      width: 342,
    },
    margin: "8px  auto 0px auto",
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      borderBottom: "none",
    },
  },
}));

interface AddNewConsiderationPropertyLoanPageProps {
  onNext: () => void;
  onBack: () => void;
  validate: () => Promise<boolean>;
}

const AddNewConsiderationPropertyLoanPage: React.FC<AddNewConsiderationPropertyLoanPageProps> = ({
  onNext,
  onBack,
  validate,
}) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
    setError,
    setValue,
    getValues,
  } = useFormContext();

  const [formData] = useRecoilState(ConsiderationPropertyRegistrationState);

  const total_price = useWatch({
    name: PRICE_KEYS.total,
    control: control,
    defaultValue: 0,
  });

  // サーバーエラー
  const [serverError, setSeverError] = React.useState<string>("");

  const sumOfPriceofIsEqualToTotalPriceOfProperty = () => {
    // priceof_* are all nil, total_price_of_property can be any value
    if (
      formData.priceof_building === "" &&
      formData.priceof_facilities === "" &&
      formData.priceof_land === "" &&
      formData.priceof_tax === ""
    ) {
      return;
    }

    const sumOfPriceof =
      parseInt(formData.priceof_building) +
      parseInt(formData.priceof_facilities) +
      parseInt(formData.priceof_land) +
      parseInt(formData.priceof_tax);

    // The front side rounds off, so the total amount matches within an error of 5
    if (
      sumOfPriceof - 5 <= parseInt(formData.total_price_of_property) &&
      parseInt(formData.total_price_of_property) <= sumOfPriceof + 5
    ) {
      return;
    }

    setError("base", {
      type: "manual",
      message: "Invalid total price of property",
    });
  };
  const handleNext = async () => {
    sumOfPriceofIsEqualToTotalPriceOfProperty();
    const isValid = await validate();
    if (isValid) {
      sendEventToAppMeasurementTool(AppEventTokenEnum.ConsiderationPropertyPriceLoanCompleted);
      onNext();
    }
  };

  const handleClose = React.useCallback(() => {
    setSeverError("");
  }, []);

  // 選択肢
  return (
    <>
      <Box>
        <Box className={classes.content}>
          <Box mb={1}>
            <CustomFormLabel label="購入予定年月日" required info="購入した年月日を選択してください。" />
          </Box>
          <Controller
            name="bought_at"
            control={control}
            rules={{ required: true }}
            defaultValue={formData.bought_at || dayjs(new Date()).format("YYYY-MM")}
            render={({ ...field }) => <DatePickerComponent labelLight="年月" {...field} />}
          />

          <Controller
            name="bought_at_day"
            control={control}
            rules={{
              required: "入力してください。",
              pattern: {
                value: /^[0-9]+$/,
                message: "半角数字で入力してください。",
              },
            }}
            defaultValue={formData.bought_at_day || "1"}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="日にち"
                helperText="不明の場合は値を「1日」のままお進みください。"
                error={!!errors.bought_at_day}
                errorText={errors.bought_at_day?.message}
                onChange={field.onChange}
                placeHolder=""
                unitSuffix="日"
              />
            )}
          />

          <Controller
            name="total_price_of_property"
            control={control}
            rules={{
              required: "入力してください。",
              pattern: {
                value: /^[0-9]+$/,
                message: "半角数字で入力してください。",
              },
            }}
            defaultValue={formData.total_price_of_property || ""}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="購入予定価格 (税込)"
                required
                error={!!errors.total_price_of_property}
                errorText={errors.total_price_of_property?.message}
                onChange={(e) => {
                  field.onChange(e);
                  const total = parseInt(e.target.value);
                  // 建物(税込) = 購入価格の6割として自動計算
                  const building = total * 0.6;
                  // 土地 = 購入価格の4割として自動計算
                  const land = total * 0.4;

                  const tax_rate_at_bought = getTaxRateAtBought(formData.bought_at);
                  if (tax_rate_at_bought !== 0) {
                    // 建物(税抜)
                    const buildingWithOutTax = building / (1 + tax_rate_at_bought);
                    // うち消費税(土地は消費税かからないため、「うち消費税 = 建物消費税」となる)
                    const tax = building - buildingWithOutTax;

                    setValue(PRICE_KEYS.tax, Math.round(tax));
                    setValue(PRICE_KEYS.building, Math.round(buildingWithOutTax));
                    // うち建物付属設備は0円とする
                    setValue(PRICE_KEYS.facilities, 0);
                    setValue(PRICE_KEYS.land, Math.round(land));
                  }
                }}
                placeHolder="100000000"
                unitSuffix="円"
              />
            )}
          />

          <Controller
            name="priceof_tax"
            control={control}
            rules={{
              required: "入力してください。",
              pattern: {
                value: /^[0-9]+$/,
                message: "半角数字で入力してください。",
              },
            }}
            defaultValue={formData.priceof_tax || ""}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="うち消費税"
                required
                info="消費税は物件価格の割合として建物価格6割、土地価格4割と仮定して自動計算しておりますが、より詳細な値を出すには売買契約書記載の消費税額をご入力ください。"
                error={!!errors.priceof_tax}
                errorText={errors.priceof_tax?.message}
                onChange={(e) => {
                  field.onChange(e);
                  // 前提
                  // 1. 自動計算の際は建物付属設備(税抜)を0にする
                  // 2. 建物合計 = 建物(税抜) + 建物付属設備(税抜) + 消費税
                  // 3. 購入価格(税込) = 建物合計 + 土地
                  const tax = parseInt(e.target.value);

                  const tax_rate_at_bought = getTaxRateAtBought(formData.bought_at);
                  if (tax_rate_at_bought !== 0) {
                    // 建物(税抜)
                    const buildingWithOutTax = tax / tax_rate_at_bought;
                    // 土地
                    const land = total_price - tax - buildingWithOutTax;

                    setValue(PRICE_KEYS.building, Math.round(buildingWithOutTax));
                    setValue(PRICE_KEYS.land, Math.round(land));
                    setValue(PRICE_KEYS.facilities, 0);
                  }
                }}
                placeHolder="100000000"
                unitSuffix="円"
              />
            )}
          />

          <Controller
            name="priceof_building"
            control={control}
            rules={{
              required: "入力してください。",
              pattern: {
                value: /^[0-9]+$/,
                message: "半角数字で入力してください。",
              },
            }}
            defaultValue={formData.priceof_building || ""}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="うち建物代"
                required
                info="本ツールでは消費税の金額を入力頂くと建物価格を自動計算して表示されますが、建物・建物付属設備の正確な価格内訳については物件を購入された際の担当者にご確認下さい。"
                error={!!errors.priceof_building}
                errorText={errors.priceof_building?.message}
                onChange={(e) => {
                  field.onChange(e);
                  const values = getValues([PRICE_KEYS.total, PRICE_KEYS.tax, PRICE_KEYS.land]);
                  const result =
                    Number(values.total_price_of_property) -
                    Number(values.priceof_tax) -
                    Number(parseInt(e.target.value)) -
                    Number(values.priceof_land);
                  setValue(PRICE_KEYS.facilities, Math.round(result));
                }}
                placeHolder="100000000"
                unitSuffix="円"
              />
            )}
          />
          <Controller
            name="priceof_land"
            control={control}
            rules={{
              required: "入力してください。",
              pattern: {
                value: /^[0-9]+$/,
                message: "半角数字で入力してください。",
              },
            }}
            defaultValue={formData.priceof_land || ""}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="うち土地代"
                required
                info="本ツールでは購入価格・消費税の金額を入力頂くと土地価格を推定して表示します。"
                error={!!errors.priceof_land}
                errorText={errors.priceof_land?.message}
                onChange={field.onChange}
                placeHolder="100000000"
                unitSuffix="円"
              />
            )}
          />

          <Controller
            name="loan_amount"
            control={control}
            rules={{
              required: "入力してください。",
              pattern: {
                value: /^[0-9]+$/,
                message: "半角数字で入力してください。",
              },
            }}
            defaultValue={formData.loan_amount || ""}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="ローン借入予定金額"
                required
                error={!!errors.loan_amount}
                errorText={errors.loan_amount?.message}
                onChange={field.onChange}
                placeHolder="100000000"
                unitSuffix="円"
              />
            )}
          />

          <Controller
            name="loan_interest_rate_percentage"
            control={control}
            rules={{
              required: "入力してください。",
              pattern: {
                value: /^[0-9]+(\.[0-9]+)?$/,
                message: "半角数字で入力してください。",
              },
            }}
            defaultValue={formData.loan_interest_rate_percentage || ""}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="借入利率 (年利)"
                required
                error={!!errors.loan_interest_rate_percentage}
                errorText={errors.loan_interest_rate_percentage?.message}
                onChange={field.onChange}
                placeHolder="10"
                unitSuffix="%"
                isShort
              />
            )}
          />
          <Controller
            name="payment_starts_at"
            control={control}
            rules={{ required: true }}
            defaultValue={formData.payment_starts_at || dayjs(new Date()).format("YYYY-MM")}
            render={({ ...field }) => <DatePickerComponent label="初回返済月" {...field} />}
          />

          <Controller
            name="payment_period"
            control={control}
            rules={{
              required: "入力してください。",
              pattern: {
                value: /^[0-9]+$/,
                message: "半角数字で入力してください。",
              },
            }}
            defaultValue={formData.payment_period || ""}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="返済予定年数"
                required
                error={!!errors.payment_period}
                errorText={errors.payment_period?.message}
                onChange={field.onChange}
                placeHolder="30"
                unitSuffix="年"
              />
            )}
          />
        </Box>
        <Box mt={5}>
          <CustomButton type="button" customVariant="filled" onClick={handleNext}>
            次へ
          </CustomButton>
          <Box display="flex" margin="32px auto" justifyContent="center">
            <Typography variant="button" className={classes.link} onClick={onBack}>
              前に戻る
            </Typography>
          </Box>
        </Box>
      </Box>
      <Toast open={!!serverError} variant="error" message={serverError} onClose={handleClose} />
    </>
  );
};

export default AddNewConsiderationPropertyLoanPage;
