import { PropallyApiFactory } from "../module/custom_api_factory";

export const getMonthlyCashflowResults = async (propertyId: number) => {
  const fp = PropallyApiFactory();
  try {
    const monthlyCashflowResults = await fp.v1PropertiesPropertyIdMonthlyCashflowResultsGet(propertyId, {
      withCredentials: true,
    });

    return { success: true, monthlyCashflow: monthlyCashflowResults.data, error: "" };
  } catch (e) {
    if (e.response?.status === 401) {
      return { success: false, monthlyCashflow: null, error: "画面をリロードし、再度ログインしてください" };
    }
    return {
      success: false,
      monthlyCashflow: null,
      error: "ネットワークエラーです。しばらくたってからお試しください",
    };
  }
};
