import { CircularProgress, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";

// Custom styles for the CircularProgress
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[50],
  },
  wrap: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      width: 640,
      margin: "0 auto",
    },
  },
  circleRoot: {
    position: "relative",
    display: "inline-flex",
    padding: "16px 24px 16px 16px",
    [theme.breakpoints.up("sm")]: {
      padding: "16px 24px 16px 0",
    },
  },
  circle: {
    strokeLinecap: "round",
  },
  box: {
    top: 0,
    left: -12,
    bottom: 0,
    right: 0,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      left: -24,
    },
  },
  stepText: {
    color: theme.palette.text.secondary,
  },
  stepNumber: {
    color: theme.palette.primary.main,
    fontSize: "19px",
  },
  stepName: {
    color: theme.palette.text.primary,
  },
  stepMessage: {
    color: theme.palette.text.secondary,
  },
}));
interface CircularProgressWithLabelProps {
  value: number;
  stepName: string;
  stepMessage: string;
}
export const CircularProgressWithLabel: React.FC<CircularProgressWithLabelProps> = ({
  value,
  stepName,
  stepMessage,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.wrap}>
        <Box className={classes.circleRoot}>
          <CircularProgress
            variant="determinate"
            value={(value / 3) * 100}
            thickness={5}
            size={64}
            classes={{ circle: classes.circle }}
            color="primary"
          />
          <Box className={classes.box}>
            <Typography variant="h4" component="div" className={classes.stepText}>
              {/* {`${value}/3`} */}
              <span className={classes.stepNumber}>{`${value}`}</span>
              /3
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant="h3" className={classes.stepName}>
            {stepName}
          </Typography>
          <Typography variant="body2" className={classes.stepMessage}>
            {stepMessage}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
