import { makeStyles, Typography, Input, Box } from "@material-ui/core";
import * as React from "react";

import NoDocumentImage from "../../../../../assets/image/no_document.png";
import { CustomButton } from "../button";

const useStyles = makeStyles((theme) => ({
  image: {
    width: 220,
    height: "auto",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(14),
    [theme.breakpoints.up("sm")]: {
      width: 320,
    },
  },
  mainMessage: {
    marginBottom: theme.spacing(1.5),
  },
  subMessage: {
    marginBottom: theme.spacing(4),
  },
  buttonWrapper: {
    paddingBottom: theme.spacing(11),
  },
  input: {
    display: "none",
  },
}));

interface props {
  onChangeFile: (_event: React.ChangeEvent<HTMLInputElement>) => void;
}
export const NoDocumentComponent: React.FC<props> = ({ onChangeFile }) => {
  const classes = useStyles();
  const [fileName, setFileName] = React.useState("");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files.length > 0) {
      setFileName(event.target.files[0].name);
      onChangeFile(event);
    } else {
      setFileName("");
    }
  };

  return (
    <>
      <img src={NoDocumentImage} alt="No property" className={classes.image} />
      <Typography variant="h3" className={classes.mainMessage} color="textPrimary">
        書類が登録されていません。
      </Typography>
      <Typography variant="body1" className={classes.subMessage} color="textPrimary">
        書類をアップロードして、確定申告などの時に必要な書類を確認できるようにしておきましょう
      </Typography>
      <Input
        inputProps={{ accept: "image/*,application/pdf" }}
        className={classes.input}
        id="contained-button-file"
        type="file"
        onChange={handleFileChange}
      />
      <Box className={classes.buttonWrapper}>
        <label htmlFor="contained-button-file">
          <CustomButton customVariant="filled" component="span">
            書類をアップロードする
          </CustomButton>
        </label>
      </Box>
    </>
  );
};
