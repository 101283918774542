import { Box } from "@material-ui/core";
import * as React from "react";
import { useRecoilValueLoadable } from "recoil";

import { NavigationPath } from "../../../scripts/components/renewal_v1/enums";
import Footer from "../../../scripts/components/renewal_v1/footer";
import NavigationBar from "../../../scripts/components/renewal_v1/navigation";
import { OfferTwoColumnTab } from "../../../scripts/components/renewal_v1/offer/offer_two_col_tab";
import { MainTemplate } from "../../../scripts/components/renewal_v1/template/main_template";
import { useDevice } from "../../../utilities/sp/use_device";
import { assessmentUnreadCount, investmentUnreadCount } from "../../../view_models/proposal_unread_counts";
import { Loading } from "../../loading";

import { OfferAssessmentTab } from "./tabs/assessment";
import { OffferInvestmentTab } from "./tabs/investment";

export const OfferListPage: React.FC = () => {
  const { isMobile } = useDevice();
  const investmentUnreadCnt = useRecoilValueLoadable(investmentUnreadCount);
  const assessmentUnreadCnt = useRecoilValueLoadable(assessmentUnreadCount);

  if (investmentUnreadCnt.state === "loading" || assessmentUnreadCnt.state === "loading") {
    return <Loading />;
  }
  if (investmentUnreadCnt.state === "hasError" || assessmentUnreadCnt.state === "hasError") {
    return <Box>Error...</Box>;
  }

  const investmentUnreadCountValue = investmentUnreadCnt.contents;
  const assessmentUnreadCountValue = assessmentUnreadCnt.contents;

  return (
    <MainTemplate>
      <Box margin="0 auto">
        <OfferTwoColumnTab
          firstTabLabel="投資オファー"
          firstTabChildren={<OffferInvestmentTab />}
          firstTabCount={investmentUnreadCountValue?.unread_count}
          secondTabLabel="査定オファー"
          secondTabChildren={<OfferAssessmentTab />}
          secondTabCount={assessmentUnreadCountValue?.unread_count}
        />
      </Box>

      {isMobile ? <NavigationBar path={NavigationPath.OFFER} /> : <Footer />}
    </MainTemplate>
  );
};
