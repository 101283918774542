import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import React = require("react");

import { EntranceTemplate } from "../scripts/components/templates/entrance_template";

export const RegistrationsError: React.FC = () => {
  return (
    <EntranceTemplate title="リンクが無効です" isFooter>
      <Box mb={6} mt={2}>
        <Typography variant="body2" color="textSecondary">
          お手数ですが仮登録からやり直してください。
        </Typography>
      </Box>
      <Link variant="caption" href="/login">
        ログイン
      </Link>
      <Typography variant="caption">に戻る</Typography>
    </EntranceTemplate>
  );
};
