import * as Sentry from "@sentry/browser";
import axios from "axios";

import { PropallyV1ApiFactory } from "../api/generated/api";

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

export const PropallyApiFactory = () => {
  return PropallyV1ApiFactory(undefined, undefined, axiosInstance);
};
