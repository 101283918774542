import { useState, useEffect } from "react";

import { AssessmentProposal } from "../api/generated";
import { PropallyApiFactory } from "../module/custom_api_factory";

export const useGetAssesmentProposals = () => {
  const [loading, setLoading] = useState(true);
  const [assessmentProposals, setAssessmentProposals] = useState<AssessmentProposal[]>([]);

  useEffect(() => {
    const fetchAssessmentProposals = async () => {
      const fp = PropallyApiFactory();
      try {
        const data = await fp.v1AssessmentProposalsGet({ withCredentials: true });
        if (data.status === 200) {
          setAssessmentProposals(data.data);
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchAssessmentProposals();
  }, []);

  return { assessmentProposals, loading };
};
