// 想定売却価格とローン残高推移設定
import { Box, FormControl, IconButton, Input, MenuItem, Select, Typography, makeStyles } from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";

import {
  SimulationConfig,
  SimulationConfigHouseRentModeEnum,
  SimulationConfigMarketPriceChangeAtBoughtModeEnum,
  SimulationConfigMarketPriceModeEnum,
  SimulationConfigWithIdProposedByEnum,
  SimulationPatternEnum,
} from "../../../../api/generated";
import { PropallyApiFactory } from "../../../../module/custom_api_factory";
import { CustomButton } from "../../../../scripts/components/renewal_v1/button";
import CustomFormLabel from "../../../../scripts/components/renewal_v1/form_label";
import { SimConfigForm } from "../../../../scripts/components/renewal_v1/property/simulation/sim_config_form";
import { StyledModal } from "../../../../scripts/components/renewal_v1/styled_modal";
import { PropertyManagementDetailSnackbarState, useRequestInitialLoading } from "../../../../view_models/atoms";
import { SelectedProperty } from "../../../../view_models/property_selectors";

import { SimulationConfigPriceLoanCustomFormType } from "./types";

const useStyles = makeStyles((theme) => ({
  modalContent: {
    maxHeight: "80vh", // Adjust as needed
    overflowY: "auto",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  icon: {
    width: 16,
    height: 16,
  },
  inputUnderline: {
    "&:before": {
      border: "none",
    },
    "&:after": {
      border: "none",
    },
  },
  block: {
    marginBottom: 24,
  },
  formBlock: {
    backgroundColor: theme.palette.tertiary.light,
    padding: "8px 16px",
    borderRadius: 8,
    marginBottom: 8,
    position: "relative",
    "&:hover $deleteButton": {
      display: "block",
    },
  },
  deleteButton: {
    display: "none",
    position: "absolute",
    top: -8,
    right: -8,
    border: `1px solid ${theme.palette.tertiary.main}`,
    borderRadius: "100%",
    padding: 0,
  },
  cancel: {
    display: "flex",
    justifyContent: "center",
    padding: 16,
    color: theme.palette.primary.main,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      borderBottom: "none",
    },
  },
}));

interface props {
  onClose: () => void;
  onClickSave: () => void;
  onClickCancel: () => void;
}

export const SimulationConfigCustomPriceLoanModal: React.FC<props> = ({ onClose, onClickSave, onClickCancel }) => {
  const classes = useStyles();
  const history = useHistory();

  const selected_property = useRecoilValue(SelectedProperty);
  const setManagementDetailSnackBarState = useSetRecoilState(PropertyManagementDetailSnackbarState);
  const requestInitialLoading = useRequestInitialLoading();

  const { control, handleSubmit, errors, trigger, watch } = useForm<SimulationConfigPriceLoanCustomFormType>();

  // 年間の価格変動率の単位
  const market_price_mode = watch("market_price_mode");
  // 購入時の価格変動率の単位
  const market_price_change_at_bought_mode = watch("market_price_change_at_bought_mode");

  const handleClick = React.useCallback(() => {
    document.body.style.overflow = "auto"; // Enable scrolling
    onClose();
  }, [onClose]);
  // スクロール制御
  React.useEffect(() => {
    document.body.style.overflow = "hidden"; // Disable scroll when modal is open
    return () => {
      document.body.style.overflow = "auto"; // Enable scroll when modal is unmounted
    };
  }, []);

  const handleCancel = React.useCallback(() => {
    onClickCancel();
    onClose();
  }, []);

  const formTypeToApiType = (data: SimulationConfigPriceLoanCustomFormType): SimulationConfig => {
    let market_price_mode: SimulationConfigMarketPriceModeEnum;
    if (data.market_price_mode === "percentage") {
      market_price_mode = SimulationConfigMarketPriceModeEnum.Percentage;
    } else {
      market_price_mode = SimulationConfigMarketPriceModeEnum.Real;
    }
    // market_price_change_down_valueの正負
    let market_price_change_down_value: number;
    if (data.market_price_mode_sign === "positive") {
      market_price_change_down_value = Number(data.market_price_change_down_value) * -1;
    } else {
      market_price_change_down_value = Number(data.market_price_change_down_value);
    }
    //  購入時の価格変動率の単位
    let market_price_change_at_bought_mode: SimulationConfigMarketPriceChangeAtBoughtModeEnum;
    if (data.market_price_change_at_bought_mode === "percentage") {
      market_price_change_at_bought_mode = SimulationConfigMarketPriceChangeAtBoughtModeEnum.Percentage;
    } else {
      market_price_change_at_bought_mode = SimulationConfigMarketPriceChangeAtBoughtModeEnum.Real;
    }
    // market_price_change_at_bought_valueの正負
    let market_price_change_at_bought_value: number;
    if (data.market_price_change_at_bought_sign === "positive") {
      market_price_change_at_bought_value = Number(data.market_price_change_at_bought_value) * -1;
    } else {
      market_price_change_at_bought_value = Number(data.market_price_change_at_bought_value);
    }
    return {
      // 更新するパラメータ
      market_price_change_down_value: market_price_change_down_value,
      market_price_mode: market_price_mode,
      market_price_change_at_bought_value: market_price_change_at_bought_value,
      market_price_change_at_bought_mode: market_price_change_at_bought_mode,
      // 初期値
      market_price_change_ends_at: 50,
      house_rent_price_change_ends_at: 0,
      house_rent_spare_months_for_N_contracts: 0,
      house_rent_spare_months: 0,
      house_rent_price_change_down_value: 0,
      house_rent_mode: SimulationConfigHouseRentModeEnum.Percentage,
      simulation_pattern: SimulationPatternEnum.カスタム,
    };
  };

  const handleSave = async (data: SimulationConfigPriceLoanCustomFormType) => {
    const isValid = await trigger();
    if (isValid) {
      const fp = PropallyApiFactory();
      // 既存のシミュレーション設定を取得
      const user_simconf = selected_property.simulation_configs.find((c) => {
        return c.proposed_by !== SimulationConfigWithIdProposedByEnum.Realtor;
      });

      // シミュレーション設定の更新
      const simconf = formTypeToApiType(data);

      try {
        const response = await fp.v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdPatch(
          selected_property.id,
          user_simconf.id,
          simconf,
          { withCredentials: true }
        );
        if (response.status === 200) {
          console.log("シミュレーション設定を変更しました");
        }
      } catch (e) {
        console.log(e);
        setManagementDetailSnackBarState({
          message: "設定を適用できませんでした",
          status: "error",
          isOpen: true,
        });
        history.push("/management/detail");
        return;
      }

      requestInitialLoading();
      onClickSave();
      onClose();
      setManagementDetailSnackBarState({
        message: "設定を適用しました",
        status: "success",
        isOpen: true,
      });
    }
  };

  return (
    <StyledModal onClickOutside={handleClick}>
      <form onSubmit={handleSubmit(handleSave)}>
        <Box className={classes.modalContent}>
          <Box className={classes.header}>
            <Typography variant="h3">詳細設定</Typography>
            <IconButton onClick={handleClick}>
              <CloseOutlinedIcon classes={{ root: classes.icon }} />
            </IconButton>
          </Box>
          <Box p={3}>
            <Box mb={2}>
              <Typography variant="h3">物件価格の変動</Typography>
            </Box>

            {/* 年間の価格変動率 */}
            <Box className={classes.block}>
              <Box mb={1}>
                <CustomFormLabel
                  label="年間の価格変動率"
                  info="物件価格の変動率もしくは変動金額を入力してください"
                  required
                />
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <Controller
                  name="market_price_change_down_value"
                  control={control}
                  rules={{ required: "入力してください。" }}
                  defaultValue={""}
                  render={({ ...field }) => (
                    <SimConfigForm
                      {...field}
                      error={!!errors.market_price_change_down_value}
                      errorText={errors.market_price_change_down_value?.message}
                      onChange={field.onChange}
                      placeHolder="10"
                      unitSuffix={market_price_mode === "percentage" ? "%" : "円"}
                    />
                  )}
                />
                <Controller
                  name="market_price_mode"
                  control={control}
                  rules={{ required: "入力してください。" }}
                  defaultValue={"percentage"}
                  render={({ ...field }) => (
                    <Select
                      {...field}
                      onChange={field.onChange}
                      IconComponent={ExpandMoreIcon}
                      style={{ marginLeft: 8 }}
                      input={<Input classes={{ underline: classes.inputUnderline }} />}
                    >
                      <MenuItem value="percentage">%</MenuItem>
                      <MenuItem value="yen">円</MenuItem>
                    </Select>
                  )}
                />
              </Box>
              <Controller
                name="market_price_mode_sign"
                control={control}
                defaultValue={"positive"}
                render={({ ...field }) => (
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      {...field}
                      onChange={field.onChange}
                      error={!!errors.market_price_mode_sign}
                      IconComponent={() => (
                        <Box p={1}>
                          <ExpandMoreIcon />
                        </Box>
                      )}
                    >
                      <MenuItem key="positive" value="positive">
                        上昇
                      </MenuItem>
                      <MenuItem key="negative" value="negative">
                        下落
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Box>

            {/* 購入時の価格変動率 */}
            <Box className={classes.block}>
              <Box mb={1}>
                <CustomFormLabel
                  label="購入時の価格変動率"
                  info="物件価格の変動率もしくは変動金額を入力してください"
                  required
                />
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <Controller
                  name="market_price_change_at_bought_value"
                  control={control}
                  rules={{ required: "入力してください。" }}
                  defaultValue={""}
                  render={({ ...field }) => (
                    <SimConfigForm
                      {...field}
                      error={!!errors.market_price_change_at_bought_value}
                      errorText={errors.market_price_change_at_bought_value?.message}
                      onChange={field.onChange}
                      placeHolder="10"
                      unitSuffix={market_price_change_at_bought_mode === "percentage" ? "%" : "円"}
                    />
                  )}
                />
                <Controller
                  name="market_price_change_at_bought_mode"
                  control={control}
                  rules={{ required: "入力してください。" }}
                  defaultValue={"percentage"}
                  render={({ ...field }) => (
                    <Select
                      {...field}
                      onChange={field.onChange}
                      IconComponent={ExpandMoreIcon}
                      style={{ marginLeft: 8 }}
                      input={<Input classes={{ underline: classes.inputUnderline }} />}
                    >
                      <MenuItem value="percentage">%</MenuItem>
                      <MenuItem value="yen">円</MenuItem>
                    </Select>
                  )}
                />
              </Box>
              <Controller
                name="market_price_change_at_bought_sign"
                control={control}
                defaultValue={"positive"}
                render={({ ...field }) => (
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      {...field}
                      onChange={field.onChange}
                      error={!!errors.market_price_change_at_bought_sign}
                      IconComponent={() => (
                        <Box p={1}>
                          <ExpandMoreIcon />
                        </Box>
                      )}
                    >
                      <MenuItem key="positive" value="positive">
                        上昇
                      </MenuItem>
                      <MenuItem key="negative" value="negative">
                        下落
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Box>

            <Box mt={4}>
              <CustomButton customVariant="filled" type="submit">
                設定を適用する
              </CustomButton>
              <Typography variant="button" className={classes.cancel} onClick={handleCancel}>
                キャンセル
              </Typography>
            </Box>
          </Box>
        </Box>
      </form>
    </StyledModal>
  );
};
