import { ThemeProvider, makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import * as React from "react";

import { useDevice } from "../../../../utilities/sp/use_device";
import Footer from "../footer";
import theme from "../theme";

const useStyles = makeStyles(() => ({
  title: {
    color: theme.palette.text.primary,
  },
}));

type Props = {
  children: React.ReactNode;
  title: string;
  subTitle?: string;
};

export const EntranceTemplate: React.FC<Props> = ({ children, title, subTitle }) => {
  const { isMobile } = useDevice();
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Box
        bgcolor={theme.palette.background}
        pt={10}
        textAlign="left"
        minHeight="100vh"
        display="flex"
        flexDirection="column"
      >
        <Box width={isMobile ? "auto" : "390px"} px={3} margin="0 auto" flexGrow={1} className={classes.title}>
          <Typography variant="h1" className={classes.title}>
            {title}
          </Typography>
          {subTitle && (
            <Box mt={1}>
              <Typography variant="body2">{subTitle}</Typography>
            </Box>
          )}
          {children}
        </Box>
        {!isMobile && <Footer />}
      </Box>
    </ThemeProvider>
  );
};
