import { ThemeProvider, makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import * as React from "react";

import { useDevice } from "../../../../utilities/sp/use_device";
import Footer from "../footer";
import StepIndicator from "../step_indicator";
import theme from "../theme";

const useStyles = makeStyles(() => ({
  title: {
    color: theme.palette.text.primary,
  },
  message: {
    color: theme.palette.text.primary,
    marginTop: 4,
  },
}));

type Props = {
  children: React.ReactNode;
  title: string;
  canSelectMultiple?: boolean;
  subMessage?: string;
  totalSteps: number;
  currentStep: number;
};

export const RegistrationTemplate: React.FC<Props> = ({
  children,
  title,
  canSelectMultiple,
  subMessage,
  totalSteps,
  currentStep,
}) => {
  const { isMobile } = useDevice();
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Box
        bgcolor={theme.palette.background}
        pt={10}
        textAlign="left"
        minHeight="100vh"
        display="flex"
        flexDirection="column"
      >
        <Box width={isMobile ? "auto" : "390px"} px={3} pb={15} margin="0 auto" flexGrow={1}>
          <StepIndicator steps={totalSteps} currentStep={currentStep} />
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>
          {canSelectMultiple && (
            <Typography variant="body1" className={classes.message}>
              （複数選択可）
            </Typography>
          )}
          {subMessage && (
            <Typography variant="body1" className={classes.message}>
              {subMessage}
            </Typography>
          )}
          {/* <Box mt={4}>{children}</Box> */}
          {children}
        </Box>
        {!isMobile && <Footer />}
      </Box>
    </ThemeProvider>
  );
};
