import { Typography, makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import * as React from "react";
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area, ResponsiveContainer } from "recharts";
import { useRecoilValueLoadable } from "recoil";

import { Loading } from "../../../../pages/loading";
import { useDevice } from "../../../../utilities/sp/use_device";
import { ReducedYearlySimulationResults } from "../../../../view_models/simulation_selectors";
import { ColorScheme } from "../colorscheme";

const Palette = [ColorScheme.PrBlue, ColorScheme.Mint, ColorScheme.Emerald, ColorScheme.LightGray];

export const PrLineChart: React.FC = () => {
  const { isMobile } = useDevice();
  const loadable = useRecoilValueLoadable(ReducedYearlySimulationResults);
  if (loadable.state === "loading") {
    return <Loading />;
  }
  if (loadable.state === "hasError") {
    throw loadable.contents;
  }
  const simulation_data = loadable.contents;

  const Graph: React.FC = () => {
    return (
      <ResponsiveContainer width="100%" height={240}>
        <AreaChart data={simulation_data}>
          <CartesianGrid />
          <XAxis dataKey="year" />
          <YAxis
            unit="万"
            domain={[0, (dataMax) => Math.ceil(dataMax / 1000000) * 1000000]}
            tickFormatter={(props) => Math.ceil(props / 10000).toLocaleString()}
          />
          <Tooltip cursor={{ stroke: ColorScheme.PrBlue, strokeWidth: 1 }} content={<CustomTooltip />} />
          <Area
            type="monotone"
            dataKey="loan_amount"
            name="ローン残高"
            legendType="square"
            strokeWidth={3}
            isAnimationActive={false}
            stroke={ColorScheme.Strawberry}
            fill="none"
          />
          <Legend verticalAlign="bottom" align="left" layout="horizontal" />
          {Array.from(
            Object.keys(simulation_data[0]?.market_prices || {}).map((legend, i) => {
              return (
                <Area
                  key={`area${legend}`}
                  isAnimationActive={false}
                  type="monotone"
                  dataKey={`market_prices.${legend}`}
                  legendType="square"
                  // name={legend}
                  name="物件価格"
                  strokeWidth={3}
                  fill={i < Palette.length ? Palette[i] : "null"}
                  stroke={i < Palette.length ? Palette[i] : "null"}
                  fillOpacity={0.1}
                  stackId="1"
                />
              );
            })
          )}
        </AreaChart>
      </ResponsiveContainer>
    );
  };

  return isMobile ? (
    <Box style={{ overflowX: "scroll" }}>
      <Box width={simulation_data.length * 25} mt={2}>
        {/* years * 25 px */}
        <Graph />
      </Box>
    </Box>
  ) : (
    <Graph />
  );
};

const useStyles = makeStyles((theme) => ({
  background: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.background.paper,
    padding: "2px 8px",
    borderRadius: 4,
  },
}));

const CustomTooltip = ({ active, payload }) => {
  const classes = useStyles();
  if (active && payload && payload.length) {
    const { market_prices } = payload[1].payload;
    const dataKey = payload[1].dataKey.split(".")[1];
    const price = market_prices[dataKey];
    // 売却益
    const profit = price - payload[0].payload.loan_amount;
    return (
      <Box className={classes.background}>
        <Typography variant="caption">{`${payload[0].name} : ${Math.round(
          payload[0].payload.loan_amount / 10000
        ).toLocaleString()}万円`}</Typography>
        <Typography variant="caption">{`${payload[1].name} : ${Math.round(
          price / 10000
        ).toLocaleString()}万円`}</Typography>
        <Typography variant="caption">{`売却益 : ${Math.round(profit / 10000).toLocaleString()}万円`}</Typography>
      </Box>
    );
  }

  return null;
};
