import { makeStyles, Box, Typography, IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import * as React from "react";

import { DropDownMenuItem, DropDownMenu } from "../dropdown_menu";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
    padding: 16,
    marginBottom: 8,
  },
  historyLink: {
    display: "flex",
    justifyContent: "center",
    padding: "7px 8px",
  },
}));

interface props {
  contents: { label: string; value: string }[];
  onEdit?: () => void;
  onDelete?: () => void;
}

export const EditPropertyHistoryItem: React.FC<props> = ({ contents, onEdit, onDelete }) => {
  const classes = useStyles();

  // 3点リーダーメニュー
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchorEl(null);
  };

  const menuItems: DropDownMenuItem[] = React.useMemo(
    () => [
      {
        label: "編集",
        onClick: onEdit,
        color: "textPrimary",
      },
      {
        label: "削除",
        onClick: onDelete,
        color: "error",
      },
    ],
    [onEdit, onDelete]
  );
  return (
    <>
      <Box className={classes.background}>
        {contents.map((item, index) => (
          <Box key={index} mb={index === contents.length - 1 ? 0 : 1 / 2}>
            <Typography variant="h5">{item.label}</Typography>
            <Typography variant="body1">{item.value}</Typography>
          </Box>
        ))}
      </Box>
      {onEdit && onDelete && (
        <IconButton onClick={handleSettingsClick}>
          <MoreVertIcon />
        </IconButton>
      )}
      <DropDownMenu anchorEl={anchorEl} onClose={handleSettingsClose} itemOptions={menuItems} />
    </>
  );
};
