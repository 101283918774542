import { Box, makeStyles, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Joyride, { TooltipRenderProps } from "react-joyride";
import { useLocation } from "react-router";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: 8,
  },
}));

const Tooltip = ({ step }: TooltipRenderProps) => {
  const classes = useStyles();

  return <Box className={classes.tooltip}>{step.content}</Box>;
};

const AppTour = () => {
  const [run, setRun] = useState(false);
  const location = useLocation<{ previousPath?: string }>();

  const steps = [
    {
      target: "#tour-step1",
      content: (
        <Typography>
          こちらで物件の収支情報を確認できます。
          <br />
          <br /> ※初回登録の際には
          <Typography variant="h4" component="span">
            サンプルの物件データ
          </Typography>
          を入れてありますので、不要な場合は物件ごと削除をお願いいたします。
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: "#tour-step2",
      content: (
        <Typography>
          各物件ごとの管理ページはこちらです。
          <br />
          物件の追加・編集・シミュレーションが行え、書類も一括管理可能です。
        </Typography>
      ),
      disableBeacon: true,
      placement: "top" as const,
    },
    {
      target: "#tour-step3",
      content: (
        <Typography>
          エージェントからあなたの情報を見てオファーが届くことがあります。届いたらこちらから確認してみましょう。
        </Typography>
      ),
      disableBeacon: true,
      placement: "top" as const,
    },
    {
      target: "#tour-step4",
      content: (
        <Typography>
          成約事例ページでは、入力した物件の周辺で、実際に成約した価格が確認できます。
          <br />
          相場の把握に利用いただけます。
        </Typography>
      ),
      disableBeacon: true,
      placement: "top" as const,
    },
  ];

  useEffect(() => {
    // 初回のみツアーを開始する
    if (location.state?.previousPath === "/first_account") {
      setRun(true);
    }
  }, []);

  return (
    <Joyride
      steps={steps}
      run={run}
      disableScrolling
      disableCloseOnEsc
      hideCloseButton
      tooltipComponent={Tooltip}
      styles={{
        options: {
          zIndex: 5000,
        },
      }}
      floaterProps={{
        placement: "center",
        styles: {
          floater: {
            width: "85%",
          },
        },
      }}
    />
  );
};

export default AppTour;
