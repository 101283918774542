import * as React from "react";

interface props {
  width?: string;
  height?: string;
  fill?: string;
}

const OfferAchievement: React.FC<props> = ({ width = "16", height = "21", fill = "#103894" }): React.JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 7.99998C5.01036 7.20755 5.32976 6.45049 5.89014 5.89011C6.45051 5.32973 7.20757 5.01034 8 4.99998C8.79243 5.01034 9.54949 5.32973 10.1099 5.89011C10.6702 6.45049 10.9896 7.20755 11 7.99998C10.9896 8.7924 10.6702 9.54946 10.1099 10.1098C9.54949 10.6702 8.79243 10.9896 8 11C7.20757 10.9896 6.45051 10.6702 5.89014 10.1098C5.32976 9.54946 5.01036 8.7924 5 7.99998ZM8 17L12 18V14.92C10.7937 15.6464 9.40802 16.0206 8 16C6.59198 16.0206 5.20632 15.6464 4 14.92V18M8 1.99998C7.21197 1.9856 6.42932 2.13254 5.70017 2.43177C4.97102 2.73099 4.31076 3.17619 3.76 3.73998C3.19011 4.29134 2.73977 4.95408 2.43699 5.68695C2.13421 6.41982 1.98546 7.20715 2 7.99998C1.98958 8.78778 2.14033 9.56939 2.44299 10.2968C2.74565 11.0242 3.19383 11.6821 3.76 12.23C4.3082 12.7992 4.96729 13.2499 5.69657 13.5544C6.42584 13.8588 7.2098 14.0105 8 14C8.7902 14.0105 9.57416 13.8588 10.3034 13.5544C11.0327 13.2499 11.6918 12.7992 12.24 12.23C12.8062 11.6821 13.2543 11.0242 13.557 10.2968C13.8597 9.56939 14.0104 8.78778 14 7.99998C14.0145 7.20715 13.8658 6.41982 13.563 5.68695C13.2602 4.95408 12.8099 4.29134 12.24 3.73998C11.6892 3.17619 11.029 2.73099 10.2998 2.43177C9.57068 2.13254 8.78803 1.9856 8 1.99998ZM16 7.99998C15.9787 8.95986 15.7857 9.90816 15.43 10.8C15.1096 11.7074 14.6248 12.5481 14 13.28V21L8 19L2 21V13.28C0.705689 11.8264 -0.00649957 9.94625 -3.64613e-07 7.99998C-0.0176252 6.95056 0.180037 5.90867 0.580782 4.93862C0.981528 3.96858 1.57685 3.09097 2.33 2.35998C3.0637 1.60007 3.94536 0.998608 4.92056 0.592705C5.89576 0.186802 6.9438 -0.0149247 8 -2.42671e-05C9.0562 -0.0149247 10.1042 0.186802 11.0794 0.592705C12.0546 0.998608 12.9363 1.60007 13.67 2.35998C14.4231 3.09097 15.0185 3.96858 15.4192 4.93862C15.82 5.90867 16.0176 6.95056 16 7.99998Z"
      fill={fill}
    />
  </svg>
);
export default OfferAchievement;
