import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import dayjs = require("dayjs");
import * as React from "react";
import { useRecoilState } from "recoil";

import { PropertiesSelector } from "../../scripts/components/property_selector/properties_selector";
import { PreviousVersionTemplate } from "../../scripts/components/renewal_v1/template/previous_version_template";
import { Tab } from "../../scripts/components/tab";
import { useDevice } from "../../utilities/sp/use_device";
import { SelectedEndingMonth, SelectedStartingMonth } from "../../view_models/atoms";
import { Loading } from "../loading";

import { BalanceSheet } from "./balance_sheet";
import { ProfitAndLossSheet } from "./profit_and_loss_sheet";
import { MobileNavigation } from "./sp/navigation";

export const Balance: React.FC = () => {
  const { isMobile } = useDevice();
  const [selected_starting_month, setSelectedStartingYear] = useRecoilState(SelectedStartingMonth);
  const [selected_ending_month, setSelectedEndingYear] = useRecoilState(SelectedEndingMonth);

  React.useEffect(() => {
    if (selected_ending_month.diff(selected_starting_month, "year") !== 1) {
      setSelectedStartingYear(dayjs().startOf("year"));
      setSelectedEndingYear(dayjs().endOf("year"));
    }
  }, []);

  if (selected_ending_month.diff(selected_starting_month, "year") !== 0) {
    return <Loading />;
  }

  const Navigation: React.FC = () => {
    return (
      <>
        <Box display="flex" alignItems="center" maxWidth>
          <Typography component="div" variant="body2" color="textSecondary">
            <Box fontWeight="fontWeightBold">表示期間：</Box>
          </Typography>
          <Box mr={2} display="flex" alignItems="center" bgcolor="white" borderRadius={8} py={1} px={2}>
            <Link
              variant="caption"
              color="textSecondary"
              href="#"
              onClick={() => {
                setSelectedStartingYear(dayjs(selected_starting_month).subtract(12, "month"));
                setSelectedEndingYear(dayjs(selected_ending_month).subtract(12, "month"));
              }}
            >
              <ArrowBackIosIcon fontSize="small" color="inherit" style={{ fontSize: 15 }} />
            </Link>
            <Typography color="primary" variant="caption">
              <Box fontWeight="bold" mx={1}>
                {dayjs(selected_starting_month).format("YYYY/MM")}
                　〜
                {dayjs(selected_ending_month).format("YYYY/MM")}
              </Box>
            </Typography>
            <Link
              variant="caption"
              color="textSecondary"
              href="#"
              onClick={() => {
                setSelectedStartingYear(dayjs(selected_starting_month).add(12, "month"));
                setSelectedEndingYear(dayjs(selected_ending_month).add(12, "month"));
              }}
            >
              <ArrowForwardIosIcon fontSize="small" color="inherit" style={{ fontSize: 15 }} />
            </Link>
          </Box>
          <Typography component="div" variant="body2" color="textSecondary">
            <Box fontWeight="fontWeightBold">表示物件：</Box>
          </Typography>
          <PropertiesSelector />
        </Box>
      </>
    );
  };

  return (
    // リニューアルに合わせてroutes.tsから移行
    <PreviousVersionTemplate>
      <Box mb={isMobile ? 3 : 6}>
        <Typography variant={isMobile ? "subtitle1" : "h1"}>収支表・損益計算書</Typography>
      </Box>

      {isMobile && (
        <Box mb={4}>
          <MobileNavigation
            setStartingYear={setSelectedStartingYear}
            setEndingYear={setSelectedEndingYear}
            startingYear={selected_starting_month}
            endingYear={selected_ending_month}
          />
        </Box>
      )}

      <Tab
        pages={[
          { label: "収支表", page: BalanceSheet },
          { label: "損益計算書", page: ProfitAndLossSheet },
        ]}
        defaultLabel="収支表"
        Navigation={isMobile ? <></> : <Navigation />}
      />
    </PreviousVersionTemplate>
  );
};
