import { styled } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { PropsWithChildren } from "react";

type AccountRegisteredModalProps = {
  open: boolean;
  handleClose: () => void;
  handleClick: () => void;
};

const ModalBody = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#EEE",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-evenly",
  padding: "16px",
  width: "80%",
  maxWidth: "400px",
});

const StyledApproveButton = styled(Button)({
  display: "flex",
  borderRadius: "999px",
  color: "white",
  "&:hover": {
    opacity: 0.8,
  },
});

export const AccountRegisteredModal: React.FC<PropsWithChildren<AccountRegisteredModalProps>> = ({
  open,
  handleClose,
  handleClick,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <ModalBody>
        <Typography style={{ textAlign: "center" }}>
          ご入力ありがとうございました。
          <br />
          投資オファーを受けることが可能になりました！
          <br />
          その他の機能をご利用頂く場合は物件情報を入力してください。
        </Typography>
        <Box display="flex" width="100%" justifyContent="space-evenly">
          <StyledApproveButton onClick={handleClick} style={{ background: "#5772ff" }}>
            確認
          </StyledApproveButton>
        </Box>
      </ModalBody>
    </Modal>
  );
};
