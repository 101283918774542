import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import React, { FC, useState } from "react";
import { useHistory } from "react-router";

import OfferAssessmentImage from "../../../../../assets/image/offer_assessment.png";
import OfferAssessmentNotRecievedImage from "../../../../../assets/image/offer_assessment_not_recieved.png";
import { useGetAssesmentProposals } from "../../../../hooks/useGetAssesmentProposals";
import { NoContentView } from "../../../../scripts/components/renewal_v1/no_content";
import { OfferAssessmentCard } from "../../../../scripts/components/renewal_v1/offer/assessment_card";
import CustomPagination from "../../../../scripts/components/renewal_v1/pagination";
import { calculatePaginationAssessmentProposal } from "../../../../utilities/renewal_v1/pagination";
import { Loading } from "../../../loading";

const useStyles = makeStyles({
  image: {
    width: 80,
    height: "auto",
  },
});

export const OfferAssessmentTab: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { assessmentProposals, loading } = useGetAssesmentProposals();

  // ページネーション
  const [currentPage, setCurrentPage] = useState<number>(1);

  // ページネーションのためのロジック
  const { currentAssessmentProposals, totalPages } = calculatePaginationAssessmentProposal(
    assessmentProposals,
    currentPage
  );

  if (loading) {
    return <Loading />;
  }

  if (assessmentProposals.length === 0) {
    return (
      <NoContentView
        mainMessage="まだ査定オファーが届いていません"
        subMessage="オファーが届きやすいよう、保有している物件の情報を登録しましょう。"
        buttonText="保有物件を登録する"
        image={OfferAssessmentNotRecievedImage}
        onClick={() => history.push("/management")}
      />
    );
  }
  return (
    <Box>
      <Box pb={3} display="flex" alignItems="center">
        <img src={OfferAssessmentImage} alt="receiving_investment_offer" className={classes.image} />
        <Box>
          <Box mb={1}>
            <Typography variant="h4">あなたに査定オファーが届いています！</Typography>
          </Box>
          <Typography variant="body2">
            不動産の専門家に、物件の資産価値や物件の売却について相談してみましょう。
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2}>
        {currentAssessmentProposals.map((assessmentProposal) => (
          <Grid item xs={12} sm={6} key={assessmentProposal.id}>
            <OfferAssessmentCard
              profileImagePath={assessmentProposal.agent?.agent_profile_image?.image_url || ""}
              proposalDate={assessmentProposal?.proposal_date}
              companyImagePath={assessmentProposal?.agent?.company?.company_logo_image?.image_url || ""}
              companyName={assessmentProposal?.agent?.company?.name}
              representativeName={assessmentProposal?.agent?.family_name + assessmentProposal?.agent?.first_name}
              shortAppealMessage={assessmentProposal?.agent.company.short_appeal_message}
              proposalMessage={assessmentProposal?.proposal_content}
              proposalPrice={assessmentProposal?.proposal_price}
              propertyName={assessmentProposal?.property?.name}
              isUserApproved={assessmentProposal?.is_user_approved}
              isNew={!assessmentProposal?.is_user_read}
              isRead={assessmentProposal?.is_user_read}
              onClick={() => history.push(`/assessment/${assessmentProposal.id}`)}
            />
          </Grid>
        ))}
      </Grid>
      <Box pt={2} pb={12}>
        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </Box>
    </Box>
  );
};
