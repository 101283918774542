import { Box } from "@material-ui/core";
import * as React from "react";
import { useHistory } from "react-router";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";

import { Toast } from "../../../../scripts/components/renewal_v1/toast";
import { PropertyEditPageState } from "../../../../view_models/atoms";
import { SelectedProperty } from "../../../../view_models/property_selectors";
import { Loading } from "../../../loading";

import { EditPropertySectionInsuranceInfo } from "./sections/insurance_info";
import { EditPropertySectionLeaseContract } from "./sections/lease_contract";
import { EditPropertySectionLoanInfo } from "./sections/loan_info";
import { EditPropertySectionManagementCost } from "./sections/management_cost";
import { EditPropertySectionPropertyInfo } from "./sections/property_info";
import { EditPropertySectionPropertyInfoExtra } from "./sections/property_info_extra";
import { EditPropertySectionPurchaseInfo } from "./sections/purchase_info";

const EditPropertyPage: React.FC = () => {
  const history = useHistory();
  const property_loadable = useRecoilValueLoadable(SelectedProperty);
  // サーバーエラー
  const [serverError, setSeverError] = React.useState<string>("");
  // 表示するコンポーネント
  const propertyEditPageState = useRecoilValue(PropertyEditPageState);

  const onClickPageBack = React.useCallback(() => {
    window.scrollTo(0, 0);
    history.goBack();
  }, []);

  // サーバーエラー表示トースト
  const handleCloseToast = React.useCallback(() => {
    setSeverError("");
  }, []);

  // 物件詳細から編集ボタンを押さずに遷移してきた場合は物件詳細に遷移
  React.useEffect(() => {
    if (!propertyEditPageState) {
      history.push("/management/detail");
    }
  }, [propertyEditPageState, history]);

  if (property_loadable.state === "loading") {
    return <Loading />;
  }
  if (property_loadable.state === "hasError") {
    throw property_loadable.contents;
  }
  const property = property_loadable.contents;

  if (!property) {
    return <Loading />;
  }

  return (
    <Box>
      {/* 購入時情報 */}
      {propertyEditPageState === "purchase_info" ? (
        <EditPropertySectionPurchaseInfo propertyHolder={property} onClickPageBack={onClickPageBack} />
      ) : null}
      {/* 物件詳細 */}
      {propertyEditPageState === "property_info" ? (
        <EditPropertySectionPropertyInfo
          propertyHolder={property}
          onClickPageBack={onClickPageBack}
          setSeverError={setSeverError}
        />
      ) : null}
      {/* 物件詳細の単発情報 */}
      {propertyEditPageState === "property_info_extra" ? (
        <EditPropertySectionPropertyInfoExtra propertyHolder={property} onClickPageBack={onClickPageBack} />
      ) : null}

      {/* 経費情報 */}
      {propertyEditPageState === "management_cost" ? (
        <EditPropertySectionManagementCost
          propertyHolder={property}
          onClickPageBack={onClickPageBack}
          // setSeverError={setSeverError}
        />
      ) : null}
      {/* 借入情報 */}
      {propertyEditPageState === "loan_info" ? (
        <EditPropertySectionLoanInfo
          propertyHolder={property}
          onClickPageBack={onClickPageBack}
          // setSeverError={setSeverError}
        />
      ) : null}

      {/* 賃貸契約情報 */}
      {propertyEditPageState === "lease_contract" ? (
        <EditPropertySectionLeaseContract
          propertyHolder={property}
          onClickPageBack={onClickPageBack}
          // setSeverError={setSeverError}
        />
      ) : null}

      {/* 保険情報 */}
      {propertyEditPageState === "insurance_info" ? (
        <EditPropertySectionInsuranceInfo
          propertyHolder={property}
          onClickPageBack={onClickPageBack}
          // setSeverError={setSeverError}
        />
      ) : null}

      <Toast open={!!serverError} variant="error" message={serverError} onClose={handleCloseToast} />
    </Box>
  );
};

export default EditPropertyPage;
