import { useState, useEffect } from "react";

import { AssessmentProposal } from "../api/generated";
import { PropallyApiFactory } from "../module/custom_api_factory";

export const useGetAssesmentProposalDetails = (id: number) => {
  const [proposalData, setProposalData] = useState<AssessmentProposal>(null);
  const [loading, setLoading] = useState(false);

  const fp = PropallyApiFactory();

  const fetchAssessmentProposalById = async () => {
    try {
      setLoading(true);

      const data = await fp.v1AssessmentProposalsAssessmentProposalIdGet(Number(id), { withCredentials: true });
      if (data.status === 200) {
        setProposalData(data.data);
        setLoading(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchAssessmentProposalById();
  }, []);

  return { fetchAssessmentProposalById, proposalData, loading };
};
