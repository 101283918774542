import { Checkbox, FormControlLabel, FormControl, Typography, CheckboxProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(1),
  },
  checkbox: {
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
  error: {
    color: theme.palette.error.main,
    "&$checked": {
      color: theme.palette.error.main,
    },
  },
  disabled: {
    color: theme.palette.action.disabled,
  },
  label: {
    color: theme.palette.text.primary,
  },
}));

interface CustomCheckboxProps extends CheckboxProps {
  label: string;
  helperText?: string;
  checked: boolean;
  onChange: (_event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  disabled?: boolean;
}

const CustomCheckboxRef: React.ForwardRefRenderFunction<HTMLInputElement, CustomCheckboxProps> = (
  { label, helperText = "", checked, onChange, error = false, disabled = false },
  ref
) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.root}>
      <FormControlLabel
        control={
          <Checkbox
            icon={error ? <CheckBoxOutlineBlankIcon className={classes.error} /> : <CheckBoxOutlineBlankIcon />}
            checkedIcon={<CheckBoxIcon />}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            inputRef={ref}
            classes={{
              root: classes.checkbox,
              checked: error ? classes.error : classes.checked,
              disabled: classes.disabled,
            }}
          />
        }
        label={
          <>
            <Typography variant="h4" className={error ? classes.error : classes.label}>
              {label}
            </Typography>
            {helperText &&
              <Typography variant="caption">{helperText}</Typography>
            }
          </>
        }
      />
    </FormControl>
  );
};

const CustomCheckbox = React.forwardRef(CustomCheckboxRef);
export { CustomCheckbox };

// サンプルコンポーネントでチェックボックスを使用する
const CheckboxExample: React.FC = () => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <div className={classes.root}>
      <CustomCheckbox label="選択肢" checked={checked} onChange={handleChange} />
      <CustomCheckbox label="選択肢" checked={checked} onChange={handleChange} error />
      <CustomCheckbox label="選択肢" checked={true} onChange={handleChange} />
      <CustomCheckbox label="選択肢" checked={false} onChange={handleChange} disabled />
    </div>
  );
};

export default CheckboxExample;
