/**
 * Storageキー定義
 */
import { AccountRegisterFormSchema } from "../pages/renewal_v1/account/types";
import { AddNewPropertyFormSchema } from "../pages/renewal_v1/management/add_new_property/type";
import { PropertyHolder } from "../view_models/atoms";

const SELECTED_PROPERTY_ID_STORAGE_KEY = "SELECTED_PROPERTY_ID";
const PROPERTY_FORM_STORAGE_KEY = "PROPERTY_FORM";
const SELECTED_PROPERTY_STORAGE_KEY = "SELECTED_PROPERTY";
const APP_DEVICE_TOKEN = "APP_DEVICE_TOKEN";
const PROPERTY_TEMPORARY_FORM_STORAGE_KEY = "PROPERTY_TEMPORARY_FORM";
const PROPERTY_TEMPORARY_STEP_STORAGE_KEY = "PROPERTY_TEMPORARY_STEP";

export const clearStorage = (): void => {
  sessionStorage.clear();
};

export const getStorageSelectedPropertyId = (): number => {
  return Number(sessionStorage.getItem(SELECTED_PROPERTY_ID_STORAGE_KEY));
};

export const setStorageSelectedPropertyId = (value: number) => {
  sessionStorage.setItem(SELECTED_PROPERTY_ID_STORAGE_KEY, String(value));
};

export const removeStorageSelectedPropertyId = () => {
  sessionStorage.removeItem(SELECTED_PROPERTY_ID_STORAGE_KEY);
};

export const getStoragePropertyForm = (): Object => {
  return JSON.parse(sessionStorage.getItem(PROPERTY_FORM_STORAGE_KEY));
};

export const setStoragePropertyForm = (value: Object) => {
  sessionStorage.setItem(PROPERTY_FORM_STORAGE_KEY, JSON.stringify(value));
};
export const setStoragePropertyFormString = (value: string) => {
  sessionStorage.setItem(PROPERTY_FORM_STORAGE_KEY, value);
};

export const removeStoragePropertyForm = () => {
  sessionStorage.removeItem(PROPERTY_FORM_STORAGE_KEY);
};

export const getStorageSelectedProperty = (): PropertyHolder => {
  return JSON.parse(sessionStorage.getItem(SELECTED_PROPERTY_STORAGE_KEY));
};

export const setStorageSelectedProperty = (value: PropertyHolder) => {
  sessionStorage.setItem(SELECTED_PROPERTY_STORAGE_KEY, JSON.stringify(value));
};

export const removeStorageSelectedProperty = () => {
  sessionStorage.removeItem(SELECTED_PROPERTY_STORAGE_KEY);
};

export const getAppDeviceToken = (): string => {
  return sessionStorage.getItem(APP_DEVICE_TOKEN);
};

export const setAppDeviceToken = (value: string) => {
  sessionStorage.setItem(APP_DEVICE_TOKEN, value);
};

export const removeAppDeviceToken = () => {
  sessionStorage.removeItem(APP_DEVICE_TOKEN);
};

export const getStoragePropertyTemporaryForm = (): Object => {
  return JSON.parse(sessionStorage.getItem(PROPERTY_TEMPORARY_FORM_STORAGE_KEY));
};

export const setStoragePropertyTemporaryForm = (value: string) => {
  sessionStorage.setItem(PROPERTY_TEMPORARY_FORM_STORAGE_KEY, value);
};

export const removePropertyTemporaryForm = () => {
  sessionStorage.removeItem(PROPERTY_TEMPORARY_FORM_STORAGE_KEY);
};

export const getStoragePropertyTemporaryStep = (): number => {
  return Number(sessionStorage.getItem(PROPERTY_TEMPORARY_STEP_STORAGE_KEY));
};

export const setStoragePropertyTemporaryStep = (value: number) => {
  sessionStorage.setItem(PROPERTY_TEMPORARY_STEP_STORAGE_KEY, String(value));
};

export const removeStoragePropertyTemporaryStep = () => {
  sessionStorage.removeItem(PROPERTY_TEMPORARY_STEP_STORAGE_KEY);
};

// アカウント登録
export const setStorageAccountRegistrationState = (value: AccountRegisterFormSchema): void => {
  if (typeof window === "undefined") return;
  if (typeof sessionStorage === "undefined") return;
  sessionStorage.setItem("ACCOUNT_REGISTER", JSON.stringify(value));
};

export const getStorageAccountRegistrationState = (): AccountRegisterFormSchema => {
  if (typeof window === "undefined") return;
  if (typeof sessionStorage === "undefined") return;
  return JSON.parse(sessionStorage.getItem("ACCOUNT_REGISTER"));
};

export const removeStorageAccountRegistrationState = (): void => {
  if (typeof window === "undefined") return;
  if (typeof sessionStorage === "undefined") return;
  sessionStorage.removeItem("ACCOUNT_REGISTER");
};


// 物件登録 - 保有物件
export const setStoragePropertyRegistrationState = (value: AddNewPropertyFormSchema): void => {
  if (typeof window === "undefined") return;
  if (typeof localStorage === "undefined") return;
  localStorage.setItem("OWN_PROPERTY_REGISTER", JSON.stringify(value));
};

export const getStoragePropertyRegistrationState = (): AddNewPropertyFormSchema => {
  if (typeof window === "undefined") return;
  if (typeof localStorage === "undefined") return;
  return JSON.parse(localStorage.getItem("OWN_PROPERTY_REGISTER"));
};

export const removeStoragePropertyRegistrationState = (): void => {
  if (typeof window === "undefined") return;
  if (typeof localStorage === "undefined") return;
  localStorage.removeItem("OWN_PROPERTY_REGISTER");
};




// 物件登録 - 検討中物件
export const setStorageConsiderationPropertyRegistrationState = (value: AddNewPropertyFormSchema): void => {
  if (typeof window === "undefined") return;
  if (typeof localStorage === "undefined") return;
  localStorage.setItem("CONSIDERATION_PROPERTY_REGISTER", JSON.stringify(value));
};

export const getStorageConsiderationPropertyRegistrationState = (): AddNewPropertyFormSchema => {
  if (typeof window === "undefined") return;
  if (typeof localStorage === "undefined") return;
  return JSON.parse(localStorage.getItem("CONSIDERATION_PROPERTY_REGISTER"));
};

export const removeStorageConsiderationPropertyRegistrationState = (): void => {
  if (typeof window === "undefined") return;
  if (typeof localStorage === "undefined") return;
  localStorage.removeItem("CONSIDERATION_PROPERTY_REGISTER");
};

