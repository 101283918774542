import * as React from "react";

interface props {
  width?: string;
  height?: string;
  fill?: string;
}

const OfferStrengthOfPartnerFinancialInstitutions: React.FC<props> = ({
  width = "19",
  height = "20",
  fill = "#103894",
}): React.JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.5 9H2.5V16H4.5V9ZM10.5 9H8.5V16H10.5V9ZM19 18H0V20H19V18ZM16.5 9H14.5V16H16.5V9ZM9.5 2.26L14.71 5H4.29L9.5 2.26ZM9.5 0L0 5V7H19V5L9.5 0Z"
      fill={fill}
    />
  </svg>
);
export default OfferStrengthOfPartnerFinancialInstitutions;
