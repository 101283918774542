import { Box, Grid, Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import React, { FC } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    borderRadius: "4px 4px 0px 0px",
    borderStyle: "solid",
    borderWidth: "0px 0px 1px 0px",
    borderColor: theme.palette.divider,
  },
  media: {
    height: 200,
  },
}));

interface props {
  price: string;
  type: string;
  prefecture: string;
  address: string;
  year: string;
  structure: string;
  area: string;
  transactionPeriod: string;
}

export const PropertyCardNearbyPrice: FC<props> = ({
  price,
  type,
  prefecture,
  address,
  year,
  structure,
  area,
  transactionPeriod,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Box mb={1}>
          <Typography>成約価格</Typography>
          <Typography variant="h1" color="primary">
            {price}
            <Typography component="span"> 万円</Typography>
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Typography color="textSecondary">種類</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{type || "-"}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography color="textSecondary">都道府県</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{prefecture || "-"}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography color="textSecondary">所在地</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>{address || "-"}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color="textSecondary">建築年</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{year || "-"}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography color="textSecondary">構造</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>{structure || "-"} </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color="textSecondary">面積</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{area || "-"}㎡</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography color="textSecondary">取引時期</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{transactionPeriod || "-"}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
