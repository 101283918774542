import dayjs from "dayjs";

import { Property } from "../api/generated";
import { prefecture_unselected } from "../pages/management/add_new_property/basic_info";
import { PropertyHolder } from "../view_models/atoms";
import { CashflowResultWithLatestResult } from "../view_models/balancesheet_selectors";
import { getCurrentContract } from "../view_models/helpers";

export const getPropertyAddressString = (property: PropertyHolder, needTownAndBlock = true): string => {
  const prefecture =
    !property.property.prefecture || property.property.prefecture === prefecture_unselected
      ? ""
      : property.property.prefecture;
  const city = property.property.city || "";
  const town = property.property.town || "";
  const block = property.property.block || "";

  if (prefecture === "" && city === "" && town === "" && block === "") {
    return "未入力";
  }

  return prefecture + city + (needTownAndBlock ? town + block : "");
};

export const getPropertyAccessFromNearestStation = (property: Property): string => {
  return property.railway === undefined || property.station === undefined || property.walk === undefined
    ? "最寄駅未入力"
    : `${property.railway + property.station}駅徒歩` + `${property.walk}` + "分";
};

export const getNumberOfYearsOfBuilding = (property: Property): string => {
  if (property.built_at === undefined) return "築年数未入力";
  const built_at = dayjs(property.built_at);

  return built_at.format(`YYYY年MM月（築${dayjs().diff(built_at, "year")}年）`);
};

export const getCurrentContractPeriod = (property: PropertyHolder): string => {
  const current_contract = getCurrentContract(property.lease_contracts);
  return current_contract == null
    ? "現在の契約なし"
    : `${dayjs(current_contract.starts_at).format("YYYY年MM月DD日")} ～ ${dayjs(current_contract.ends_at).format(
        "YYYY年MM月DD日"
      )}`;
};

export const getYieldRate = (income: number, totalPriceOfProperty: number): string => {
  return (
    Math.round(
      ((income * 12) / totalPriceOfProperty) * 100 * 100 // 小数点第二位まで
    ) / 100
  ).toLocaleString(undefined, {
    minimumFractionDigits: 2,
  });
};

export const getActualYieldRate = (
  income: number,
  expence: number,
  propertyTaxOfLastYear: number,
  propertyAcquisitionTax: number,
  miscExpencesAtBought: number,
  totalPriceOfProperty: number
): string => {
  return (
    Math.round(
      (((income - expence) * 12 - propertyTaxOfLastYear) /
        (totalPriceOfProperty + propertyAcquisitionTax + miscExpencesAtBought)) *
        100 *
        100 // 小数点第二位まで
    ) / 100
  ).toLocaleString(undefined, {
    minimumFractionDigits: 2,
  });
};

export const getActualYieldRateByCashflowResult = (
  property: PropertyHolder,
  result: CashflowResultWithLatestResult
): string => {
  return getActualYieldRate(
    result.rent_income + result.management_fee_income,
    result.repair_fund_cost + result.building_management_cost + result.management_cost + result.misc_cost,
    result.property_tax_of_last_year || 0,
    property.property.property_acquisition_tax || 0,
    property.property.misc_expences_at_bought || 0,
    property.property.total_price_of_property
  );
};

export const mapSearchQuery = (property: PropertyHolder): string | null => {
  // 住所情報が全て未入力ならnull
  if (
    !property.property.prefecture ||
    property.property.prefecture === prefecture_unselected ||
    !property.property.city ||
    !property.property.town ||
    !property.property.block
  ) {
    return null;
  }
  return property.property.prefecture + property.property.city + property.property.town + property.property.block;
};
