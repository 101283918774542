import * as React from "react";

interface props {
  width?: string;
  height?: string;
  fill?: string;
}

const OfferAdviceAndFollowup: React.FC<props> = ({
  width = "20",
  height = "20",
  fill = "#103894",
}): React.JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.72 12.76C17.07 11.91 17.26 11 17.26 10C17.26 9.28001 17.15 8.59001 16.96 7.95001C16.31 8.10001 15.63 8.18001 14.92 8.18001C11.86 8.18001 9.15 6.67001 7.5 4.34001C6.61 6.50001 4.91 8.26001 2.77 9.22001C2.73 9.47001 2.73 9.74001 2.73 10C2.73 11.9281 3.49594 13.7773 4.85933 15.1407C6.22272 16.5041 8.07188 17.27 10 17.27C11.05 17.27 12.06 17.04 12.97 16.63C13.54 17.72 13.8 18.26 13.78 18.26C12.14 18.81 10.87 19.08 10 19.08C7.58 19.08 5.27 18.13 3.57 16.42C2.53 15.38 1.76 14.11 1.33 12.73H0V8.18001H1.09C1.93 4.04001 5.6 0.920013 10 0.920013C12.4 0.920013 14.71 1.87001 16.42 3.58001C17.69 4.84001 18.54 6.45001 18.89 8.18001H20V12.67V12.69V12.73H19.94L16.38 16L11.08 15.4V13.73H15.91L16.72 12.76ZM7.27 9.77001C7.57 9.77001 7.86 9.89001 8.07 10.11C8.28 10.32 8.4 10.61 8.4 10.91C8.4 11.21 8.28 11.5 8.07 11.71C7.86 11.92 7.57 12.04 7.27 12.04C6.64 12.04 6.13 11.54 6.13 10.91C6.13 10.28 6.64 9.77001 7.27 9.77001ZM12.72 9.77001C13.35 9.77001 13.85 10.28 13.85 10.91C13.85 11.54 13.35 12.04 12.72 12.04C12.09 12.04 11.58 11.54 11.58 10.91C11.58 10.6077 11.7001 10.3177 11.9139 10.1039C12.1277 9.89012 12.4177 9.77001 12.72 9.77001Z"
      fill={fill}
    />
  </svg>
);
export default OfferAdviceAndFollowup;
