import dayjs from "dayjs";

import { LeaseContract } from "../../api/generated";

/*
 リース契約の最新の月額料金を計算
*/
export const getLatestMonthlyFeeFromProperty = (leaseContracts: LeaseContract[]): number => {
  if (!leaseContracts.length) return 0;
  const latestContract = leaseContracts.reduce((latest, contract) => {
    const latestDate = new Date(latest.starts_at);
    const contractDate = new Date(contract.starts_at);
    return contractDate > latestDate ? contract : latest;
  });
  return latestContract.monthly_fee + latestContract.monthly_management_fee;
};

// 現在入居中の物件があるかどうか判定する関数
export const isPropertyCurrentInUse = (leaseContracts: LeaseContract[]): boolean => {
  const now = dayjs(new Date());
  return leaseContracts.some((contract) => {
    const start = dayjs(contract.starts_at);
    const end = dayjs(contract.ends_at);
    return now >= start && now <= end;
  });
};


