import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Toolbar from "@material-ui/core/Toolbar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { ColorScheme } from "../../../colorscheme";
import { Logo } from "../logo";
import { PropallyMenu } from "../menu";

const zIndices = {
  Navbar: 10000,
  Sidebar: 10001,
} as const;

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    background: ColorScheme.DarkBlue,
    zIndex: zIndices.Navbar,
  },
  toolbar: {
    paddingTop: "env(safe-area-inset-top)",
    minHeight: "calc(62px + env(safe-area-inset-top))",
  },
  navbarButton: {
    position: "absolute",
    color: "inherit",
  },
  paper: {
    width: 280,
    background: ColorScheme.DarkBlue,
    // MEMO: 2ndで追加したメニューを含めると高さが1015pxが必要になる。
    // そのため、heightをautoにしておいても問題ないと思われる。
    height: "auto",
    paddingTop: "env(safe-area-inset-top)",
  },
  drawerButton: {
    color: "white",
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "& svg": {
      fontSize: 25,
    },
  },
  logo: {
    display: "block",
    margin: "auto",
  },
  iconButton: {
    color: theme.palette.common.white,
  },
  iconRoot: {
    padding: 0,
  },
}));

export const Navbar: React.FC = () => {
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpenState] = React.useState(false);
  const setDrawer = (s: boolean) => (_) => setIsDrawerOpenState(s);
  const history = useHistory();

  const location = useLocation();
  React.useEffect(() => {
    setIsDrawerOpenState(false);
  }, [location]);

  const navbarRef = React.useRef(null);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        // clicked outside on the navbar
        setIsDrawerOpenState(false);
      }
    };
    if (isDrawerOpen && navbarRef.current) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [navbarRef, isDrawerOpen]);

  // リニューアル後の実装
  const onBack = () => {
    history.goBack();
  };

  return (
    <>
      <AppBar className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <IconButton className={classes.iconButton} classes={{ root: classes.iconRoot }} onClick={onBack}>
            <ArrowBackIcon />
          </IconButton>
          <div className={classes.logo}>
            <Logo color="white" />
          </div>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        // MEMO: style属性で指定しないと上書きできない
        style={{ zIndex: zIndices.Sidebar }}
        anchor="left"
        open={isDrawerOpen}
        onClose={setDrawer(false)}
        onOpen={setDrawer(true)}
      >
        <div className={classes.paper} ref={navbarRef}>
          <IconButton edge="start" className={classes.drawerButton} aria-label="menu" onClick={setDrawer(false)}>
            <CloseIcon />
          </IconButton>
          <PropallyMenu />
        </div>
      </SwipeableDrawer>
    </>
  );
};
