import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: theme.spacing(4),
  },
  step: {
    width: 20,
    height: 7,
    borderRadius: 20,
    backgroundColor: theme.palette.grey[300],
    margin: theme.spacing(0.5),
  },
  activeStep: {
    backgroundColor: theme.palette.primary.main,
  },
  text: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
}));

interface StepIndicatorProps {
  steps: number;
  currentStep: number;
}

const StepIndicator: React.FC<StepIndicatorProps> = ({ steps, currentStep }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {Array.from({ length: steps }).map((_, index) => (
        <Box key={index} className={`${classes.step} ${index < currentStep ? classes.activeStep : ""}`} />
      ))}
    </Box>
  );
};

export default StepIndicator;
