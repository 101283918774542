import { Box, Typography, makeStyles } from "@material-ui/core";
import * as React from "react";

import { CustomButton } from "./button";

const useStyles = makeStyles((theme) => ({
  image: {
    width: 220,
    height: "auto",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(14),
    [theme.breakpoints.up("sm")]: {
      width: 320,
    },
  },
  mainMessage: {
    marginBottom: theme.spacing(1.5),
  },
  subMessage: {
    marginBottom: theme.spacing(4),
    whiteSpace: "pre-line", // 改行コードを解釈して表示
  },
  buttonWrapper: {
    margin: theme.spacing(0, 9),
    paddingBottom: theme.spacing(11),
  },
}));

interface props {
  mainMessage: string;
  subMessage: string;
  buttonText: string;
  image: string;
  onClick?: () => void;
}
export const NoContentView: React.FC<props> = ({ mainMessage, subMessage, buttonText, image, onClick }) => {
  const classes = useStyles();
  return (
    <Box margin="0 auto" textAlign="center" display="flex" flexDirection="column" alignItems="center">
      <img src={image} alt="No property" className={classes.image} />
      <Typography variant="h3" className={classes.mainMessage}>
        {mainMessage}
      </Typography>
      <Typography variant="body1" className={classes.subMessage}>
        {subMessage}
      </Typography>
      {onClick && (
        <Box className={classes.buttonWrapper}>
          <CustomButton customVariant="filled" onClick={onClick}>
            {buttonText}
          </CustomButton>
        </Box>
      )}
    </Box>
  );
};
