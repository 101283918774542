import DateFnsUtils from "@date-io/date-fns";
import { TextField, makeStyles, InputAdornment, IconButton, Box } from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import jaLocale from "date-fns/locale/ja";
import * as React from "react";

import CustomFormLabel from "./form_label";

const useStyles = makeStyles(() => ({
  textField: {
    width: "100%",
  },
  inputAdornment: {
    paddingRight: 0,
  },
  iconButton: {
    padding: "12px 0px 12px 12px",
  },
}));

interface DatePickerComponentProps {
  label?: string;
  labelLight?: string;
  required?: boolean;
  helperText?: string;
  value: Date;
  onChange: (_date: Date | null) => void;
}

const DatePickerComponentRef: React.ForwardRefRenderFunction<HTMLInputElement, DatePickerComponentProps> = (
  { label, labelLight, required, helperText, value, onChange },
  ref
) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleDateChange = (date: Date | null) => {
    onChange(date);
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={1}>
        {label && <CustomFormLabel label={label} required={required} helperText={helperText} />}
        {labelLight && (
          <CustomFormLabel label={label} labelLight={labelLight} required={required} helperText={helperText} />
        )}
      </Box>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
        <KeyboardDatePicker
          views={["year", "month"]}
          variant="dialog"
          openTo="month"
          value={value}
          onChange={handleDateChange}
          format="yyyy/MM"
          inputRef={ref}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          TextFieldComponent={(props) => (
            <TextField
              {...props}
              variant="outlined"
              className={classes.textField}
              onClick={() => setOpen(true)}
              InputProps={{
                endAdornment: (
                  <InputAdornment className={classes.inputAdornment} position="end">
                    <IconButton className={classes.iconButton} onClick={() => setOpen(true)}>
                      <CalendarTodayIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </MuiPickersUtilsProvider>
    </Box>
  );
};

const DatePickerComponent = React.forwardRef(DatePickerComponentRef);
export { DatePickerComponent };
