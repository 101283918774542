import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { useRecoilValue } from "recoil";

import { Card } from "../../scripts/components/card";
import { sendEventToAppMeasurementTool, isFirstAccess, AppEventTokenEnum } from "../../utilities/capacitor_logic";
import { UserState } from "../../view_models/atoms";

export const ReportTab: React.FC = () => {
  const user = useRecoilValue(UserState);

  const onClickReport = () => {
    sendEventToAppMeasurementTool(
      isFirstAccess(user?.created_at)
        ? AppEventTokenEnum.ReportFirstAccess
        : AppEventTokenEnum.ReportSecondOnwardsAccess
    );
    window.open("https://form.run/@propally-1619963249", "_blank");
  };
  return (
    <Card>
      <Box textAlign="center" my={7}>
        <Typography variant="h5" component="div">
          <Box mb={3} fontWeight="bold">
            不備報告・改善要望
          </Box>
        </Typography>
        <Box justifyContent="center" display="flex">
          <Button onClick={onClickReport} variant="contained" color="primary" size="medium">
            不備報告・改善要望
          </Button>
        </Box>
      </Box>
    </Card>
  );
};
