import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 342,
    margin: "auto",
    borderRadius: "4px 4px 0px 0px",
    borderStyle: "solid",
    borderWidth: "0px 0px 1px 0px",
    borderColor: theme.palette.divider,
    boxShadow: "none",
  },
  media: {
    height: 200,
  },
}));

interface PropertyCardProps {
  image: string;
  price: number;
  type: string;
  station: string;
  prefecture: string;
  address: string;
  year: number;
  structure: string;
  area: number;
  transactionPeriod: string;
}

export const PropertyCard: React.FC<PropertyCardProps> = ({
  image,
  price,
  type,
  station,
  prefecture,
  address,
  year,
  structure,
  area,
  transactionPeriod,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardMedia className={classes.media} image={image} title="Property Image" />
      <CardContent>
        <Box mb={2}>
          <Typography variant="caption" color="textSecondary">
            成約価格:
          </Typography>
          <Typography variant="h3">{price}万円</Typography>
        </Box>
        <Box display="flex" pb={1}>
          <Typography variant="caption" color="textSecondary" component="p">
            種類: {type}
          </Typography>
          <Typography variant="caption" color="textSecondary" component="p">
            最寄駅: {station}
          </Typography>
        </Box>
        <Box pb={1}>
          <Typography variant="caption" color="textSecondary" component="p">
            都道府県: {prefecture}
          </Typography>
        </Box>
        <Box pb={1}>
          <Typography variant="caption" color="textSecondary" component="p">
            所在地: {address}
          </Typography>
        </Box>
        <Box pb={1}>
          <Typography variant="caption" color="textSecondary" component="p">
            建築年: {year}年 構造: {structure} 面積: {area}㎡
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" color="textSecondary" component="p">
            取引時期: {transactionPeriod}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export const PropertyCardExample: React.FC = () => {
  return (
    <PropertyCard
      image="https://via.placeholder.com/345x180"
      price={31400000}
      type="宅地（土地と建物）"
      station="飯田橋 (徒歩4分)"
      prefecture="東京都"
      address="千代田区 飯田橋 (商業地)"
      year={2007}
      structure="RC"
      area={100}
      transactionPeriod="2023年第2四半期"
    />
  );
};
