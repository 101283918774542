import { makeStyles, Box } from "@material-ui/core";
import * as React from "react";

import { LatestHistoryContent } from "../../../../../../scripts/components/renewal_v1/property/edit_property_history";
import { PropertyOperationTemplate } from "../../../../../../scripts/components/renewal_v1/template/property_operation_template";
import {
  getFormatCost,
  getFormatStartMonth,
  getLatestMonthlyCashflowResult,
} from "../../../../../../utilities/renewal_v1/property_edit";
import { PropertyHolder } from "../../../../../../view_models/atoms";

import { EditPropertySectionManagementCostHistory, costType } from "./history";

const useStyles = makeStyles((theme) => ({
  content: {
    width: 640,
    [theme.breakpoints.down("sm")]: {
      width: 342,
    },
    margin: "16px auto 0px auto",
  },
}));

interface EditPropertySectionManagementCostProps {
  propertyHolder: PropertyHolder;
  onClickPageBack: () => void;
}

export const EditPropertySectionManagementCost: React.FC<EditPropertySectionManagementCostProps> = ({
  propertyHolder,
  onClickPageBack,
}) => {
  const classes = useStyles();
  // 過去履歴の表示・非表示
  const [isShowHistory, setIsShowHistory] = React.useState(false);
  // 過去履歴ページに渡す情報
  const [selectedCostType, setSelectedCostType] = React.useState<costType | null>(null);

  /*
   各種コストの取得
  */
  // management_cost を含む最新のMonthlyCashflowResultを取得
  const latestMonthlyCashflowResultManagementCost = React.useMemo(() => {
    return getLatestMonthlyCashflowResult(propertyHolder.user_input_monthly_cashflow_results, "management_cost");
  }, [propertyHolder.user_input_monthly_cashflow_results]);
  const managementCost = React.useMemo(
    () => getFormatCost(latestMonthlyCashflowResultManagementCost?.management_cost),
    [latestMonthlyCashflowResultManagementCost]
  );

  // building_management_cost を含む最新のMonthlyCashflowResultを取得
  const latestMonthlyCashflowResultBuildingManagementCost = React.useMemo(() => {
    return getLatestMonthlyCashflowResult(
      propertyHolder.user_input_monthly_cashflow_results,
      "building_management_cost"
    );
  }, [propertyHolder.user_input_monthly_cashflow_results]);
  const buildingManagementCost = React.useMemo(
    () => getFormatCost(latestMonthlyCashflowResultBuildingManagementCost?.building_management_cost),
    [latestMonthlyCashflowResultBuildingManagementCost]
  );

  // repair_fund_cost を含む最新のMonthlyCashflowResultを取得
  const latestMonthlyCashflowResultRepairFundCost = React.useMemo(() => {
    return getLatestMonthlyCashflowResult(propertyHolder.user_input_monthly_cashflow_results, "repair_fund_cost");
  }, [propertyHolder.user_input_monthly_cashflow_results]);
  const repairFundCost = React.useMemo(
    () => getFormatCost(latestMonthlyCashflowResultRepairFundCost?.repair_fund_cost),
    [latestMonthlyCashflowResultRepairFundCost]
  );

  // misc_cost を含む最新のMonthlyCashflowResultを取得
  const latestMonthlyCashflowResultMiscCost = React.useMemo(() => {
    return getLatestMonthlyCashflowResult(propertyHolder.user_input_monthly_cashflow_results, "misc_cost");
  }, [propertyHolder.user_input_monthly_cashflow_results]);
  const miscCost = React.useMemo(
    () => getFormatCost(latestMonthlyCashflowResultMiscCost?.misc_cost),
    [latestMonthlyCashflowResultMiscCost]
  );

  const onClickPageBackFromHistory = () => {
    setIsShowHistory(false);
  };
  if (isShowHistory) {
    return (
      <EditPropertySectionManagementCostHistory
        costType={selectedCostType}
        onClickPageBack={onClickPageBackFromHistory}
      />
    );
  }

  const handleClickShowMoreHistory = (costType: costType) => {
    setSelectedCostType(costType);
    setIsShowHistory(true);
  };

  return (
    <PropertyOperationTemplate title="賃貸管理費" onBack={onClickPageBack}>
      <Box>
        <Box className={classes.content}>
          {/* 賃貸管理費 */}
          <LatestHistoryContent
            title="賃貸管理費"
            contents={[
              { label: "支払開始月", value: getFormatStartMonth(latestMonthlyCashflowResultManagementCost) },
              { label: "金額", value: managementCost },
            ]}
            onClickShowMoreHistory={() => handleClickShowMoreHistory("賃貸管理費")}
          />

          {/* 建物管理費 */}
          <LatestHistoryContent
            title="建物管理費"
            contents={[
              { label: "支払開始月", value: getFormatStartMonth(latestMonthlyCashflowResultBuildingManagementCost) },
              { label: "金額", value: buildingManagementCost },
            ]}
            onClickShowMoreHistory={() => handleClickShowMoreHistory("建物管理費")}
          />

          {/* 修繕積立金 */}
          <LatestHistoryContent
            title="修繕積立金"
            contents={[
              { label: "支払開始月", value: getFormatStartMonth(latestMonthlyCashflowResultRepairFundCost) },
              { label: "金額", value: repairFundCost },
            ]}
            onClickShowMoreHistory={() => handleClickShowMoreHistory("修繕積立金")}
          />

          {/* その他経費 */}
          <LatestHistoryContent
            title="その他経費"
            contents={[
              { label: "支払開始月", value: getFormatStartMonth(latestMonthlyCashflowResultMiscCost) },
              { label: "金額", value: miscCost },
            ]}
            onClickShowMoreHistory={() => handleClickShowMoreHistory("その他経費")}
          />
        </Box>
      </Box>
    </PropertyOperationTemplate>
  );
};
