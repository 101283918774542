import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React = require("react");
import { useForm } from "react-hook-form";

import { PropallyApiFactory } from "../module/custom_api_factory";
import { EntranceTemplate } from "../scripts/components/templates/entrance_template";

const postNewPasswords = async (data: { email: string }) => {
  const fp = PropallyApiFactory();
  try {
    await fp.v1PasswordResetsPost(data);
    return { success: true, error: "" };
  } catch (e) {
    if (e.response?.status === 404) {
      return { success: false, error: "アカウントが見つかりません" };
    }
    return {
      success: false,
      error: "ネットワークエラーです。しばらくたってからお試しください",
    };
  }
};

export const NewPasswordReset: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState<string>("");
  const { register, handleSubmit, errors } = useForm();
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const onClickNewPasswords = async (data) => {
    const result = await postNewPasswords(data);
    if (result.success) {
      setOpen(true);
    }
    setError(result.error);
  };

  return (
    <EntranceTemplate title="パスワードリセット" isFooter>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        message="ご記入のメールアドレスにパスワードリセットのリンクを送信しました。メールが届かない場合は、迷惑メールボックスもご確認ください。それでもメールが見つからない場合は、メールアドレスに誤りがあり送付が出来ていない可能性がございますので、もう一度パスワードリセットをお試しください。"
      />
      <form onSubmit={handleSubmit(onClickNewPasswords)}>
        <Box mb={2} textAlign="left" mx={2}>
          <Typography variant="body2" color="textSecondary">
            ご登録のメールアドレスにパスワードリセットのリンクをお送りします。
          </Typography>
        </Box>
        <Box mb={2} textAlign="left" mx={2}>
          <Typography variant="caption" color="textSecondary">
            メールアドレス
          </Typography>
        </Box>
        <Box m={2}>
          <TextField
            placeholder="sample@propally.jp"
            name="email"
            variant="outlined"
            size="small"
            fullWidth
            inputRef={register({
              required: true,
              maxLength: {
                value: 80,
                message: "80文字以内で入力してください",
              },
              pattern: {
                value: emailRegex,
                message: "形式が正しくありません",
              },
            })}
            error={Boolean(errors.email)}
            helperText={Boolean(errors.email) && errors.email.message}
          />
        </Box>
        {Boolean(error) && (
          <Box color="secondary.main" mb={3}>
            {error}
          </Box>
        )}
        <Box mb={4} mx={2}>
          <Button type="submit" variant="contained" size="large" color="primary" fullWidth>
            メール送信
          </Button>
        </Box>
      </form>
      <Box mb={1}>
        <Link variant="caption" href="/login">
          ログイン
        </Link>
        <Typography variant="caption">に戻る</Typography>
      </Box>
      <Box>
        <Typography variant="caption">何か問題が発生している場合は</Typography>
        <br />
        <Link variant="caption" href="https://form.run/@propally-1619963249" target="_blank" rel="noopener">
          こちら
        </Link>
        <Typography variant="caption">からご報告ください</Typography>
      </Box>
    </EntranceTemplate>
  );
};
