import { Snackbar, SnackbarContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  success: {
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
  },
  error: {
    border: `2px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.light,
  },
  message: {
    display: "flex",
    alignItems: "center",
    fontSize: theme.typography.button.fontSize,
    fontWeight: theme.typography.button.fontWeight,
  },
  root: {
    borderRadius: "8px",
    padding: theme.spacing(1.5),
    boxShadow: theme.shadows[3],
  },
}));

interface CustomToastProps {
  open: boolean;
  variant: string;
  message: string;
  onClose: () => void;
}

export const Toast: React.FC<CustomToastProps> = ({ open, variant, message, onClose }) => {
  const classes = useStyles();

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
      <SnackbarContent
        className={clsx(classes[variant], classes.root)}
        message={<span className={classes.message}>{message}</span>}
      />
    </Snackbar>
  );
};

// サンプルコンポーネントでトーストを使用する
export const ToastExample: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [variant, setVariant] = React.useState("success");
  const [message, setMessage] = React.useState("");

  const handleClick = (variant, message) => {
    setVariant(variant);
    setMessage(message);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <button onClick={() => handleClick("success", "設定を適用しました")}>Show Success Toast</button>
      <button onClick={() => handleClick("error", "設定を適用できませんでした")}>Show Error Toast</button>
      <Toast open={open} variant={variant} message={message} onClose={handleClose} />
    </div>
  );
};
