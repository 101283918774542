import { makeStyles, Box } from "@material-ui/core";
import dayjs from "dayjs";
import * as React from "react";
import { useHistory } from "react-router";
import { useRecoilCallback, useRecoilValue } from "recoil";

import { CustomButton } from "../../../../scripts/components/renewal_v1/button";
import { PropertyEditPageState } from "../../../../view_models/atoms";
import { SelectedProperty } from "../../../../view_models/property_selectors";

import { AccordionDetailItemRow } from "./accordion_detail_item_row";

const useStyles = makeStyles((theme) => ({
  background: {
    width: "100%",
    maxWidth: 640,
    margin: "12px auto 72px auto",
    [theme.breakpoints.down("sm")]: {
      padding: "0 24px",
    },
  },
}));

export const ManagementConsiderationDetailPropertyInfo: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const property = useRecoilValue(SelectedProperty);

  // useRecoilCallbackを使用して非同期の状態更新を行う
  const updatePropertyEditPageState = useRecoilCallback(({ snapshot, set }) => async (newState) => {
    set(PropertyEditPageState, newState);
    // 状態が更新されるのを待つ
    await snapshot.getPromise(PropertyEditPageState);
  });

  // 物件詳細情報編集ボタンクリック時
  const onClickEditProrpertyInfo = async () => {
    await updatePropertyEditPageState("property_info");
    history.push("/edit_property");
  };

  return (
    <>
      <Box className={classes.background}>
        {/* 物件情報 */}
        {property.property.name && (
          <AccordionDetailItemRow title="マンション・アパート名" value={property.property.name} />
        )}
        {property.property.room_number && <AccordionDetailItemRow title="号室" value={property.property.room_number} />}
        {property.property.floor && <AccordionDetailItemRow title="所在階" value={`${property.property.floor}階`} />}
        {property.property.prefecture && (
          <AccordionDetailItemRow title="都道府県" value={property.property.prefecture} />
        )}
        {property.property.city && <AccordionDetailItemRow title="市区町村" value={property.property.city} />}
        {property.property.town && <AccordionDetailItemRow title="番地" value={property.property.town} />}
        {property.property.railway && <AccordionDetailItemRow title="路線名" value={property.property.railway} />}
        {property.property.station && <AccordionDetailItemRow title="駅名" value={property.property.station} />}
        {property.property.walk && <AccordionDetailItemRow title="駅徒歩" value={`${property.property.walk}分`} />}
        {property.property.built_at && (
          <AccordionDetailItemRow
            title="築年"
            value={`${dayjs().year() - dayjs(property.property.built_at).year()}年`}
          />
        )}
        {property.property.occupied_area_m2 && (
          <AccordionDetailItemRow title="占有面積" value={`${property.property.occupied_area_m2}㎡`} />
        )}
        {property.property.layout && <AccordionDetailItemRow title="間取り" value={property.property.layout} />}
        {property.property.rental_management_company && (
          <AccordionDetailItemRow title="管理形態" value={property.property.rental_management_company} />
        )}
        {property.property.structure && <AccordionDetailItemRow title="構造" value={property.property.structure} />}

        <Box mb={2} justifyContent="center">
          <CustomButton customVariant="outlinedGray" isEdit onClick={onClickEditProrpertyInfo}>
            編集する
          </CustomButton>
        </Box>
      </Box>
    </>
  );
};
