import { Box, Grid, Typography, debounce, makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Autocomplete, AutocompleteInputChangeReason, createFilterOptions } from "@material-ui/lab";
import dayjs from "dayjs";
import * as React from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useRecoilState } from "recoil";

import { HowMaBuilding, HowMaRoom, IdName } from "../../../../../api/generated";
import { GetCities, GetPrefectures, GetRailways, GetStations, GetTowns } from "../../../../../api/get_address";
import {
  convertAreaNameToCity,
  convertAreaNameToPrefecture,
  convertAreaNameToTown,
  convertFloorPlanToLayout,
} from "../../../../../logics/howma/HowMaBuildingSearchApiLogic";
import { PropallyApiFactory } from "../../../../../module/custom_api_factory";
import { CustomButton } from "../../../../../scripts/components/renewal_v1/button";
import { CustomAutoCompleteForm, CustomForm } from "../../../../../scripts/components/renewal_v1/form";
import { CustomSelector } from "../../../../../scripts/components/renewal_v1/selecter";
import { Toast } from "../../../../../scripts/components/renewal_v1/toast";
import { AppEventTokenEnum, sendEventToAppMeasurementTool } from "../../../../../utilities/capacitor_logic";
import { ConsiderationPropertyRegistrationState } from "../../../../../view_models/atoms";
import { Layout } from "../const";

const useStyles = makeStyles((theme) => ({
  content: {
    width: 640,
    margin: "32px auto 32px auto",
    [theme.breakpoints.down("sm")]: {
      width: 342,
      margin: "16px auto 0px auto",
    },
  },
}));

interface AddNewConsiderationPropertyBasicPageProps {
  onNext: () => void;
  validate: () => Promise<boolean>;
}

const AddNewConsiderationPropertyBasicPage: React.FC<AddNewConsiderationPropertyBasicPageProps> = ({
  onNext,
  validate,
}) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  // デフォルト値
  const [formData] = useRecoilState(ConsiderationPropertyRegistrationState);
  /**
   * State
   */
  const [prefectures, setPrefectures] = React.useState<Array<string>>([""]);
  const [cities, setCities] = React.useState<Array<string>>([formData.city ? formData.city : ""]);
  const [towns, setTowns] = React.useState<Array<string>>([""]);
  const [railways, setRailways] = React.useState<Array<IdName>>([]);

  /*
  マンション検索関連 state
  */
  const [buildingOptions, setBuildingOptions] = React.useState<Array<HowMaBuilding>>([]);
  const [roomOptions, setRoomOptions] = React.useState<Array<HowMaRoom>>([]);
  const [selectedHowMaBuilding, setSelectedHowMaBuilding] = React.useState<HowMaBuilding>(null);
  const [selectedHowMaRoom, setSelectedHowMaRoom] = React.useState<HowMaRoom>(null);
  const [isBuildingsSearching, setIsBuildingsSearching] = React.useState<boolean>(false);

  // AutoCompleteコンポーネントでWarningを出さずに空文字を許容するために使用
  const defaultFilterOptions = createFilterOptions();
  // サーバーエラー
  const [serverError, setSeverError] = React.useState<string>("");

  const watchValues = useWatch({
    name: ["prefecture", "city", "town", "railway", "property_type"],
    control: control,
  });

  /**
   * useEffect
   */
  React.useEffect(() => {
    if (formData.name) {
      setValue("name", formData.name);
      setValue("prefecture", formData.prefecture);
      setValue("city", formData.city);
      setValue("town", formData.town);
      setValue("railway", formData.railway);
      setValue("built_at", formData.built_at);
      setValue("room_number", formData.room_number);
      setValue("floor", formData.floor);
      setValue("occupied_area_m2", formData.occupied_area_m2);
      setValue("layout", formData.layout);
    }
  }, []);

  /** Load prefectures and railways at once */
  React.useEffect(() => {
    let isMounted = true;
    (async () => {
      {
        const r = await GetPrefectures();
        if (isMounted) {
          if (!r.success) {
            return setSeverError(r.error);
          }
          setPrefectures(r.prefectures);
        }
      }

      {
        const r = await GetRailways();
        if (isMounted) {
          if (!r.success) {
            return setSeverError(r.error);
          }

          setRailways(r.railways);
        }
      }
      return undefined;
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  /**
   * マンション名候補から選択した場合の自動入力処理
   * ※市区町村, 番地, 駅名の自動入力はAutocompleteの選択肢がセットされてから実施するため、それぞれの各マスタuseEffect箇所でそれぞれ実施
   */
  React.useEffect(() => {
    if (!selectedHowMaBuilding) return;
    setValue("name", selectedHowMaBuilding.name);
    setValue("prefecture", convertAreaNameToPrefecture(selectedHowMaBuilding.area.name, prefectures));
    setValue("railway", railways.find((v) => v.id === selectedHowMaBuilding.station?.line_cd)?.name ?? "");
    setValue("built_at", selectedHowMaBuilding.built_at_year.toString());
  }, [selectedHowMaBuilding]);

  /** 号室候補から選択した場合の自動入力処理 */
  React.useEffect(() => {
    if (!selectedHowMaRoom) return;
    setValue("room_number", selectedHowMaRoom.name);
    setValue("floor", selectedHowMaRoom.floor_number);
    setValue("occupied_area_m2", selectedHowMaRoom.m2);
    setValue("layout", convertFloorPlanToLayout(selectedHowMaRoom.floor_plan));
  }, [selectedHowMaRoom]);

  /** Load cities for each time prefecture is selected */
  React.useEffect(() => {
    if (!watchValues.prefecture) return;
    if (watchValues.prefecture === "") {
      setValue("city", "");
      setValue("town", "");
      setCities([""]);
      setTowns([""]);
      return;
    }

    let isMounted = true;
    (async () => {
      const r = await GetCities(watchValues.prefecture);
      if (isMounted) {
        if (!r.success) {
          return setSeverError(r.error);
        }

        setCities(r.cities);
        if (selectedHowMaBuilding) {
          // マンション名候補から選択時
          setValue("city", convertAreaNameToCity(selectedHowMaBuilding.area.name, r.cities));
        }
      }
      return undefined;
    })();
    // eslint-disable-next-line consistent-return
    return () => {
      isMounted = false;
    };
  }, [watchValues.prefecture]);

  /** Load towns for each time city is selected */
  React.useEffect(() => {
    if (!watchValues.city) return undefined;
    if (watchValues.city === "") return undefined;
    let isMounted = true;

    (async () => {
      const r = await GetTowns(watchValues.prefecture, watchValues.city);
      if (isMounted) {
        if (!r.success) {
          return setSeverError(r.error);
        }
        setTowns(r.towns);
        if (selectedHowMaBuilding) {
          // マンション名候補から選択時
          setValue("town", convertAreaNameToTown(selectedHowMaBuilding.area.name, r.towns));
        }
      }

      return undefined;
    })();

    return () => {
      isMounted = false;
    };
  }, [watchValues.city]);

  /** Load stations for each time railway is selected */
  React.useEffect(() => {
    if (!watchValues.railway) return undefined;
    if (watchValues.railway === "") return undefined;
    let isMounted = true;

    (async () => {
      const r = await GetStations(watchValues.railway);
      if (isMounted) {
        if (!r.success) {
          return setSeverError(r.error);
        }
        if (selectedHowMaBuilding) {
          // マンション名候補から選択時
          setValue("station", selectedHowMaBuilding.station?.name);
        }
      }
      return undefined;
    })();
    return () => {
      isMounted = false;
    };
  }, [watchValues.railway]);

  const handleNext = async () => {
    console.log("handleNext");
    const isValid = await validate();
    if (isValid) {
      sendEventToAppMeasurementTool(AppEventTokenEnum.ConsiderationPropertyBasicInfoCompleted);
      onNext();
    }
  };

  const handleClose = React.useCallback(() => {
    setSeverError("");
  }, []);

  /**
   * 関数定義
   */
  const fp = PropallyApiFactory();
  // debounce関数
  const buildingsSearch = (searchBuildingName: string) => {
    setValue("name", searchBuildingName);
    // 入力し直しの場合は選択肢は空にする
    setBuildingOptions([]);
    setRoomOptions([]);

    if (searchBuildingName.length < 2) return;

    setIsBuildingsSearching(true);
    debounceBuildingsSearch(searchBuildingName);
  }

  const debounceBuildingsSearch = debounce(
    (searchBuildingName: string) => {
      fetchBuildingOptions(searchBuildingName);
    },
    1000 // 1秒
  );

  // 建物情報取得
  const fetchBuildingOptions = (searchBuildingName: string) => {
    fp.v1HowmaBuildingsPost({ text: searchBuildingName }, { withCredentials: true })
      .then((res) => {
        setBuildingOptions(res.data.buildings);
        setIsBuildingsSearching(false);
      })
      .catch(() => {
        setSeverError("物件検索に失敗しました。再度お試し頂くか、手動で入力してください。");
        setIsBuildingsSearching(false);
      });
  };
  // 部屋情報取得
  const fetchRoomOptions = (buildingId: number) => {
    fp.v1HowmaBuildingsBuildingIdGet(buildingId, { withCredentials: true })
      .then((res) => {
        setRoomOptions(res.data.rooms);
      })
      .catch(() => {
        // enqueueSnackbar("物件検索に失敗しました。再度お試し頂くか、手動で入力してください。");
        console.log("部屋情報取得に失敗しました。");
        setSeverError("物件検索に失敗しました。再度お試し頂くか、手動で入力してください。");
      });
  };

  // 築年の選択肢
  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(90), (val, index) => currentYear - 70 + index); // Array of years from current year - 70 to current year + 20
  const builtAtYearOptions = years.map((year) => ({ value: year.toString(), label: year.toString() }));

  return (
    <>
      <Box>
        <Box className={classes.content}>
          {isBuildingsSearching &&
            <Box>
              <Typography variant="subtitle2" color="inherit">
                マンション検索中...<CircularProgress style={{ marginLeft: "4px" }} size={14} />
              </Typography>
            </Box>
          }
          <Controller
            name="name"
            control={control}
            rules={{ required: "入力してください" }}
            defaultValue={formData?.name || ""}
            render={({ ...field }) => (
              <Autocomplete
                size="small"
                freeSolo
                disableClearable
                options={buildingOptions}
                // ref={field.ref}
                value={field.value}
                // MEMO: getOptionLabelのoptionにはoptionsでvalueを含めなくても直接入力されたstring型のvalueも含まれるためstring型の考慮をしている
                getOptionLabel={(option: HowMaBuilding | string) => {
                  return typeof option === "string" ? option : `${option.name}`;
                }}
                // MEMO: renderOptionのoptionには直接入力されたstring型のvalueは入ってこないためstring型の考慮不要
                renderOption={(option: HowMaBuilding) => (
                  <Box>
                    <Typography variant="body2" component="div">
                      {option.name}
                    </Typography>
                    <Typography variant="caption">({option.area.name})</Typography>
                  </Box>
                )}
                renderInput={(params) => {
                  return (
                    <CustomAutoCompleteForm
                      params={params}
                      label="マンション・アパート名"
                      helperText="マンション・アパート名から物件情報を自動入力する"
                      required
                      ref={field.ref}
                      error={!!errors.name}
                      errorText={errors.name?.message}
                    />
                  );
                }}
                // MEMO: オートコンプリートの選択肢の中から選択した場合の処理
                onChange={(e, selected: HowMaBuilding) => {
                  // 手動入力のEnter確定時にもonChangeが発火してしまい、その場合には文字列が入ってきてしまうため、
                  // 選択肢の中からの選択ではない文字列(=string)の場合は後続処理スキップ
                  if (typeof selected === "string") return;

                  // マンション情報自動入力
                  setSelectedHowMaBuilding(selected);

                  // 部屋詳細APIを実行して部屋情報取得
                  fetchRoomOptions(selected.id);
                }}
                // MEMO: 手動入力の場合の処理
                // onInputChangeのdataに渡される値は、オートコンプリート選択肢から選択された場合も、選択されたoptionにあたるgetOptionLabelの値が利用されるため、Building型は入らずstring型のみ
                onInputChange={(e: React.ChangeEvent, inputed: string, reason: AutocompleteInputChangeReason) => {
                  // 候補選択時でもinputが発火してしまうため、手動入力以外は検索処理を実行させない
                  if (reason !== "input") return;
                  // 検索実行
                  buildingsSearch(inputed);
                }}
                // MEMO: inputエリアフォーカス時に実行したい処理
                onFocus={() => {}}
              />
            )}
          />

          {(formData?.room_number || selectedHowMaBuilding) && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="room_number"
                  defaultValue={formData?.room_number || ""}
                  render={({ ...field }) => (
                    <Autocomplete
                      id="room_number"
                      size="small"
                      freeSolo
                      disableClearable
                      value={field.value}
                      options={roomOptions}
                      // MEMO: getOptionLabelのoptionにはoptionsでvalueを含めなくても直接入力されたstring型のvalueも含まれるためstring型の考慮をしている
                      getOptionLabel={(option: HowMaRoom | string) => {
                        return typeof option === "string" ? option : `${option.name}`;
                      }}
                      // MEMO: renderOptionのoptionには直接入力されたstring型のvalueは入ってこないためstring型の考慮不要
                      renderOption={(option: HowMaRoom) => (
                        <Box>
                          <Typography variant="body2" component="div">
                            {option.name}
                            <Typography variant="caption">号室</Typography>
                          </Typography>
                        </Box>
                      )}
                      renderInput={(params) => {
                        return (
                          <CustomAutoCompleteForm
                            params={params}
                            label="号室"
                            ref={field.ref}
                            error={!!errors.room_number}
                            errorText={errors.room_number?.message}
                          />
                        );
                      }}
                      // 選択肢選択時
                      onChange={(e, selected: HowMaRoom) => {
                        // 自動入力
                        setSelectedHowMaRoom(selected);
                      }}
                      // 手動入力時
                      onInputChange={(e: React.ChangeEvent, inputed: string) => {
                        setValue("room_number", inputed);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="floor"
                  control={control}
                  defaultValue={formData.floor || ""}
                  render={({ ...field }) => (
                    <>
                      <CustomForm
                        {...field}
                        label="所在階"
                        error={!!errors.floor}
                        errorText={errors.floor?.message}
                        onChange={field.onChange}
                        placeHolder="1"
                        unitSuffix="階"
                      />
                      <Typography variant="caption">
                        ※一棟の場合は「0」と入力
                      </Typography>
                    </>
                  )}
                />
              </Grid>
            </Grid>
          )}
          {/* 都道府県 */}
          {(formData?.prefecture || selectedHowMaBuilding) && (
            <Controller
              control={control}
              name="prefecture"
              defaultValue={formData.prefecture || ""}
              render={({ ...field }) => (
                <Autocomplete
                  id="prefecture"
                  options={!field.value || field.value === "" ? [field.value, ...prefectures] : prefectures}
                  value={field.value || ""}
                  size="small"
                  filterOptions={(options, state) => {
                    const defaultFiltered = defaultFilterOptions(options, state);
                    return defaultFiltered.filter((option) => option !== "" && option !== null);
                  }}
                  getOptionLabel={(option) => {
                    return !option ? "" : option;
                  }}
                  renderOption={(params) => <>{params}</>}
                  renderInput={(params) => {
                    return (
                      <CustomAutoCompleteForm
                        params={params}
                        label="都道府県"
                        ref={field.ref}
                        error={!!errors.prefecture}
                        errorText={errors.prefecture?.message}
                      />
                    );
                  }}
                  onChange={(e, data) => {
                    field.onChange(data);
                    setValue("city", "");
                    setValue("town", "");
                  }}
                />
              )}
            />
          )}
          {/* 市区町村 */}
          {cities.length > 1 && (
            <Controller
              name="city"
              control={control}
              defaultValue={formData.city || ""}
              render={({ ...field }) => (
                <Autocomplete
                  id="city"
                  options={!field.value || field.value === "" ? [field.value, ...cities] : cities}
                  value={field.value}
                  size="small"
                  filterOptions={(options, state) => {
                    const defaultFiltered = defaultFilterOptions(options, state);
                    return defaultFiltered.filter((option) => option !== "" && option !== null);
                  }}
                  getOptionLabel={(option) => {
                    return !option ? "" : option;
                  }}
                  getOptionSelected={(option, value) => option === value}
                  renderOption={(params) => <>{params}</>}
                  renderInput={(params) => {
                    return (
                      <CustomAutoCompleteForm
                        params={params}
                        label="市区町村"
                        ref={field.ref}
                        error={!!errors.city}
                        errorText={errors.city?.message}
                      />
                    );
                  }}
                  onChange={(e, data) => {
                    field.onChange(data);
                    setValue("town", "");
                  }}
                  disabled={!watchValues.prefecture || watchValues.prefecture === ""}
                />
              )}
            />
          )}
          {/* 番地 */}
          {towns.length > 1 && (
            <Controller
              control={control}
              name="town"
              error={!!errors.town}
              helperText={errors.town?.message}
              defaultValue={formData.town || ""}
              render={({ ...field }) => (
                <Autocomplete
                  id="town"
                  // options={!field.value || field.value === "" ? [field.value, ...towns] : towns}
                  options={!field.value || field.value === "" ? [field.value, ...towns] : towns}
                  value={field.value}
                  size="small"
                  filterOptions={(options, state) => {
                    const defaultFiltered = defaultFilterOptions(options, state);
                    return defaultFiltered.filter((option) => option !== "" && option !== null);
                  }}
                  getOptionLabel={(option) => {
                    return !option ? "" : option;
                  }}
                  renderOption={(params) => <>{params}</>}
                  renderInput={(params) => {
                    return (
                      <CustomAutoCompleteForm
                        params={params}
                        label="番地"
                        ref={field.ref}
                        error={!!errors.town}
                        errorText={errors.town?.message}
                      />
                    );
                  }}
                  onChange={(e, data) => {
                    field.onChange(data);
                  }}
                  disabled={!watchValues.city || watchValues.city === ""}
                />
              )}
            />
          )}
          {/* 築年 */}
          {(formData?.built_at || selectedHowMaBuilding) && (
            <Controller
              name="built_at"
              control={control}
              defaultValue={
                formData.built_at ? dayjs(formData.built_at).format("YYYY") : dayjs(new Date().getDate()).format("YYYY")
              }
              render={({ ...field }) => (
                <CustomSelector
                  {...field}
                  label="築年"
                  errorText={errors.built_at?.message}
                  onChange={field.onChange}
                  options={builtAtYearOptions}
                  placeHolder={`${currentYear}`}
                  suffix="年"
                />
              )}
            />
          )}
          {/* 専有面積 */}
          {(formData?.occupied_area_m2 || selectedHowMaRoom) && (
            <Controller
              name="occupied_area_m2"
              control={control}
              defaultValue={formData.occupied_area_m2 || ""}
              render={({ ...field }) => (
                <>
                  <CustomForm
                    {...field}
                    label="専有面積"
                    error={!!errors.occupied_area_m2}
                    errorText={errors.occupied_area_m2?.message}
                    onChange={field.onChange}
                    placeHolder="32"
                    unitSuffix="m²"
                    isShort
                  />
                  <Typography variant="caption">
                    ※一棟の場合は「0」と入力
                  </Typography>
                </>
              )}
            />
          )}
          {/* 間取り */}
          {(formData?.layout || selectedHowMaRoom) && (
            <Controller
              name="layout"
              control={control}
              defaultValue={formData.layout || ""}
              render={({ ...field }) => (
                <CustomSelector
                  {...field}
                  label="間取り"
                  onChange={field.onChange}
                  error={!!errors.layout}
                  errorText={errors.layout?.message}
                  options={Layout.map((layout) => ({ label: layout, value: layout }))}
                  placeHolder="未選択"
                />
              )}
            />
          )}
        </Box>
        <Box mt={5}>
          <CustomButton type="button" customVariant="filled" onClick={handleNext}>
            次へ
          </CustomButton>
        </Box>
      </Box>
      <Toast open={!!serverError} variant="error" message={serverError} onClose={handleClose} />
    </>
  );
};

export default AddNewConsiderationPropertyBasicPage;
