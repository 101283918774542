import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import dayjs = require("dayjs");
import { useSnackbar } from "notistack";
import * as React from "react";
import { useState, useEffect } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useRecoilStateLoadable, useSetRecoilState } from "recoil";

import { RegisteredStatusEnum } from "../../api/generated";
import { JobCategory } from "../../api/generated/api";
import { GetMasterPurposeOfUses, GetMasterResidenceTypes, GetMasterTags } from "../../api/get_master_data";
import { WAY_TO_PROPOSE_VALUES } from "../../api/mappings/account";
import { PREFECTURES, OCCUPATIONS, JOB_INDUSTRIES } from "../../api/options/account";
import { PropallyApiFactory } from "../../module/custom_api_factory";
import { Card } from "../../scripts/components/card";
import { AccountRegisteredModal } from "../../scripts/components/second/modal/AccountRegisteredModal";
import { Select } from "../../scripts/components/styled_components";
import { AppEventTokenEnum, sendEventToAppMeasurementTool } from "../../utilities/capacitor_logic";
import { CircularProgressWithLabel } from "../../utilities/circular_progress_with_label";
import { Label } from "../../utilities/form/label";
import { UserDataFirstRegisteredStatusState, UserState } from "../../view_models/atoms";
import { Loading } from "../loading";

const loan_ranges = [
  "なし",
  "〜500万円",
  "500〜1,000万円",
  "1,000〜2,000万円",
  "2,000〜3,000万円",
  "3,000〜5,000万円",
  "5,000万円〜1億円",
  "1〜2億円",
  "2〜3億円",
  "3〜5億円",
  "5〜10億円",
  "10億円〜",
];

const period_ranges = ["6ヶ月未満", "6ヶ月以上1年未満", "1年以上3年未満", "3年以上"];

// 入力率計算から除外させたい項目のkeyを格納する
const KEYS_EXCLUDED_FROM_INPUT_RATE: string[] = [
  "home_loan_amount",
  "monthly_rent_payment_amount",
  "other_request_text",
];

export const BasicInfo: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [serverError, setSeverError] = useState<boolean>(false);
  const [inputCompletedRate, setInputCompletedRate] = useState<number>(0);
  const [masterResidenceTypes, setMasterResidenceTypes] = useState<Array<any>>([]);
  const [masterTags, setMasterTags] = useState<Array<any>>([]);
  const [masterPurposeOfUses, setMasterPurposeOfUses] = React.useState<Array<any>>([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const history = useHistory();
  // 現在のお住まい入力内容により表示フォームを変更するために必要な判定用変数
  const [selectedCurrentResidenceTypeId, setSelectedCurrentResidenceTypeId] = React.useState<number>(0);
  // 現在のお住まい入力内容により表示フォームを変更するために必要な判定用変数
  const [inputJobCategory, setInputJobCategory] = React.useState<JobCategory>(JobCategory.入力なし);
  // 電話番号の内容が既に登録された情報と違う場合は認証コードフォームを表示するために、入力された電話番号を監視して保持するStateを準備
  const [inputPhoneNumber, setInputPhoneNumber] = React.useState<string>("");

  const handleClickConfirmationModal = () => {
    setIsConfirmModalOpen(false);
    history.push("/first_add_new_property");
  };

  const handleCheckInterestTag = (checkedId) => {
    const { interest_tag_ids: selected_ids } = getValues();
    return selected_ids?.includes(checkedId)
      ? selected_ids?.filter((id) => id !== checkedId)
      : [...(selected_ids ?? []), checkedId];
  };

  const handlePurposeOfUseId = (checkedId) => {
    const { purpose_of_use_ids: selected_ids } = getValues();
    return selected_ids?.includes(checkedId)
      ? selected_ids?.filter((id) => id !== checkedId)
      : [...(selected_ids ?? []), checkedId];
  };

  const { register, handleSubmit, errors, control, getValues } = useForm({});

  const watchValues = useWatch({
    name: ["current_residence_type_id", "job_category", "phone_number"],
    control: control,
  });

  // 現在のお住まい入力内容により表示フォームを変更するためのuseEffect
  useEffect(() => {
    setSelectedCurrentResidenceTypeId(watchValues.current_residence_type_id);
  }, [watchValues.current_residence_type_id]);

  // 職業入力内容により業界フォームの表示・非表示を変更するためのuseEffect
  useEffect(() => {
    setInputJobCategory(watchValues.job_category);
  }, [watchValues.job_category]);

  // 電話番号入力内容変更時にStateに保持している電話番号も更新するためのuseEffect
  useEffect(() => {
    setInputPhoneNumber(watchValues.phone_number);
  }, [watchValues.phone_number]);

  const [loadable, setUser] = useRecoilStateLoadable(UserState);
  const setUserDataFirstRegisteredStatus = useSetRecoilState(UserDataFirstRegisteredStatusState);

  const updateSettingRate = async () => {
    const form = getValues();
    const targetForm = Object.keys(form).filter((key) => !KEYS_EXCLUDED_FROM_INPUT_RATE.includes(key));
    const count = targetForm.filter((key) => String(form[key])?.length && form[key] !== "未選択").length;
    setInputCompletedRate(Math.floor((count / targetForm.length) * 100));
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      {
        const r = await GetMasterResidenceTypes();
        if (isMounted) {
          if (!r.success) {
            return enqueueSnackbar(r.error);
          }
          setMasterResidenceTypes(r.masterResidenceTypes);
        }
      }

      {
        const r = await GetMasterTags();
        if (isMounted) {
          if (!r.success) {
            return enqueueSnackbar(r.error);
          }
          setMasterTags(r.masterTags);
        }
      }

      {
        const r = await GetMasterPurposeOfUses();
        if (isMounted) {
          if (!r.success) {
            return enqueueSnackbar(r.error);
          }
          setMasterPurposeOfUses(r.purposeOfUses);
        }
      }

      return undefined;
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (loadable.state === "hasValue" && loadable.contents !== null) {
      updateSettingRate();
    }
  }, [loadable.contents]);

  if (loadable.state === "hasError") {
    throw loadable.contents;
  } else if (loadable.state === "loading") {
    return <Loading />;
  }

  if (loadable.contents == null) {
    return <Loading />;
  }
  const user = loadable.contents;

  const today = new Date();
  const years = [...Array(100)].map((_, i) => i + today.getFullYear() - 99);
  const months = [...Array(12)].map((_, i) => i + 1);

  const sendPhoneNumberAuthCode = async () => {
    const fp = PropallyApiFactory();

    try {
      const sendResponse = await fp.v1SendPhoneNumberAuthCodesPost(
        { phone_number: inputPhoneNumber },
        {
          withCredentials: true,
        }
      );
      if (sendResponse.status === 200) {
        setMessage("電話番号認証コード送信に成功しました。");
      }
    } catch (e) {
      setMessage("電話番号認証コード送信に失敗しました。");
    }
  };

  const onSubmit = async (data) => {
    const sendValues = {
      ...data,
      email: user.email,
      birthday: `${data.birth_year}-${data.birth_month}-01`,
      // MEMO: 以下「way_to_propose」「request_the_proposal」は現状、非表示項目となるので、固定値を送信する
      way_to_propose: WAY_TO_PROPOSE_VALUES.email,
      request_the_proposal: true, // data.request_the_proposal === 1,
    };
    setDisabled(true);
    const fp = PropallyApiFactory();
    try {
      const userPatch = await fp.v1UserPatch(sendValues, {
        withCredentials: true,
      });
      if (userPatch.status === 200) {
        setUser({ ...user, ...sendValues });
        setMessage("更新に成功しました");

        if (location.pathname === "/first_account") {
          // 初回登録完了時のみ実行する処理

          // 初回登録済みステータスを更新
          await fp
            .v1UserDataFirstRegisteredStatusPatch(
              {
                status: RegisteredStatusEnum.Account,
              },
              {
                withCredentials: true,
              }
            )
            .then((res) => {
              setUserDataFirstRegisteredStatus(res.data.status);
            });

          // エージェントに新着通知
          await fp.v1NotificationsToAgentForNewUserPost({ withCredentials: true });

          sendEventToAppMeasurementTool(AppEventTokenEnum.UserAccountCompleted);

          // 確認モーダル表示
          setIsConfirmModalOpen(true);
        }
      }
    } catch (e) {
      if (e.response?.status === 401) {
        setMessage("更新に失敗しました。画面をリロードし、再度ログインしてください");
        return;
      } else if (e.response?.data.code === "INVALID_PHONE_NUMBER_AUTH_CODE") {
        setMessage("電話番号認証コードが不正です");
        return;
      }
      setSeverError(true);
    }
    setDisabled(false);
  };
  return (
    <>
      <Snackbar
        open={Boolean(message) || serverError}
        autoHideDuration={3000}
        message={message}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        {...(serverError && {
          action: (
            <Box>
              更新に失敗しました。以下をお試しください。
              <ul>
                <li>インターネット接続をご確認ください。</li>
                <li>
                  ページを
                  <a href="/">更新</a>
                  してください。
                </li>
              </ul>
              上記でも解決しない場合は
              <a href="https://form.run/@propally-1619963249">報告フォーム</a>
              にてご報告ください。
            </Box>
          ),
        })}
      />
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Card md={10}>
          <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
            <Label name="入力率" width="320px" />
            <Box width="320px" justifyContent="center" display="flex">
              <CircularProgressWithLabel value={inputCompletedRate} size={60} />
            </Box>
          </Box>

          <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
            <Label name="ID" width="320px" />
            <Box width="320px">
              <Typography variant="subtitle1" align="left">
                {user.identifier}
              </Typography>
            </Box>
          </Box>

          <Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Label name="氏名 （それぞれ15文字以内）" required width="640px" />
            </Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Box width="640px">
                <TextField
                  placeholder="姓"
                  name="family_name"
                  variant="outlined"
                  size="small"
                  color="primary"
                  style={{ marginRight: "16px", width: "calc(50% - 8px)" }}
                  inputRef={register({
                    required: "入力してください",
                    maxLength: {
                      value: 15,
                      message: "15文字以内で入力してください",
                    },
                  })}
                  defaultValue={user.family_name}
                  error={Boolean(errors.family_name)}
                  helperText={Boolean(errors.family_name) && errors.family_name.message}
                  onBlur={updateSettingRate}
                />
                <TextField
                  placeholder="名"
                  name="first_name"
                  variant="outlined"
                  size="small"
                  style={{ width: "calc(50% - 8px)" }}
                  inputRef={register({
                    required: "入力してください",
                    maxLength: {
                      value: 15,
                      message: "15文字以内で入力してください",
                    },
                  })}
                  defaultValue={user.first_name}
                  error={Boolean(errors.first_name)}
                  helperText={Boolean(errors.first_name) && errors.first_name.message}
                  onBlur={updateSettingRate}
                />
              </Box>
            </Box>
          </Box>

          <Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Label name="カナ（それぞれ15文字以内）" required width="640px" />
            </Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Box width="640px">
                <TextField
                  placeholder="セイ"
                  name="family_name_kana"
                  variant="outlined"
                  size="small"
                  color="primary"
                  style={{ marginRight: "16px", width: "calc(50% - 8px)" }}
                  inputRef={register({
                    required: "入力してください",
                    maxLength: {
                      value: 15,
                      message: "15文字以内で入力してください",
                    },
                    pattern: {
                      value: /^[ァ-ンヴー]+$/,
                      message: "カタカナのみで入力してください",
                    },
                  })}
                  defaultValue={user.family_name_kana}
                  error={Boolean(errors.family_name_kana)}
                  helperText={Boolean(errors.family_name_kana) && errors.family_name_kana.message}
                  onBlur={updateSettingRate}
                />
                <TextField
                  placeholder="メイ"
                  name="first_name_kana"
                  variant="outlined"
                  size="small"
                  style={{ width: "calc(50% - 8px)" }}
                  inputRef={register({
                    required: "入力してください",
                    maxLength: {
                      value: 15,
                      message: "15文字以内で入力してください",
                    },
                    pattern: {
                      value: /^[ァ-ンヴー]+$/,
                      message: "カタカナのみで入力してください",
                    },
                  })}
                  defaultValue={user.first_name_kana}
                  error={Boolean(errors.first_name_kana)}
                  helperText={Boolean(errors.first_name_kana) && errors.first_name_kana.message}
                  onBlur={updateSettingRate}
                />
              </Box>
            </Box>
          </Box>

          <Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Label name="生年月" required width="640px" />
            </Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Box width="640px">
                <Select
                  ref={register({ required: true })}
                  name="birth_year"
                  defaultValue={dayjs(user.birthday).get("year")}
                  style={{ marginRight: "16px", width: "calc(50% - 8px)" }}
                  onBlur={updateSettingRate}
                >
                  {years.map((year, i) => (
                    <option key={i} value={year}>
                      {year}年
                    </option>
                  ))}
                </Select>
                <Select
                  ref={register({ required: true })}
                  name="birth_month"
                  defaultValue={dayjs(user.birthday).get("month") + 1}
                  style={{ width: "calc(50% - 8px)" }}
                  onBlur={updateSettingRate}
                >
                  {months.map((month, i) => (
                    <option key={i} value={month}>
                      {month} 月
                    </option>
                  ))}
                </Select>
              </Box>
            </Box>
          </Box>

          <Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Label name="電話番号" required width="640px" />
            </Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Box width="640px">
                <TextField
                  placeholder="電話番号"
                  name="phone_number"
                  variant="outlined"
                  size="small"
                  inputRef={register({
                    required: "入力してください",
                    maxLength: {
                      value: 15,
                      message: "15桁以内で入力してください",
                    },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "半角数字で入力してください。",
                    },
                  })}
                  defaultValue={user.phone_number}
                  style={{ width: "calc(85%)" }}
                  error={Boolean(errors.phone_number)}
                  helperText={Boolean(errors.phone_number) && errors.phone_number.message}
                  onBlur={updateSettingRate}
                />
              </Box>
            </Box>
          </Box>

          {user.phone_number !== inputPhoneNumber && (
            <Box>
              <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
                <Button onClick={sendPhoneNumberAuthCode} variant="contained" color="primary">
                  電話番号認証コード取得
                </Button>
              </Box>
              <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
                <Label name="電話番号認証コード" required width="640px" />
              </Box>
              <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
                <Box width="640px">
                  <TextField
                    placeholder="200"
                    type="number"
                    name="phone_number_auth_code"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    inputRef={register({ required: "電話番号の新規登録または更新時に必須入力です" })}
                    error={!!errors.phone_number_auth_code}
                    helperText={
                      errors.phone_number_auth_code
                        ? errors.phone_number_auth_code?.message
                        : "電話番号の新規登録または更新時に必須入力です"
                    }
                  />
                </Box>
              </Box>
            </Box>
          )}

          <Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Label name="現在のお住まい（都道府県）" required width="640px" />
            </Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Box width="640px">
                <Controller
                  as={<TextField fullWidth select />}
                  control={control}
                  name="address_prefecture"
                  variant="outlined"
                  margin="dense"
                  rules={{ validate: (value) => value !== "未選択" || "選択してください" }}
                  error={!!errors.address_prefecture}
                  helperText={errors.address_prefecture?.message}
                  defaultValue={user.address_prefecture || "未選択"}
                  onBlur={updateSettingRate}
                >
                  <MenuItem key={0} value={"未選択"}>
                    未選択
                  </MenuItem>
                  {PREFECTURES.map((v, i) => {
                    return (
                      <MenuItem key={i + 1} value={v}>
                        {v}
                      </MenuItem>
                    );
                  })}
                </Controller>
              </Box>
            </Box>
          </Box>

          <Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Label name="職業" required width="640px" />
            </Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Box width="640px">
                <Select
                  ref={register({ required: true })}
                  defaultValue={user.job_category}
                  name="job_category"
                  style={{ width: "100%" }}
                  onBlur={updateSettingRate}
                >
                  {OCCUPATIONS.map((v, i) => (
                    <option key={i} value={v}>
                      {v}
                    </option>
                  ))}
                </Select>
              </Box>
            </Box>
          </Box>

          {[JobCategory.会社員上場, JobCategory.会社員非上場, JobCategory.会社員外資系企業].includes(
            inputJobCategory
          ) && (
            <Box>
              <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
                <Label name="業種" required width="640px" />
              </Box>
              <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
                <Box width="640px">
                  <Controller
                    as={<TextField fullWidth select />}
                    control={control}
                    name="job_industry"
                    variant="outlined"
                    margin="dense"
                    rules={{ validate: (value) => value !== "未選択" || "選択してください" }}
                    error={!!errors.job_industry}
                    helperText={errors.job_industry?.message}
                    defaultValue={user.job_industry || "未選択"}
                    onBlur={updateSettingRate}
                  >
                    <MenuItem key={0} value={"未選択"}>
                      未選択
                    </MenuItem>
                    {JOB_INDUSTRIES.map((v, i) => {
                      return (
                        <MenuItem key={i + 1} value={v}>
                          {v}
                        </MenuItem>
                      );
                    })}
                  </Controller>
                </Box>
              </Box>
            </Box>
          )}

          <Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Label name="勤続年数（現在の職業の年数）" required width="640px" />
            </Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Box width="640px">
                <Controller
                  as={<TextField fullWidth select />}
                  control={control}
                  name="job_continuous_period"
                  variant="outlined"
                  margin="dense"
                  rules={{ validate: (value) => value !== "未選択" || "選択してください" }}
                  error={!!errors.job_continuous_period}
                  helperText={errors.job_continuous_period?.message}
                  defaultValue={user?.job_continuous_period || "未選択"}
                  onBlur={updateSettingRate}
                >
                  <MenuItem key={0} value={"未選択"}>
                    未選択
                  </MenuItem>
                  {period_ranges.map((v, i) => {
                    return (
                      <MenuItem key={i + 1} value={v}>
                        {v}
                      </MenuItem>
                    );
                  })}
                </Controller>
              </Box>
            </Box>
          </Box>

          <Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Label name="年収" required width="640px" />
            </Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Box width="640px">
                <TextField
                  placeholder="600"
                  type="number"
                  name="annual_income"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  inputRef={register({ required: "入力してください" })}
                  error={!!errors.annual_income}
                  helperText={errors.annual_income?.message}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">万円台</InputAdornment>,
                  }}
                  defaultValue={user.annual_income}
                  onBlur={updateSettingRate}
                />
              </Box>
            </Box>
          </Box>

          <Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Label name="金融資産（預金・株式等）" required width="640px" />
            </Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Box width="640px">
                <Controller
                  as={<TextField fullWidth select />}
                  control={control}
                  name="financial_asset"
                  variant="outlined"
                  margin="dense"
                  rules={{ validate: (value) => value !== "未選択" || "選択してください" }}
                  error={!!errors.financial_asset}
                  helperText={errors.financial_asset?.message}
                  defaultValue={user?.financial_asset || "未選択"}
                  onBlur={updateSettingRate}
                >
                  <MenuItem key={0} value={"未選択"}>
                    未選択
                  </MenuItem>
                  {loan_ranges.map((v, i) => {
                    return (
                      <MenuItem key={i + 1} value={v}>
                        {v}
                      </MenuItem>
                    );
                  })}
                </Controller>
              </Box>
            </Box>
          </Box>

          <Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Label name="投資用不動産保有数(住宅ローンで組んでいる物件は含みません)" required width="640px" />
            </Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Box width="640px">
                <TextField
                  placeholder="2"
                  type="number"
                  name="holding_property_count"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  inputRef={register({ required: "入力してください" })}
                  error={!!errors.holding_property_count}
                  helperText={errors.holding_property_count?.message}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">戸</InputAdornment>,
                  }}
                  defaultValue={user.holding_property_count}
                  onBlur={updateSettingRate}
                />
              </Box>
            </Box>
          </Box>

          <Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Label name="投資用不動産ローン残高合計（住宅ローン残高は含みません）" required width="640px" />
            </Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Box width="640px">
                <TextField
                  placeholder="2300"
                  type="number"
                  name="real_estate_loan_amount"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  inputRef={register({ required: "入力してください" })}
                  error={!!errors.real_estate_loan_amount}
                  helperText={errors.real_estate_loan_amount?.message}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">万円</InputAdornment>,
                  }}
                  defaultValue={user.real_estate_loan_amount}
                  onBlur={updateSettingRate}
                />
              </Box>
            </Box>
          </Box>

          <Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Label name="現在のお住まい" required width="640px" />
            </Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Box width="640px">
                <Controller
                  as={<TextField fullWidth select />}
                  control={control}
                  name="current_residence_type_id"
                  variant="outlined"
                  margin="dense"
                  rules={{ validate: (value) => value !== 0 || "選択してください" }}
                  error={!!errors.current_residence_type_id}
                  helperText={errors.current_residence_type_id?.message}
                  defaultValue={user?.current_residence_type_id || 0}
                  onBlur={updateSettingRate}
                >
                  <MenuItem key={0} value={0}>
                    未選択
                  </MenuItem>
                  {masterResidenceTypes.map((v, i) => {
                    return (
                      <MenuItem key={i + 1} value={v.id}>
                        {v.name}
                      </MenuItem>
                    );
                  })}
                </Controller>
              </Box>
            </Box>
          </Box>

          {selectedCurrentResidenceTypeId === 1 && (
            <Box>
              <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
                <Label name="住宅ローン残高合計" required width="640px" />
              </Box>
              <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
                <Box width="640px">
                  <TextField
                    placeholder="200"
                    type="number"
                    name="home_loan_amount"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    inputRef={register({ required: "現在のお住まいで「持ち家」を選択した場合に必須入力です" })}
                    error={!!errors.home_loan_amount}
                    helperText={
                      errors.home_loan_amount
                        ? errors.home_loan_amount?.message
                        : "現在のお住まいで「持ち家」を選択した場合に必須入力です"
                    }
                    InputProps={{
                      endAdornment: <InputAdornment position="end">万円</InputAdornment>,
                    }}
                    defaultValue={user.home_loan_amount}
                    onBlur={updateSettingRate}
                  />
                </Box>
              </Box>
            </Box>
          )}

          {selectedCurrentResidenceTypeId === 2 && (
            <Box>
              <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
                <Label name="お住まいの家賃" required width="640px" />
              </Box>
              <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
                <Box width="640px">
                  <TextField
                    placeholder="23000"
                    type="number"
                    name="monthly_rent_payment_amount"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    inputRef={register({ required: "現在のお住まいで「賃貸」を選択した場合に必須入力です" })}
                    error={!!errors.monthly_rent_payment_amount}
                    helperText={
                      errors.monthly_rent_payment_amount
                        ? errors.monthly_rent_payment_amount?.message
                        : "現在のお住まいで「賃貸」を選択した場合に必須入力です"
                    }
                    InputProps={{
                      endAdornment: <InputAdornment position="end">円</InputAdornment>,
                    }}
                    defaultValue={user.monthly_rent_payment_amount}
                    onBlur={updateSettingRate}
                  />
                </Box>
              </Box>
            </Box>
          )}

          <Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Label name="興味のある項目" required width="640px" />
            </Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Box width="640px">
                <Controller
                  rules={{ validate: (v) => v?.length > 0 || "興味のある項目は1つ以上選択してください" }}
                  control={control}
                  name="interest_tag_ids"
                  defaultValue={user.interest_tag_ids}
                  render={({ onChange, value }) => (
                    <FormGroup row={true}>
                      {masterTags.map((masterTag) => (
                        <FormControlLabel
                          key={masterTag.id}
                          control={
                            <Checkbox
                              key={masterTag.id}
                              value={masterTag.id}
                              onChange={() => onChange(handleCheckInterestTag(masterTag.id))}
                              checked={value.includes(masterTag.id)}
                              onBlur={updateSettingRate}
                            />
                          }
                          label={masterTag.name}
                        />
                      ))}
                      {errors.interest_tag_ids && (
                        <FormHelperText error>{errors.interest_tag_ids.message}</FormHelperText>
                      )}
                    </FormGroup>
                  )}
                />
              </Box>
            </Box>
          </Box>

          <Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Label name="アンケート" required width="640px" />
            </Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Box width="640px">
                <Controller
                  rules={{ validate: (v) => v?.length > 0 || "選択してください" }}
                  control={control}
                  name="purpose_of_use_ids"
                  defaultValue={user.purpose_of_use_ids}
                  render={({ onChange, value }) => (
                    <FormGroup row={true}>
                      {masterPurposeOfUses.map((reason) => (
                        <FormControlLabel
                          key={reason.id}
                          control={
                            <Checkbox
                              key={reason.id}
                              value={reason.id}
                              onChange={() => onChange(handlePurposeOfUseId(reason.id))}
                              checked={value.includes(reason.id)}
                              onBlur={updateSettingRate}
                            />
                          }
                          label={reason.name}
                        />
                      ))}
                      {errors.purpose_of_use_ids && (
                        <FormHelperText error>{errors.purpose_of_use_ids.message}</FormHelperText>
                      )}
                    </FormGroup>
                  )}
                />
              </Box>
            </Box>
          </Box>

          <Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Label name="その他のご要望" optional width="640px" />
            </Box>
            <Box mb={1}>
              <Typography variant="caption">※記載いただくと、より正確なオファーがきます</Typography>
            </Box>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <Box width="640px">
                <TextField
                  multiline
                  rows={5}
                  placeholder="例：まずは不動産投資の基礎を知りたいです"
                  name="other_request_text"
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                  inputRef={register({
                    maxLength: {
                      value: 2000,
                      message: "2000文字以内で入力してください",
                    },
                  })}
                  defaultValue={user.other_request_text}
                  error={Boolean(errors.other_request_text)}
                  helperText={Boolean(errors.other_request_text) && errors.other_request_text.message}
                  onBlur={updateSettingRate}
                />
              </Box>
            </Box>
          </Box>

          <Box justifyContent="center" display="flex">
            <Button type="submit" variant="contained" color="primary" size="large" disabled={disabled}>
              更新
            </Button>
          </Box>
        </Card>
      </form>
      <AccountRegisteredModal open={isConfirmModalOpen} handleClose={null} handleClick={handleClickConfirmationModal} />
    </>
  );
};
