/**
 * HowMaが提供しているマンション情報外部API関連の処理をまとめたファイル
 *
 * 現状のフォームに合わせた形式で各項目を変換する処理等
 */

import dayjs from "dayjs";

import { PropertyLayoutEnum } from "../../api/generated/api";

/**
 * 都道府県
 *
 *   例)「東京都港区芝浦２丁目」の場合「東京都」を返却
 */
export const convertAreaNameToPrefecture = (areaName :string, masterPrefectures: Array<string>): string => {
  for (const masterPrefecture of masterPrefectures) {
    if (areaName.startsWith(masterPrefecture)) {
      return masterPrefecture;
    }
  }
  // 合致しない場合は空
  return ""
}

/**
 * 市区町村
 *
 *  例)
 *    「東京都港区芝浦２丁目」の場合「港区」を返却
 *    「福岡県福岡市中央区地行３丁目」の場合「福岡市中央区」を返却
 */
export const convertAreaNameToCity = (areaName :string, masterCities: Array<string>): string => {
  for (const masterCity of masterCities) {
    if (areaName.includes(masterCity)) {
      return masterCity;
    }
  }
  // 合致しない場合は空
  return ""
}

/**
 * 番地
 *
 *  例) 「東京都港区芝浦２丁目」の場合「芝浦二丁目」を返却
 */
export const convertAreaNameToTown = (areaName: string, masterTowns: Array<string>): string => {
  const area = convertNumberToKanji(areaName);
  for (const masterTown of masterTowns) {
    if (area.includes(masterTown)) {
      return masterTown;
    }
  }
  // 合致しない場合は空
  return ""
}

// 現行Propallyのシステムに表記を合わせるため、x丁目は全角数字から漢数字に変換する処理
const convertNumberToKanji = (town: string) : string => {
  return town
    .replace("１丁目", "一丁目")
    .replace("２丁目", "二丁目")
    .replace("３丁目", "三丁目")
    .replace("４丁目", "四丁目")
    .replace("５丁目", "五丁目")
    .replace("６丁目", "六丁目")
    .replace("７丁目", "七丁目")
    .replace("８丁目", "八丁目")
    .replace("９丁目", "九丁目")
}

/**
 * 築年数
 *
 * 年数から年月形式(月は1月で固定)に変換する
 */
export const convertBuiltAtYearToBuiltAt = (year: number): string => {
  return dayjs(`${year}-01`).format("YYYY-MM");
}

/**
 * 間取り
 */
export const convertFloorPlanToLayout = (floor_plan: string): string => {
  for (const key of Object.keys(PropertyLayoutEnum)) {
    const enumValue = PropertyLayoutEnum[key];
    if (enumValue.includes(floor_plan)) return enumValue;
  }
  // 条件に合致するものが見つからない場合は、未選択を返す
  return PropertyLayoutEnum.未選択;
}

