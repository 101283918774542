import { AssessmentProposal, InvestmentProposal, ReinfolibPrice } from "../../api/generated";
import { PropertyHolder } from "../../view_models/atoms";

// 1ページあたりのプロパティ数
const propertiesPerPage = 5;

// ページネーションのためのロジックを関数に変更
export const calculatePagination = (
  properties: PropertyHolder[],
  currentPage: number
): {
  currentProperties: PropertyHolder[];
  totalPages: number;
} => {
  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentProperties = properties.slice(indexOfFirstProperty, indexOfLastProperty);

  const totalPages = Math.ceil(properties.length / propertiesPerPage);

  return { currentProperties, totalPages };
};

export const calculatePaginationNearbyPrice = (
  prices: ReinfolibPrice[] | undefined,
  currentPage: number
): {
  currentNearbyPrices: ReinfolibPrice[];
  totalPages: number;
  propertiesPerPage: number;
} => {
  if (prices === undefined) {
    return { currentNearbyPrices: [], totalPages: 0, propertiesPerPage: 5 };
  }

  const indexOfLastPrice = currentPage * propertiesPerPage;
  const indexOfFirstPrice = indexOfLastPrice - propertiesPerPage;
  const currentNearbyPrices = prices.slice(indexOfFirstPrice, indexOfLastPrice);

  const totalPages = Math.ceil(prices.length / propertiesPerPage);

  return { currentNearbyPrices, totalPages, propertiesPerPage };
};

// オファー
const offersPerPage = 5;

// 投資オファー：ページネーションのためのロジックを関数に変更
export const calculatePaginationInvestmentProposal = (
  investmentProposals: InvestmentProposal[],
  currentPage: number
): {
  currentInvestmentProposals: InvestmentProposal[];
  totalPages: number;
} => {
  const indexOfLastProperty = currentPage * offersPerPage;
  const indexOfFirstProperty = indexOfLastProperty - offersPerPage;
  const currentInvestmentProposals = investmentProposals.slice(indexOfFirstProperty, indexOfLastProperty);

  const totalPages = Math.ceil(investmentProposals.length / offersPerPage);

  return { currentInvestmentProposals, totalPages };
};

// 投資オファー：ページネーションのためのロジックを関数に変更
export const calculatePaginationAssessmentProposal = (
  assessmentProposals: AssessmentProposal[],
  currentPage: number
): {
  currentAssessmentProposals: AssessmentProposal[];
  totalPages: number;
} => {
  const indexOfLastProperty = currentPage * offersPerPage;
  const indexOfFirstProperty = indexOfLastProperty - offersPerPage;
  const currentAssessmentProposals = assessmentProposals.slice(indexOfFirstProperty, indexOfLastProperty);

  const totalPages = Math.ceil(assessmentProposals.length / offersPerPage);

  return { currentAssessmentProposals, totalPages };
};
