import { Box, Typography, makeStyles } from "@material-ui/core";
import React, { FC, useState, useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useRecoilState } from "recoil";

import { JobCategory } from "../../../api/generated";
import { JOB_INDUSTRIES, OCCUPATIONS } from "../../../api/options/account";
import { CustomButton } from "../../../scripts/components/renewal_v1/button";
import { CustomForm } from "../../../scripts/components/renewal_v1/form";
import { CustomSelector } from "../../../scripts/components/renewal_v1/selecter";
import { RegistrationTemplate } from "../../../scripts/components/renewal_v1/template/registration_template";
import { theme } from "../../../theme";
import { UserRegistrationsAccoutFormState } from "../../../view_models/atoms";

const useStyles = makeStyles({
  link: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      borderBottom: "none",
    },
  },
});

interface RegisterJobPageProps {
  totalSteps: number;
  currentStep: number;
  onNext: () => void;
  onBack: () => void;
  validate: () => Promise<boolean>;
}

const period_ranges = ["6ヶ月未満", "6ヶ月以上1年未満", "1年以上3年未満", "3年以上"];

const RegisterJobPage: FC<RegisterJobPageProps> = ({ totalSteps, currentStep, onNext, onBack, validate }) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const watchValues = useWatch({
    name: ["job_category"],
    control: control,
  });
  // 現在のお住まい入力内容により表示フォームを変更するために必要な判定用変数
  const [inputJobCategory, setInputJobCategory] = useState<JobCategory>(JobCategory.入力なし);
  const [formData] = useRecoilState(UserRegistrationsAccoutFormState);

  const handleNext = async () => {
    const isValid = await validate();
    if (isValid) {
      onNext();
    }
  };

  // formDataが存在する場合に初期値を設定
  useEffect(() => {
    control.setValue("job_category", formData.job_category);
    control.setValue("job_continuous_period", formData.job_continuous_period);
    control.setValue("annual_income", formData.annual_income);
  }, []);

  // 職業入力内容により業界フォームの表示・非表示を変更するためのuseEffect
  useEffect(() => {
    setInputJobCategory(watchValues.job_category);
  }, [watchValues.job_category]);

  // inputJobCategory が変更された際に、job_industry の初期値を設定する
  useEffect(() => {
    control.setValue("job_industry", formData.job_industry);
  }, [inputJobCategory]);

  const occupationOptions = OCCUPATIONS.map((occupation) => ({ value: occupation, label: occupation }));
  const jobCategoryOptions = JOB_INDUSTRIES.map((jobCategory) => ({ value: jobCategory, label: jobCategory }));
  const periodRangeOptions = period_ranges.map((periodRange) => ({ value: periodRange, label: periodRange }));

  return (
    <RegistrationTemplate title="現在のお仕事について教えてください" totalSteps={totalSteps} currentStep={currentStep}>
      <Box mt={4}>
        <Controller
          name="job_category"
          control={control}
          rules={{ required: "ご職業を選択してください。" }}
          render={({ ...field }) => (
            <CustomSelector
              {...field}
              label="ご職業"
              error={!!errors.job_category}
              errorText={errors.job_category?.message}
              onChange={field.onChange}
              options={occupationOptions}
              placeHolder="選択してください"
            />
          )}
        />
        {/* 業種条件付きで表示 */}
        {[JobCategory.会社員上場, JobCategory.会社員非上場, JobCategory.会社員外資系企業].includes(
          inputJobCategory
        ) && (
          <Controller
            name="job_industry"
            control={control}
            rules={{ required: "業種を選択してください。" }}
            render={({ ...field }) => (
              <CustomSelector
                {...field}
                label="業種"
                error={!!errors.job_industry}
                errorText={errors.job_industry?.message}
                onChange={field.onChange}
                options={jobCategoryOptions}
                placeHolder="選択してください"
              />
            )}
          />
        )}

        <Controller
          name="job_continuous_period"
          control={control}
          rules={{ required: "勤続年数を選択してください。" }}
          render={({ ...field }) => (
            <CustomSelector
              {...field}
              label="勤続年数"
              error={!!errors.job_continuous_period}
              errorText={errors.job_continuous_period?.message}
              onChange={field.onChange}
              options={periodRangeOptions}
              placeHolder="選択してください"
            />
          )}
        />

        <Controller
          name="annual_income"
          control={control}
          rules={{
            required: "年収を入力してください。",
            pattern: {
              value: /^[0-9]+$/,
              message: "半角数字で入力してください。",
            },
          }}
          render={({ ...field }) => (
            <CustomForm
              {...field}
              label="年収"
              error={!!errors.annual_income}
              errorText={errors.annual_income?.message}
              onChange={field.onChange}
              placeHolder="入力してください"
              unitSuffix="万円"
            />
          )}
        />
      </Box>
      <Box mt={3}>
        <CustomButton type="button" customVariant="filled" onClick={handleNext}>
          次へ
        </CustomButton>
        <Box display="flex" margin="32px auto" justifyContent="center">
          <Typography variant="button" className={classes.link} onClick={onBack}>
            戻る
          </Typography>
        </Box>
      </Box>
    </RegistrationTemplate>
  );
};

export default RegisterJobPage;
