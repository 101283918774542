import { Chip, makeStyles, ThemeProvider, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import MailIcon from "@material-ui/icons/Mail";
import React, { FC, ReactNode } from "react";
import { useHistory } from "react-router";
import { useRecoilValue } from "recoil";

import { RegisteredStatusEnum } from "../../../../api/generated";
import Notification from "../../../../pages/renewal_v1/mypage/notification";
import { useDevice } from "../../../../utilities/sp/use_device";
import { UserDataFirstRegisteredStatusState } from "../../../../view_models/atoms";
import { hasUnread } from "../../../../view_models/proposal_unread_counts";
import { NavigationPath } from "../enums";
import Footer from "../footer";
import NavigationBar from "../navigation";
import { ManagementAppBar } from "../property/app_bar";
import theme from "../theme";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2),
    background: "white",
    boxShadow: "0px 4px 4px 0px #BFBFBF40",
    [theme.breakpoints.down("sm")]: {
      paddingTop: `calc(${theme.spacing(2)}px + 56px)`,
    },
  },
  background: {
    backgroundColor: theme.palette.grey[50],
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    padding: theme.spacing(2, 2, 10, 2),
  },
  chipWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  chip: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    padding: theme.spacing(0, 3),
    "&:hover, &:active": {
      backgroundColor: theme.palette.info.dark,
    },
    "&:focus": {
      backgroundColor: theme.palette.info.main,
    },
  },
  chipIcon: {
    color: theme.palette.common.white,
  },
}));

type Props = {
  children: ReactNode;
};

export const MypageTemplate: FC<Props> = ({ children }) => {
  const { isMobile } = useDevice();
  const classes = useStyles();

  const history = useHistory();

  const hasUnreadValue = useRecoilValue(hasUnread);
  const userDataFirstRegisteredStatus = useRecoilValue(UserDataFirstRegisteredStatusState);

  return (
    <ThemeProvider theme={theme}>
      {!isMobile && <ManagementAppBar />}

      <Box className={classes.header}>
        <Typography variant="h3">ホーム</Typography>
      </Box>
      {userDataFirstRegisteredStatus !== RegisteredStatusEnum.Property && <Notification />}
      {hasUnreadValue && (
        <Box className={classes.chipWrapper}>
          <Chip
            className={classes.chip}
            icon={<MailIcon className={classes.chipIcon} />}
            label="未読の新規オファーがあります"
            onClick={() => history.push("/offer")}
          />
        </Box>
      )}
      {/* コンテンツ */}
      <Box className={classes.background}>
        <Box className={classes.content}>{children}</Box>
      </Box>
      {isMobile ? <NavigationBar path={NavigationPath.HOME} /> : <Footer />}
    </ThemeProvider>
  );
};
