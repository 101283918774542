import { makeStyles, Box } from "@material-ui/core";
import dayjs from "dayjs";
import * as React from "react";
import { useHistory } from "react-router";
import { useSetRecoilState, useRecoilValue } from "recoil";

import { MonthlyCashflowResult, MonthlyCashflowResultUpdate } from "../../../../../../api/generated";
import { PropallyApiFactory } from "../../../../../../module/custom_api_factory";
import { EditPropertyHistoryItem } from "../../../../../../scripts/components/renewal_v1/property/edit_property_history_item";
import { useRequestInitialLoading, PropertyManagementDetailSnackbarState } from "../../../../../../view_models/atoms";
import { SelectedProperty } from "../../../../../../view_models/property_selectors";

import { insuranceType } from "./history";
import { InsuranceInfoHistoryEditor } from "./history_editor";

const useStyles = makeStyles((theme) => ({
  background: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
    padding: 16,
    marginBottom: 8,
  },
  iconRoot: {
    padding: 0,
  },
  textLink: {
    width: 80,
    textAlign: "center",
  },
}));

interface props {
  monthlyCashflowResult: MonthlyCashflowResult;
  insuranceType: insuranceType;
}

export const InsuranceInfoHistoryItem: React.FC<props> = ({ monthlyCashflowResult, insuranceType }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isShowEdior, setIsShowEditor] = React.useState(false);
  const requestInitialLoading = useRequestInitialLoading();
  // 物件詳細でのスナックバー表示
  const setManagementDetailSnackBarState = useSetRecoilState(PropertyManagementDetailSnackbarState);
  // 選択済み物件取得
  const selected_property = useRecoilValue(SelectedProperty);

  // 編集ボタンクリック時の処理
  const onClickEdit = React.useCallback(() => {
    setIsShowEditor(true);
  }, []);
  // 削除ボタンクリック時の処理
  const onClickDelete = React.useCallback(async () => {
    const fp = PropallyApiFactory();
    let sendData: MonthlyCashflowResultUpdate;
    switch (insuranceType) {
      case "火災保険":
        sendData = {
          month: monthlyCashflowResult.month,
          fire_insurance_cost: null,
          fire_insurance_holding_period: null,
        };
        break;
      case "地震保険":
        sendData = {
          month: monthlyCashflowResult.month,
          earthquake_insurance_cost: null,
          earthquake_insurance_holding_period: null,
        };
        break;
      default:
        return;
    }
    try {
      const response = await fp.v1PropertiesPropertyIdMonthlyCashflowResultsPatch(selected_property.id, sendData, {
        withCredentials: true,
      });
      if (response.status === 200) {
        requestInitialLoading();
      }
    } catch (e) {
      console.error(e);
      setManagementDetailSnackBarState({
        message: "物件情報を変更できませんでした",
        status: "error",
        isOpen: true,
      });
      history.push("/management/detail");
    }
  }, [insuranceType, selected_property.id, monthlyCashflowResult.month]);

  const getHistoryContents = (insuranceType: insuranceType, data: MonthlyCashflowResult) => {
    const startMonth = dayjs(data.month).format("YYYY/MM");
    switch (insuranceType) {
      case "火災保険":
        if (!data.fire_insurance_cost && !data.fire_insurance_holding_period) return [];
        return [
          {
            label: "火災保険料",
            value: data.fire_insurance_cost ? `${data.fire_insurance_cost.toLocaleString()}円` : "-円",
          },
          {
            label: "火災保険期間",
            value: data.fire_insurance_holding_period
              ? `${startMonth}〜${dayjs(data.month).add(data.fire_insurance_holding_period, "year").format("YYYY/MM")}`
              : "-年/-月/-日 ~ -年/-月/-日",
          },
        ];
      case "地震保険":
        if (!data.earthquake_insurance_cost && !data.earthquake_insurance_holding_period) return [];
        return [
          {
            label: "地震保険料",
            value: data.earthquake_insurance_cost ? `${data.earthquake_insurance_cost.toLocaleString()}円` : "-円",
          },
          {
            label: "地震保険期間",
            value: data.earthquake_insurance_holding_period
              ? `${startMonth}〜${dayjs(data.month)
                  .add(data.earthquake_insurance_holding_period, "year")
                  .format("YYYY/MM")}`
              : "-年/-月/-日 ~ -年/-月/-日",
          },
        ];
      default:
        return [];
    }
  };

  if (getHistoryContents(insuranceType, monthlyCashflowResult).length === 0) {
    return null;
  }

  return (
    <Box className={classes.background}>
      {isShowEdior ? (
        <InsuranceInfoHistoryEditor
          monthlyCashflowResult={monthlyCashflowResult}
          insuranceType={insuranceType}
          onCancel={() => setIsShowEditor(false)}
          onSave={() => setIsShowEditor(false)}
        />
      ) : (
        <EditPropertyHistoryItem
          contents={getHistoryContents(insuranceType, monthlyCashflowResult)}
          onEdit={onClickEdit}
          onDelete={onClickDelete}
        />
      )}
    </Box>
  );
};
