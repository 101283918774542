import { ChipProps, Chip } from "@material-ui/core";
import * as React from "react";

// const useStyles = makeStyles((theme) => ({}));

interface CustomChip extends ChipProps {
  label: string;
  outlined?: boolean;
}

export const CustomChip: React.FC<CustomChip> = ({ label, outlined = false, ...props }) => {
  // const classes = useStyles();

  return outlined ? (
    <Chip label={label} size="medium" variant="outlined" color="primary" {...props} />
  ) : (
    <Chip label={label} size="medium" variant="default" color="primary" {...props} />
  );
};
