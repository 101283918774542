import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import styled from "styled-components";

import { ColorScheme } from "../../../colorscheme";
import { useDevice } from "../../../utilities/sp/use_device";
import { Card } from "../card";
import { Logo } from "../logo";

type Props = {
  children: React.ReactNode;
  title: string;
  // TODO: 出し分けをせず全て必須で良いか後程確認
  isFooter?: boolean;
};

const StyledLink = styled(Link)`
  &.MuiLink-root {
    color: "#5772ff";
  }
` as typeof Link;

export const EntranceTemplate: React.FC<Props> = ({ children, title, isFooter }) => {
  const { isMobile } = useDevice();

  return (
    <Box bgcolor={ColorScheme.DarkBlue} pt={17} pb={7} textAlign="center" minHeight="100vh">
      <Box width={isMobile ? "auto" : "440px"} margin="0 auto" padding="0 24px">
        <Box mb={5}>
          <Logo color="white" />
        </Box>
        <Card>
          <Box mb={3} mt={2}>
            <Typography variant="h1">Propally for Owners</Typography>
          </Box>
          <Box mb={3} mt={2}>
            <Typography variant="h1">{title}</Typography>
          </Box>
          {children}
          {isFooter && (
            <Box display="flex" justifyContent="center" gridColumnGap="8px" mt={6}>
              <StyledLink component="span" variant="caption" underline="none" style={{ color: "#737585" }}>
                ©Propally
              </StyledLink>
              <StyledLink
                variant="caption"
                href="https://lp.propally.jp/termsofuse.html"
                target="_blank"
                rel="noopener"
              >
                利用規約
              </StyledLink>
              <StyledLink
                variant="caption"
                href="https://propally.co.jp/privacypolicy.html"
                target="_blank"
                rel="noopener"
              >
                プライバシーポリシー
              </StyledLink>
            </Box>
          )}
        </Card>
      </Box>
    </Box>
  );
};
