import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";

interface StatusIconProps {
  active: boolean;
}

const useStyles = makeStyles((theme) => ({
  box: {
    borderRadius: "50%",
    color: theme.palette.action.disabled,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&.active": {
      color: theme.palette.success.main,
    },
    paddingRight: theme.spacing(1),
  },
}));

function CustomCheckIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 14 14" style={{ width: "14px", height: "14px" }}>
      <circle cx="7" cy="7" r="7" fill="currentColor" />
      <path d="M3.5 7.5L6 9.5L11 4.5" stroke="white" />
    </SvgIcon>
  );
}

export const StatusIcon: React.FC<StatusIconProps> = ({ active }) => {
  const classes = useStyles();

  return (
    <Box className={`${classes.box} ${active ? "active" : ""}`}>
      <CustomCheckIcon />
    </Box>
  );
};
