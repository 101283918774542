import { withStyles } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import styled from "styled-components";

export const StickyStyledTableCell = withStyles((theme) => ({
  head: {
    [theme.breakpoints.up("md")]: {
      minWidth: "216px",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "100px",
      fontSize: "0.7em",
    },
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 2,
  },
  body: {
    [theme.breakpoints.up("md")]: {
      minWidth: "216px",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "100px",
      fontSize: "0.7em",
    },
    backgroundColor: "white",
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 1,
  },
}))(TableCell);

export const tableHeadStyle = {
  backgroundColor: "white",
  borderBottom: "solid 2px rgba(224, 224, 224, 1)",
};
export const itemCategoryNameStyle = (isMobile: boolean): Object => {
  return {
    fontSize: isMobile ? "0.9em" : "1.17em",
    fontWeight: "bold",
    borderBottom: "none",
  };
};
export const smallItemsStyle = {
  backgroundColor: "rgb(247, 248, 251)",
  borderBottom: "none",
};
export const itemsSumStyle = {
  backgroundColor: "rgb(247, 248, 251)",
  fontWeight: "bold",
  borderTop: "solid 1px rgba(224, 224, 224, 1)",
  borderBottom: "none",
};

export const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    font-size: 0.75rem;
    min-width: 88px;
  }
`;
