import { Typography, Box, makeStyles } from "@material-ui/core";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { Redirect, useHistory } from "react-router";
import { useRecoilState, useRecoilStateLoadable } from "recoil";

import { PropallyApiFactory } from "../../module/custom_api_factory";
import { CustomButton } from "../../scripts/components/renewal_v1/button";
import { CustomForm } from "../../scripts/components/renewal_v1/form";
import { EntranceTemplate } from "../../scripts/components/renewal_v1/template/entrance_template";
import { TextLink } from "../../scripts/components/renewal_v1/text_link";
import { Toast } from "../../scripts/components/renewal_v1/toast";
import { SessionState, UserState } from "../../view_models/atoms";

const useStyles = makeStyles((theme) => ({
  span: {
    fontSize: theme.typography.body1.fontSize,
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.typography.body2.fontSize,
    },
    fontWeight: theme.typography.body1.fontWeight,
    lineHeight: theme.typography.body1.lineHeight,
  },
}));

// APIの呼び出し
const postLogin = async (data: { email: string; password: string }) => {
  const fp = PropallyApiFactory();
  try {
    // if (data.email.length > 1) {
    //   return {
    //     success: false,
    //     error: "エラーサンプル",
    //   };
    // }
    await fp.v1LoginPost(data, { withCredentials: true });
    return { success: true, error: "" };
  } catch (e) {
    if (e.response?.status === 401) {
      return {
        success: false,
        error: "メールアドレスまたはパスワードが不正です",
      };
    }
    return {
      success: false,
      error: "ネットワークエラーです。しばらくたってからお試しください",
    };
  }
};

// formの型
export interface PostLoginRequestBody {
  email: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const [session, setSession] = useRecoilState(SessionState);
  const [loadable] = useRecoilStateLoadable(UserState);
  const [error, setError] = React.useState<string>("");
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PostLoginRequestBody>({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const history = useHistory();
  const classes = useStyles();

  const onClickLogin = async (data) => {
    const result = await postLogin(data);
    setSession({
      logged_in: result.success,
    });
    setError(result.error);
  };

  // トーストのクローズ
  const handleClose = () => {
    setError("");
  };

  React.useEffect(() => {
    if (loadable.state === "hasValue" && loadable.contents !== null) {
      const user = loadable.contents;
      if (!user.family_name || !user.first_name || !user.email || !user.phone_number) {
        history.push("/first_account");
      }
    }
    if (session.logged_in) {
      history.push("/first_account");
    }
  }, [loadable.contents]);

  // TODO 要恒久対応
  // 以下暫定MEMO
  // 事象：Recoilの「SessionState」で管理している「logged_in」がおそらく意図した挙動していなそうで、ログイン中でも「/login」にアクセスするとマイページにリダイレクトせずに毎回ログインが表示されてしまう件の暫定対応
  // 暫定対応: ユーザ取得APIをGETして200であればログイン状態であるので、その場合にはマイページに遷移させる
  React.useEffect(() => {
    (async () => {
      const user = await PropallyApiFactory().v1UserGet({
        withCredentials: true,
      });
      if (user.status == 200) history.push("/mypage");
    })();
  }, []);

  // TODO: ログイン済みの場合はマイページかアンケート回答画面前ページにリダイレクト
  return session.logged_in ? (
    <Redirect to="/mypage" />
  ) : (
    <EntranceTemplate title="ログイン">
      <Box mt={4}>
        <form onSubmit={handleSubmit(onClickLogin)}>
          <Box>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "メールアドレスを入力してください。",
                maxLength: {
                  value: 80,
                  message: "80文字以内で入力してください",
                },
                pattern: {
                  value: /^\S+@\S+\.\S+$/, // メール形式バリデーション
                  message: "※メールアドレスの形式が正しくありません。", // 形式エラー時のメッセージ
                },
              }}
              render={({ ...field }) => (
                <CustomForm
                  {...field}
                  label="メールアドレス"
                  type="text"
                  required
                  autoComplete="email"
                  error={!!errors.email}
                  errorText={Boolean(errors.email) && errors.email.message}
                  placeHolder="propally@gmail.com"
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{
                required: "パスワードを入力してください。",
                pattern: {
                  value: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d!"#$%&'()\-\^@[\];:]{8,72}$/,
                  message:
                    "※8～72文字の間で設定してください。<br/>※半角英字、半角数字の2種類を使用してください。<br/>【使用可能な記号 】 !\"#$%&''()-^@[;:",
                },
              }}
              render={({ ...field }) => (
                <CustomForm
                  {...field}
                  label="パスワード"
                  type="password"
                  required
                  autoComplete="current-password"
                  error={!!errors.password}
                  errorText={
                    Boolean(errors.password) && <span dangerouslySetInnerHTML={{ __html: errors.password.message }} />
                  }
                  placeHolder="半角英数８文字"
                />
              )}
            />
            <Box display="flex" margin="32px auto" justifyContent="center">
              <TextLink href="/password_reset/new" underline>
                <Typography variant="body1">パスワードを忘れた</Typography>
              </TextLink>
            </Box>
            <CustomButton type="submit" customVariant="filled">
              <Typography variant="button">ログイン</Typography>
            </CustomButton>
            <Box display="flex" margin="32px auto" justifyContent="center">
              <Typography variant="body1">
                アカウントが未登録ですか？
                <TextLink href="/registrations" bold>
                  <span className={classes.span}>会員登録</span>
                </TextLink>
              </Typography>
            </Box>
            {/* エラーメッセージ表示用 */}
            <Toast open={Boolean(error)} variant="error" message={error} onClose={handleClose} />
          </Box>
        </form>
      </Box>
    </EntranceTemplate>
  );
};

export default LoginPage;
