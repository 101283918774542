import { Box, FormGroup, Typography, makeStyles } from "@material-ui/core";
import React, { FC, useState, useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useRecoilState } from "recoil";

import { IdName } from "../../../api/generated";
import { GetMasterPurposeOfUses } from "../../../api/get_master_data";
import { CustomButton } from "../../../scripts/components/renewal_v1/button";
import { CustomCheckbox } from "../../../scripts/components/renewal_v1/checkbox";
import { CustomInput } from "../../../scripts/components/renewal_v1/input";
import { RegistrationTemplate } from "../../../scripts/components/renewal_v1/template/registration_template";
import { UserRegistrationsAccoutFormState } from "../../../view_models/atoms";

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      borderBottom: "none",
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
  },
}));

interface RegisterReasonPageProps {
  totalSteps: number;
  currentStep: number;
  onNext: () => void;
  onBack: () => void;
  validate: () => Promise<boolean>;
}

const RegisterReasonPage: FC<RegisterReasonPageProps> = ({ totalSteps, currentStep, onNext, onBack, validate }) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext();
  const [masterPurposeOfUses, setMasterPurposeOfUses] = useState<Array<IdName>>([]);
  const [selectedPurposeOfUseIds, setSelectedPurposeOfUseIds] = useState<number[]>([]);
  const [serverError, setSeverError] = useState<string>("");
  const [formData] = useRecoilState(UserRegistrationsAccoutFormState);

  const handleNext = async () => {
    const isValid = await validate();
    if (isValid) {
      onNext();
    }
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      {
        const r = await GetMasterPurposeOfUses();
        if (isMounted) {
          if (!r.success) {
            return setSeverError(r.error);
          }
          setMasterPurposeOfUses(r.purposeOfUses);
        }
      }

      return undefined;
    })();
    // formDataが存在する場合に初期値を設定
    control.setValue("purpose_of_use_ids", formData.purpose_of_use_ids);
    return () => {
      isMounted = false;
    };
  }, []);

  const watchValues = useWatch({
    name: ["purpose_of_use_ids"],
    control: control,
  });

  useEffect(() => {
    setSelectedPurposeOfUseIds(watchValues.purpose_of_use_ids);
  }, [watchValues.purpose_of_use_ids]);

  // アンケート用の関数に変更が必要
  const handlePurposeOfUseId = (checkedId) => {
    const { purpose_of_use_ids: selected_ids } = getValues();
    return selected_ids?.includes(checkedId)
      ? selected_ids?.filter((id) => id !== checkedId)
      : [...(selected_ids ?? []), checkedId];
  };

  return (
    <RegistrationTemplate
      title="Propallyを利用しようと思った理由を教えてください"
      canSelectMultiple
      subMessage="※情報は公開されません"
      totalSteps={totalSteps}
      currentStep={currentStep}
    >
      {/* エラーメッセージ表示用 */}
      {serverError && (
        <Typography variant="caption" className={classes.errorMessage}>
          {serverError}
        </Typography>
      )}
      <Box mt={4}>
        <Controller
          name="purpose_of_use_ids"
          control={control}
          rules={{ validate: (v) => v?.length > 0 || "最低1つの項目を選択してください。" }}
          render={({ ...field }) => (
            <FormGroup>
              {masterPurposeOfUses.map((reason) => (
                <CustomCheckbox
                  {...field}
                  key={reason.id}
                  label={reason.name}
                  helperText={reason.id === 5 ? "※専属のコンサルタントがサポートで付きます" : ""}
                  error={!!errors.purpose_of_use_ids}
                  onChange={() => field.onChange(handlePurposeOfUseId(reason.id))}
                  checked={field.value?.includes(reason.id)}
                />
              ))}
            </FormGroup>
          )}
        />
        {/* その他を選択したときにinputフォームを表示 */}
        {selectedPurposeOfUseIds.includes(6) && (
          <Controller
            name="purpose_of_use_free_text"
            control={control}
            rules={{ required: "理由を入力してください。" }}
            render={({ ...field }) => (
              <CustomInput
                {...field}
                label="その他"
                error={errors.purpose_of_use_free_text?.message}
                onChange={field.onChange}
              />
            )}
          />
        )}
      </Box>
      {errors && (
        <Typography variant="caption" className={classes.errorMessage}>
          {errors.purpose_of_use_ids?.message}
        </Typography>
      )}
      <Box mt={3}>
        <CustomButton type="button" customVariant="filled" onClick={handleNext}>
          次へ
        </CustomButton>
        <Box display="flex" margin="32px auto" justifyContent="center">
          <Typography variant="button" className={classes.link} onClick={onBack}>
            戻る
          </Typography>
        </Box>
      </Box>
    </RegistrationTemplate>
  );
};

export default RegisterReasonPage;
