import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import React = require("react");
import { useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import styled from "styled-components";

import { ColorScheme } from "../../../colorscheme";
import { SelectedPropertyIds } from "../../../view_models/atoms";
import { Properties } from "../../../view_models/property_selectors";

export const WhiteSelect = styled(Select)`
  padding: 8px 16px;
  color: ${ColorScheme.PrBlue};
  font-weight: bold;
  border: none;
  border-radius: 8px;
  background-color: white;
`;

type Props = {
  width?: string | number;
};

export const PropertiesSelector: React.FC<Props> = ({ width }) => {
  // MEMO 物件削除時にタイミングによってundefinedになるためfilter処理追加
  const properties = useRecoilValue(Properties).filter(v => v);
  const [selected_property_ids, setSelectedPropertyIds] = useRecoilState(SelectedPropertyIds);
  const [open, setOpen] = useState(false);

  return (
    <FormControl
      style={
        width && {
          width: width,
        }
      }
    >
      <WhiteSelect
        multiple
        displayEmpty
        disableUnderline
        input={<Input />}
        value={selected_property_ids}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        onChange={(event) => {
          const edited: Array<number> = (event.target.value as Array<string>)
            .filter((v) => {
              return v !== undefined;
            })
            .map((e) => {
              return parseInt(e);
            });

          if (!_.isEqual(edited, selected_property_ids)) {
            setSelectedPropertyIds(edited);
          }
        }}
        renderValue={() => {
          if (selected_property_ids.length === 0) {
            return "未選択";
          }
          return `${selected_property_ids.length}件選択中`;
        }}
      >
        {properties.map((property) => {
          const checked = selected_property_ids.indexOf(property.id) > -1;
          return (
            <MenuItem key={property.id} value={property.id}>
              <Checkbox checked={checked} />
              <ListItemText primary={property.property.name + property.property.room_number} />
            </MenuItem>
          );
        })}
        <Box mt={1}>
          <Typography align="center">
            <Button name="toggle" size="small" color="primary" onClick={() => setOpen(false)}>
              閉じる
            </Button>
          </Typography>
        </Box>
      </WhiteSelect>
    </FormControl>
  );
};
