import { Button, Input, Typography, makeStyles } from "@material-ui/core";
import * as React from "react";
import { ErrorOption } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  uploadButton: {
    marginTop: theme.spacing(1),
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    padding: "12px 16px 12px 16px",
    width: 165,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  label: {
    color: theme.palette.text.primary,
  },
  subLabel: {
    color: theme.palette.text.primary,
    margin: "4px 0",
  },
  input: {
    display: "none",
  },
  fileName: {
    marginTop: theme.spacing(1),
    color: "#333",
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
  },
}));

// 制限サイズ
const sizeLimit = 1024 * 1024 * 20;

interface UploadImageComponentProps {
  name: string;
  error?: boolean;
  errorText?: string | JSX.Element;
  setError: (_name: string, _error: ErrorOption) => void;
  onChange: (..._event: any[]) => void;
  clearErrors: (_name?: string | string[]) => void;
}

export const UploadComponent: React.FC<UploadImageComponentProps> = ({
  name,
  error,
  errorText,
  setError,
  onChange,
  clearErrors,
}) => {
  const classes = useStyles();
  const [fileName, setFileName] = React.useState("");

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      if (event.target.files[0].size > sizeLimit) {
        setError(name, {
          type: "manual",
          message: "20MB以下のファイルを選択してください。",
        });
        // この処理を行わないとリロードしないと再アップロードできなくなる
        event.target.value = "";
        return;
      }
      onChange(event.target.files[0]);
      clearErrors(name);
      // この処理を行わないとリロードしないと再アップロードできなくなる
      setFileName(event.target.files[0].name);
      event.target.value = "";
    } else {
      setFileName("");
    }
  };

  return (
    <div className={classes.container}>
      <Typography variant="h5" className={classes.label}>
        物件の写真
      </Typography>
      <Typography variant="body2" className={classes.subLabel}>
        画像をお持ちでない場合は不要です。
      </Typography>
      <UploadButtonComponent name={name} handleFileChange={handleFileChange} />
      {error && <Typography className={classes.errorMessage}>{errorText}</Typography>}
      {fileName && (
        <Typography variant="body2" className={classes.fileName}>
          {fileName}
        </Typography>
      )}
    </div>
  );
};

interface UploadButtonComponentProps {
  name: string;
  handleFileChange?: (_event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const UploadButtonComponentRef: React.ForwardRefRenderFunction<HTMLInputElement, UploadButtonComponentProps> = (
  { name, handleFileChange },
  ref
) => {
  const classes = useStyles();
  return (
    <>
      <Input
        inputProps={{ accept: "image/*" }}
        className={classes.input}
        name={name}
        id="contained-button-file"
        type="file"
        onChange={handleFileChange}
        inputRef={ref}
      />
      <label htmlFor="contained-button-file">
        <Button variant="outlined" component="span" className={classes.uploadButton}>
          画像アップロード
        </Button>
      </label>
    </>
  );
};

const UploadButtonComponent = React.forwardRef(UploadButtonComponentRef);
export { UploadButtonComponent };
