import { TextField, FormControl, InputAdornment, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import * as React from "react";
import { forwardRef } from "react";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 161,
    width: "100%",
  },
  textField: {
    width: "100%",
  },
  adornment: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  inputRoot: {
    paddingRight: 0,
    height: 45,
    [theme.breakpoints.down("sm")]: {
      height: 56,
    },
  },
  input: {
    padding: "12px 16px 12px 16px",
  },
  helperText: {
    marginLeft: "14px",
    marginRight: "14px",
  },
  error: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
  },
}));

interface props {
  value: string;
  onChange: (_event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (_event: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  error?: boolean;
  type?: "text" | "password" | "month";
  unitSuffix?: string;
  errorText?: string | JSX.Element;
  placeHolder?: string;
  isShort?: boolean;
}

const SimConfigFormRef: React.ForwardRefRenderFunction<HTMLInputElement, props> = (
  {
    value = "",
    onChange,
    onBlur,
    disabled = false,
    error = false,
    type = "text",
    unitSuffix = "",
    errorText = "",
    placeHolder = "",
    isShort,
  },
  ref
) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <FormControl className={classes.formControl} variant="outlined" error={error} disabled={disabled}>
      <TextField
        // className={isShort ? null : classes.textField}
        style={isShort ? { width: "161px" } : { width: "100%" }}
        variant="outlined"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        InputProps={{
          classes: { root: classes.inputRoot, input: classes.input },
          endAdornment: (
            <>
              {type === "password" && (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )}
              {unitSuffix && (
                <InputAdornment position="end" className={classes.adornment}>
                  {unitSuffix}
                </InputAdornment>
              )}
            </>
          ),
        }}
        placeholder={placeHolder}
        inputRef={ref}
        error={error}
        disabled={disabled}
      />
      {error && <Typography className={classes.errorMessage}>{errorText}</Typography>}
    </FormControl>
  );
};

const SimConfigForm = forwardRef(SimConfigFormRef);
export { SimConfigForm };
