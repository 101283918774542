import Box from "@material-ui/core/Box";
import MuiCard from "@material-ui/core/Card";
import MuiCardContent from "@material-ui/core/CardContent";
import Grid, { GridSize } from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import styled from "styled-components";

import { useDevice } from "../../utilities/sp/use_device";

type Props = {
  title?: string;
  titleSecondary?: string;
  titleExplanation?: string;
  isTitleCenter?: boolean;
  md?: GridSize;
  grid?: boolean;
  maxHeight?: boolean;
};

const StyledMaxHeightCard = styled(MuiCard)`
  &.MuiCard-root {
    height: 100%;
  }
`;

export const Card: React.FC<Props> = ({
  title,
  titleSecondary,
  titleExplanation,
  isTitleCenter,
  md,
  children,
  grid,
  maxHeight,
}) => {
  const { isMobile } = useDevice();
  const gridMd = md || 12;
  const CardContent = (
    <MuiCardContent>
      {(title || titleSecondary) && (
        <Box mb={2} {...(isTitleCenter && { textAlign: "center" })}>
          <Typography display="inline" variant={isMobile ? "h2" : "subtitle1"} component="div">
            {title}
            {titleSecondary && (
              <Typography
                display="inline"
                variant={isMobile ? "h2" : "subtitle1"}
                color="textSecondary"
                component="span"
              >
                {titleSecondary}
              </Typography>
            )}
            {titleExplanation ? (
              <Tooltip enterTouchDelay={0} title={titleExplanation}>
                <Box
                  display="inline-block"
                  bgcolor="primary.main"
                  borderRadius="50%"
                  width="1.5rem"
                  color="#FFF"
                  textAlign="center"
                  mx={0.5}
                >
                  <Typography display="inline">？</Typography>
                </Box>
              </Tooltip>
            ) : (
              ""
            )}
          </Typography>
        </Box>
      )}
      {children}
    </MuiCardContent>
  );
  const card = maxHeight ? <StyledMaxHeightCard>{CardContent}</StyledMaxHeightCard> : <MuiCard>{CardContent}</MuiCard>;

  return grid ? (
    <Grid item md={gridMd} sm={12}>
      {card}
    </Grid>
  ) : (
    card
  );
};
