import { Box, Typography, makeStyles } from "@material-ui/core";
import * as React from "react";
import { useHistory } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";

import {
  SimulationConfig,
  SimulationConfigMarketPriceChangeAtBoughtModeEnum,
  SimulationConfigWithIdProposedByEnum,
  SimulationPatternEnum,
} from "../../../../api/generated";
import { PropallyApiFactory } from "../../../../module/custom_api_factory";
import { CustomButton } from "../../../../scripts/components/renewal_v1/button";
import { PrLineChart } from "../../../../scripts/components/renewal_v1/linechart/linechart";
import { CustomSelector } from "../../../../scripts/components/renewal_v1/selecter";
import { PrBalanceChart } from "../../../../scripts/components/renewal_v1/signedbarchart/balance_chart";
import { PropertyOperationTemplate } from "../../../../scripts/components/renewal_v1/template/property_operation_template";
import { PropertyManagementDetailSnackbarState, useRequestInitialLoading } from "../../../../view_models/atoms";
import { SelectedProperty } from "../../../../view_models/property_selectors";

import { SimulationConfigNeutral, SimulationConfigOptimistic, SimulationConfigPessimistic } from "./const";
import { SimulationConfigCustomBalanceModal } from "./custom_setting_balance";
import { SimulationConfigCustomPriceLoanModal } from "./custom_setting_price_loan";
import { SimulationConfigDefaultType } from "./types";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.grey[50],
  },
  selectConfig: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "12px 24px",
    marginBottom: 8,
  },
  selectConfigDescription: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
  },
  graphCard: {
    backgroundColor: theme.palette.background.paper,
    padding: 24,
    marginBottom: 8,
  },
}));


// 楽観的
const SimulationConfigOptionOptimistic = SimulationPatternEnum.楽観的;
// 中立
const SimulationConfigOptionNeutral = SimulationPatternEnum.中立;
// 悲観的
const SimulationConfigOptionPessimistic = SimulationPatternEnum.悲観的;
// カスタム
const SimulationConfigOptionCustom = SimulationPatternEnum.カスタム;

const SimulationConfigOptions = [
  {
    label: SimulationConfigOptionOptimistic,
    value: SimulationConfigOptionOptimistic,
  },
  {
    label: SimulationConfigOptionNeutral,
    value: SimulationConfigOptionNeutral,
  },
  {
    label: SimulationConfigOptionPessimistic,
    value: SimulationConfigOptionPessimistic,
  },
  {
    label: SimulationConfigOptionCustom,
    value: SimulationConfigOptionCustom,
  },
];

export const ManagementDetailSimulationConfigPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const selected_property = useRecoilValue(SelectedProperty);
  const [simulationPattern, setSimulationPattern] = React.useState<SimulationPatternEnum>(
    selected_property.simulation_configs[0]?.simulation_pattern ?? SimulationConfigOptionOptimistic
  );
  const setManagementDetailSnackBarState = useSetRecoilState(PropertyManagementDetailSnackbarState);
  const requestInitialLoading = useRequestInitialLoading();
  // モーダル表示
  const [isOpenPriceLoanModal, setIsOpenPriceLoanModal] = React.useState(false);
  const [isOpenBalanceModal, setIsOpenBalanceModal] = React.useState(false);

  const onClickPageBack = () => {
    history.goBack();
  };

  const getSimulationConfigBySelector = (simPattern: SimulationPatternEnum): SimulationConfig => {
    let selectedConfig: SimulationConfigDefaultType;
    switch (simPattern) {
      case SimulationConfigOptionOptimistic:
        selectedConfig = SimulationConfigOptimistic;
        break;
      case SimulationConfigOptionNeutral:
        selectedConfig = SimulationConfigNeutral;
        break;
      case SimulationConfigOptionPessimistic:
        selectedConfig = SimulationConfigPessimistic;
        break;
      default:
        selectedConfig = SimulationConfigNeutral;
    }
    // market_price_change_down_valueの正負
    if (selectedConfig.market_price_mode_sign === "positive") {
      selectedConfig.market_price_change_down_value *= -1;
    }
    //

    return {
      // 価格
      market_price_change_down_value: selectedConfig.market_price_change_down_value,
      market_price_mode: selectedConfig.market_price_mode,
      market_price_change_ends_at: selectedConfig.market_price_change_ends_at,
      // 収支
      house_rent_spare_months_for_N_contracts: selectedConfig.house_rent_spare_months_for_N_contracts,
      house_rent_spare_months: selectedConfig.house_rent_spare_months,
      house_rent_price_change_down_value: selectedConfig.house_rent_price_change_down_value,
      house_rent_mode: selectedConfig.house_rent_mode,
      // 初期値
      market_price_change_at_bought_value: 0,
      house_rent_price_change_ends_at: 100, // 家賃変動率 N回目の契約から変動ストップ のNの値
      market_price_change_at_bought_mode: SimulationConfigMarketPriceChangeAtBoughtModeEnum.Percentage,
      // 選択パターン
      simulation_pattern: simPattern,
    };
  };
  const onClickSave = async (selectedSimPattern: SimulationPatternEnum) => {
    setSimulationPattern(selectedSimPattern);

    if (selectedSimPattern === SimulationConfigOptionCustom) {
      return;
    }
    // シミュレーションパラメータを送信
    const fp = PropallyApiFactory();

    // 既存のシミュレーション設定を取得
    const user_simconf = selected_property.simulation_configs.find((c) => {
      return c.proposed_by !== SimulationConfigWithIdProposedByEnum.Realtor;
    });

    // シミュレーション設定を取得
    const selectedSimConf = getSimulationConfigBySelector(selectedSimPattern);
    try {
      const data = await fp.v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdPatch(
        selected_property.id,
        user_simconf.id,
        selectedSimConf,
        {
          withCredentials: true,
        }
      );
      if (data.status === 200) {
        requestInitialLoading();
      }
    } catch (e) {
      console.error(e);
      setManagementDetailSnackBarState({
        message: "シミュレーション設定を変更できませんでした",
        status: "error",
        isOpen: true,
      });
    }
  };

  const simulationConfigDescriptions = React.useMemo(
    () =>
      new Map<SimulationPatternEnum, { price: string; vacancyPeriod: string; rentChange: string }>([
        [
          SimulationConfigOptionOptimistic,
          {
            price: "毎年【1】％上昇",
            vacancyPeriod: "【3】回の賃貸借契約ごとに【1】ヶ月発生",
            rentChange: "賃貸借契約ごとに現在より【3】%上昇",
          },
        ],
        [
          SimulationConfigOptionNeutral,
          {
            price: "変わらない",
            vacancyPeriod: "【2】回の賃貸借契約ごとに【1】ヶ月発生",
            rentChange: "賃貸借契約ごとに現在より変わらない",
          },
        ],
        [
          SimulationConfigOptionPessimistic,
          {
            price: "毎年【1】％下落",
            vacancyPeriod: "【1】回の賃貸借契約ごとに【1】ヶ月発生",
            rentChange: "賃貸借契約ごとに現在より【3】%下落",
          },
        ],
      ]),
    []
  );

  const selectedDescription = React.useMemo(() => {
    return simulationConfigDescriptions.get(simulationPattern);
  }, [simulationPattern, simulationConfigDescriptions]);

  return (
    <PropertyOperationTemplate
      title="シミュレーション設定"
      onBack={onClickPageBack}
      isModalOpen={isOpenPriceLoanModal || isOpenBalanceModal}
    >
      <Box className={classes.background}>
        <Box className={classes.selectConfig}>
          <CustomSelector
            label="シミュレーション設定"
            options={SimulationConfigOptions}
            value={simulationPattern}
            onChange={async (e) => {
              await onClickSave(e.target.value as SimulationPatternEnum);
            }}
          />
          {/* 各パラメータの説明文 */}
          {selectedDescription && (
            <Box className={classes.selectConfigDescription}>
              <Typography variant="body2">【{simulationPattern}】</Typography>
              <Typography variant="body2">・物件価格: {selectedDescription.price}</Typography>
              <Typography variant="body2">・空室期間: {selectedDescription.vacancyPeriod}</Typography>
              <Typography variant="body2">・家賃変動: {selectedDescription.rentChange}</Typography>
            </Box>
          )}
        </Box>

        <Box className={classes.graphCard}>
          {/* 物件価格とローン残高推移 */}
          <Typography variant="h3" style={{ paddingTop: "12px" }}>
            物件価格とローン残高推移
          </Typography>
          <Typography variant="body1" style={{ paddingTop: "12px" }}>
            物件価格とローン残高の差が大きいほど
            <br />
            売却益が高くなります。
          </Typography>
          <Typography variant="body2" align="center" color="textSecondary" style={{ paddingTop: "12px" }}>
            シミュレーション「{simulationPattern}」で設定されています。
          </Typography>
          <PrLineChart />
          {simulationPattern === SimulationConfigOptionCustom && (
            <Box mt={2}>
              {/* 自分で設定する */}
              <CustomButton
                customVariant="outlinedGray"
                isEdit
                onClick={async () => {
                  window.scrollTo(0, 0);
                  setIsOpenPriceLoanModal(true);
                }}
              >
                自分で設定する
              </CustomButton>
            </Box>
          )}
        </Box>

        <Box className={classes.graphCard}>
          {/* 将来収支 */}
          <Typography variant="h3" style={{ paddingTop: "12px" }}>
            将来収支
          </Typography>
          <Typography variant="body1" style={{ paddingTop: "12px" }}>
            将来的な年間キャッシュフローを予測できます。
          </Typography>
          <Typography variant="body2" color="textSecondary">※最後に登録した賃貸借契約期間が更新されていく想定のシミュレーションになります。</Typography>
          <Typography variant="body2" align="center" color="textSecondary" style={{ paddingTop: "12px" }}>
            シミュレーション「{simulationPattern}」で設定されています。
          </Typography>
          <PrBalanceChart />
          {simulationPattern === SimulationConfigOptionCustom && (
            <Box mt={2}>
              {/* 自分で設定する */}
              <CustomButton
                customVariant="outlinedGray"
                isEdit
                onClick={async () => {
                  window.scrollTo(0, 0);
                  setIsOpenBalanceModal(true);
                }}
              >
                自分で設定する
              </CustomButton>
            </Box>
          )}
        </Box>
      </Box>
      {isOpenPriceLoanModal && (
        <SimulationConfigCustomPriceLoanModal
          onClose={() => setIsOpenPriceLoanModal(false)}
          onClickCancel={() => setIsOpenPriceLoanModal(false)}
          onClickSave={() => setIsOpenPriceLoanModal(false)}
        />
      )}
      {isOpenBalanceModal && (
        <SimulationConfigCustomBalanceModal
          onClose={() => setIsOpenBalanceModal(false)}
          onClickCancel={() => setIsOpenBalanceModal(false)}
          onClickSave={() => setIsOpenBalanceModal(false)}
        />
      )}
    </PropertyOperationTemplate>
  );
};
