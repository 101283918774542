import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import React = require("react");

export const Loading: React.FC = () => {
  return (
    <Box display="flex" justifyContent="center" mt={3}>
      <CircularProgress />
    </Box>
  );
};
