import { DocumentType, LeaseContractTypeOfTheIncomeOfTheBeginningOfContractEnum } from "../../../api/generated/api";

import { DocumentTypeOption } from "./types";

export const MANAGEMENT_FORM_SHORT_WIDTH = 161;

export const DocumentTypeOptions: DocumentTypeOption[] = [
  {
    value: DocumentType.NUMBER_0,
    label: "マイソク・物件オファー資料",
  },
  {
    value: DocumentType.NUMBER_1,
    label: "物件パンフレット",
  },
  {
    value: DocumentType.NUMBER_2,
    label: "売買契約書",
  },
  {
    value: DocumentType.NUMBER_3,
    label: "重要事項説明書",
  },
  {
    value: DocumentType.NUMBER_4,
    label: "金銭消費貸借契約書",
  },
  {
    value: DocumentType.NUMBER_5,
    label: "返済予定表",
  },
  {
    value: DocumentType.NUMBER_6,
    label: "賃貸借契約書",
  },
  {
    value: DocumentType.NUMBER_7,
    label: "賃貸管理委託契約書",
  },
  {
    value: DocumentType.NUMBER_8,
    label: "保険証券",
  },
  {
    value: DocumentType.NUMBER_9,
    label: "納税通知書（固定資産税・都市計画税）",
  },
  {
    value: DocumentType.NUMBER_10,
    label: "納税通知書（不動産取得税）",
  },
  {
    value: DocumentType.NUMBER_11,
    label: "その他",
  },
];

export const LeaseContractTypeOfTheIncomeOfTheBeginningOfContractOptions = [
  { value: "", label: "未選択" },
  { value: LeaseContractTypeOfTheIncomeOfTheBeginningOfContractEnum.KeyMoney, label: "礼金" },
  { value: LeaseContractTypeOfTheIncomeOfTheBeginningOfContractEnum.SecurityDeposit, label: "更新料" },
];
