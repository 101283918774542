import * as React from "react";

interface props {
  width?: string;
  height?: string;
  fill?: string;
}

const OfferStrengthOfPropertiesForSale: React.FC<props> = ({
  width = "18",
  height = "22",
  fill = "#103894",
}): React.JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 2V20H10V16.5H8V20H2V2H16ZM12 6H14V4H12V6ZM8 6H10V4H8V6ZM4 6H6V4H4V6ZM12 10H14V8H12V10ZM8 10H10V8H8V10ZM4 10H6V8H4V10ZM12 14H14V12H12V14ZM8 14H10V12H8V14ZM4 14H6V12H4V14ZM12 18H14V16H12V18ZM4 18H6V16H4V18ZM18 0H0V22H18V0Z"
      fill={fill}
    />
  </svg>
);
export default OfferStrengthOfPropertiesForSale;
