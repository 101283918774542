import { Typography, Box, makeStyles } from "@material-ui/core";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useRecoilState } from "recoil";

import { PropallyApiFactory } from "../../../module/custom_api_factory";
import { CustomButton } from "../../../scripts/components/renewal_v1/button";
import { CustomForm } from "../../../scripts/components/renewal_v1/form";
import { EntranceTemplate } from "../../../scripts/components/renewal_v1/template/entrance_template";
import { UserRegistrationsFormState } from "../../../view_models/atoms";

import { PostRegisterRequestBody } from "./types";

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      borderBottom: "none",
    },
  },
  error: {
    color: "#DE351F", // TODO: 反映されないため。theme.palette.error.main,
    fontSize: theme.typography.caption.fontSize,
  },
}));

// APIの呼び出し
const sendPhoneNumberAuthCode = async (data: { phone_number: string }) => {
  const fp = PropallyApiFactory();

  try {
    // return { success: true, error: "" };
    const sendResponse = await fp.v1SendPhoneNumberAuthCodesPost(
      { phone_number: data.phone_number },
      {
        withCredentials: true,
      }
    );
    if (sendResponse.status === 200) {
      // return { success: true, error:"電話番号認証コード送信に成功しました。"}
      return { success: true, error: "" };
    }
  } catch (e) {
    return { success: false, error: "電話番号認証コード送信に失敗しました。" };
  }
};

interface RegisterPhoneNumberPageProps {
  onNext: () => void;
  onBack: () => void;
  validate: () => Promise<boolean>;
}

const RegisterPhoneNumberPage: React.FC<RegisterPhoneNumberPageProps> = ({ onNext, onBack, validate }) => {
  const [error, setError] = React.useState<string>("");
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext<PostRegisterRequestBody>();
  const [formData, setFormData] = useRecoilState(UserRegistrationsFormState);
  const classes = useStyles();

  const handleNext = React.useCallback(async () => {
    // バリデーション
    const isValid = await validate();
    if (!isValid) return;
    // 電話番号を送信
    const data = getValues();
    const result = await sendPhoneNumberAuthCode({ phone_number: data.phone_number });
    if (result.success) {
      setFormData({ ...formData, phone_number: data.phone_number });
      onNext();
    } else {
      setError(result.error);
    }
  }, [getValues, onNext, validate, formData]);

  return (
    <EntranceTemplate title="SMS認証">
      {error && (
        <Typography variant="caption" className={classes.error}>
          {error}
        </Typography>
      )}
      <Box mt={4}>
        <Box>
          <Controller
            name="phone_number"
            control={control}
            defaultValue={formData.phone_number || ""}
            rules={{
              required: "電話番号を入力してください。",
              pattern: {
                value: /^[0-9]{10,11}$/,
                message: "※正しい電話番号をご入力ください。",
              },
            }}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="電話番号"
                type="text"
                required
                error={!!errors.phone_number}
                errorText={errors.phone_number?.message}
                placeHolder="08001234567"
              />
            )}
          />
          <Box display="flex" margin="40px auto" justifyContent="center" width="300px">
            <CustomButton type="button" customVariant="filled" onClick={handleNext}>
              <Typography variant="button">認証コードを送信する</Typography>
            </CustomButton>
          </Box>
          <Box display="flex" margin="32px auto" justifyContent="center">
            <Typography variant="button" className={classes.link} onClick={onBack}>
              戻る
            </Typography>
          </Box>
        </Box>
      </Box>
    </EntranceTemplate>
  );
};

export default RegisterPhoneNumberPage;
