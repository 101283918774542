import { Box, Card, CardContent, makeStyles } from "@material-ui/core";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  card: {
    border: "1px solid #ccc",
    borderRadius: 8,
  },
  cardContent: {
    padding: 0,
    width: 400,
    [theme.breakpoints.down("sm")]: {
      width: 342,
    },
    "&:last-child": {
      paddingBottom: 0,
    },
  },
}));

interface StyledModalProps {
  children: React.ReactNode;
  onClickOutside: () => void;
}

export const StyledModal: React.FC<StyledModalProps> = ({ children, onClickOutside }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="absolute"
      top={0}
      left={0}
      width="100%"
      height="100%"
      bgcolor="rgba(0, 0, 0, 0.5)"
      onClick={onClickOutside}
    >
      <Card className={classes.card} onClick={(e) => e.stopPropagation()}>
        <CardContent className={classes.cardContent}>{children}</CardContent>
      </Card>
    </Box>
  );
};
