import { Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useState } from "react";
import { useRecoilState } from "recoil";

import { CustomIconButton } from "../../../scripts/components/renewal_v1/custom_icon_button";
import { NavigationPath } from "../../../scripts/components/renewal_v1/enums";
import Footer from "../../../scripts/components/renewal_v1/footer";
import NavigationBar from "../../../scripts/components/renewal_v1/navigation";
import { MainTemplate } from "../../../scripts/components/renewal_v1/template/main_template";
import { Toast } from "../../../scripts/components/renewal_v1/toast";
import { BaseTwoColumnTab } from "../../../scripts/components/renewal_v1/two_col_tab";
import { useDevice } from "../../../utilities/sp/use_device";
import { PropertyManagementListSnackbarState } from "../../../view_models/atoms";

import { AddNewPropertyModal } from "./management_tabs/add_new_property_modal";
import { ConsiderationPropertyTab } from "./management_tabs/consideration";
import { OwnPropertyTab } from "./management_tabs/own";

export const ManagementPage: React.FC = () => {
  const { isMobile } = useDevice();

  const [snackbarState, setSnackbarState] = useRecoilState(PropertyManagementListSnackbarState);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onClickAddProperty = () => {
    window.scrollTo(0, 0);
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setSnackbarState({ ...snackbarState, isOpen: false });
  };

  return (
    <MainTemplate>
      <Box margin="0 auto">
        <BaseTwoColumnTab
          firstTabLabel="保有中"
          firstTabChildren={<OwnPropertyTab />}
          secondTabLabel="検討中"
          secondTabChildren={<ConsiderationPropertyTab />}
        />
      </Box>

      {isMobile ? (
        <>
          <CustomIconButton onClick={onClickAddProperty} float>
            <AddIcon />
          </CustomIconButton>
          <NavigationBar path={NavigationPath.PROPERTY_MANAGEMENT} />
        </>
      ) : (
        <Footer />
      )}
      <AddNewPropertyModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <Toast
        open={snackbarState.isOpen}
        variant={snackbarState.status}
        message={snackbarState.message}
        onClose={handleClose}
      />
    </MainTemplate>
  );
};
