import * as React from "react";

interface props {
  width?: string;
  height?: string;
  fill?: string;
}

const OfferPartners: React.FC<props> = ({ width = "22", height = "20", fill = "#103894" }): React.JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.71 7.70999C21.96 6.45999 21.39 4.99999 20.71 4.28999L17.71 1.28999C16.45 0.0399901 15 0.60999 14.29 1.28999L12.59 2.99999H9.99999C8.09999 2.99999 6.99999 3.99999 6.43999 5.14999L1.99999 9.58999V13.59L1.28999 14.29C0.0399901 15.55 0.60999 17 1.28999 17.71L4.28999 20.71C4.82999 21.25 5.40999 21.45 5.95999 21.45C6.66999 21.45 7.31999 21.1 7.70999 20.71L10.41 18H14C15.7 18 16.56 16.94 16.87 15.9C18 15.6 18.62 14.74 18.87 13.9C20.42 13.5 21 12.03 21 11V7.99999H20.41L20.71 7.70999ZM19 11C19 11.45 18.81 12 18 12H17V13C17 13.45 16.81 14 16 14H15V15C15 15.45 14.81 16 14 16H9.58999L6.30999 19.28C5.99999 19.57 5.81999 19.4 5.70999 19.29L2.71999 16.31C2.42999 16 2.59999 15.82 2.70999 15.71L3.99999 14.41V10.41L5.99999 8.40999V9.99999C5.99999 11.21 6.79999 13 8.99999 13C11.2 13 12 11.21 12 9.99999H19V11ZM19.29 6.28999L17.59 7.99999H9.99999V9.99999C9.99999 10.45 9.80999 11 8.99999 11C8.18999 11 7.99999 10.45 7.99999 9.99999V6.99999C7.99999 6.53999 8.16999 4.99999 9.99999 4.99999H13.41L15.69 2.71999C16 2.42999 16.18 2.59999 16.29 2.70999L19.28 5.68999C19.57 5.99999 19.4 6.17999 19.29 6.28999Z"
      fill={fill}
    />
  </svg>
);
export default OfferPartners;
