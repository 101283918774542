import { PropallyApiFactory } from "../module/custom_api_factory";
import { PropertyHolder } from "../view_models/atoms";

export const deleteProperty = async (property: PropertyHolder) => {
  const fp = PropallyApiFactory();
  try {
    await fp.v1PropertiesPropertyIdDelete(property.id, {
      withCredentials: true,
    });

    return { success: true, error: "" };
  } catch (e) {
    if (e.response?.status === 401) {
      return {
        success: false,
        error: "画面をリロードし、再度ログインしてください",
      };
    }
    if (e.response?.status === 422) {
      return { success: false, error: "バリデーションエラーです" };
    }
    return {
      success: false,
      error: "ネットワークエラーです。しばらくたってからお試しください",
    };
  }
};
