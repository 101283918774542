import { makeStyles, Box } from "@material-ui/core";
import dayjs from "dayjs";
import * as React from "react";

import { LatestHistoryContent } from "../../../../../../scripts/components/renewal_v1/property/edit_property_history";
import { PropertyOperationTemplate } from "../../../../../../scripts/components/renewal_v1/template/property_operation_template";
import {
  getFormatCost,
  getFormatStartMonth,
  getLatestMonthlyCashflowResult,
} from "../../../../../../utilities/renewal_v1/property_edit";
import { PropertyHolder } from "../../../../../../view_models/atoms";

import { EditPropertySectionInsuranceInfoHistory, insuranceType } from "./history";

const useStyles = makeStyles((theme) => ({
  content: {
    width: 640,
    [theme.breakpoints.down("sm")]: {
      width: 342,
    },
    margin: "16px auto 0px auto",
  },
}));

interface EditPropertySectionInsuranceInfoProps {
  propertyHolder: PropertyHolder;
  onClickPageBack: () => void;
}

export const EditPropertySectionInsuranceInfo: React.FC<EditPropertySectionInsuranceInfoProps> = ({
  propertyHolder,
  onClickPageBack,
}) => {
  const classes = useStyles();
  // 過去履歴の表示・非表示
  const [isShowHistory, setIsShowHistory] = React.useState(false);
  // 過去履歴ページに渡す情報
  const [selectedInsuranceType, setSelectedInsuranceType] = React.useState<insuranceType | null>(null);

  /*
   保険情報の取得
  */

  // 火災保険
  // fire_insurance_cost を含む最新のMonthlyCashflowResultを取得
  const latestMonthlyCashflowResultFireInsuranceCost = React.useMemo(() => {
    return getLatestMonthlyCashflowResult(propertyHolder.user_input_monthly_cashflow_results, "fire_insurance_cost");
  }, [propertyHolder.user_input_monthly_cashflow_results]);

  const fireInsuranceCost = getFormatCost(latestMonthlyCashflowResultFireInsuranceCost?.fire_insurance_cost);
  const fireInsuranceCostStartMonth = latestMonthlyCashflowResultFireInsuranceCost?.month;

  const fireInsurancePeriod = latestMonthlyCashflowResultFireInsuranceCost?.fire_insurance_holding_period
    ? `${getFormatStartMonth(latestMonthlyCashflowResultFireInsuranceCost)} ~ ${dayjs(fireInsuranceCostStartMonth)
        .add(latestMonthlyCashflowResultFireInsuranceCost?.fire_insurance_holding_period, "year")
        .format("YYYY/MM")}`
    : "-年/月 ~ -年/月";

  // 地震保険
  // earthquake_insurance_cost を含む最新のMonthlyCashflowResultを取得
  const latestMonthlyCashflowResultEarthquakeInsuranceCost = React.useMemo(() => {
    return getLatestMonthlyCashflowResult(
      propertyHolder.user_input_monthly_cashflow_results,
      "earthquake_insurance_cost"
    );
  }, [propertyHolder.user_input_monthly_cashflow_results]);

  const earthquakeInsuranceCost = getFormatCost(
    latestMonthlyCashflowResultEarthquakeInsuranceCost?.earthquake_insurance_cost
  );

  const earthquakeInsurancePeriod =
    latestMonthlyCashflowResultEarthquakeInsuranceCost?.earthquake_insurance_holding_period
      ? `${getFormatStartMonth(latestMonthlyCashflowResultEarthquakeInsuranceCost)} ~ ${dayjs(
          latestMonthlyCashflowResultEarthquakeInsuranceCost?.month
        )
          .add(latestMonthlyCashflowResultEarthquakeInsuranceCost?.earthquake_insurance_holding_period, "year")
          .format("YYYY/MM")}`
      : "-年/月 ~ -年/月";

  // 過去の履歴
  const onClickPageBackFromHistory = () => {
    setIsShowHistory(false);
  };
  const handleClickShowMoreHistory = (insuranceType: insuranceType) => {
    setSelectedInsuranceType(insuranceType);
    setIsShowHistory(true);
  };
  if (isShowHistory) {
    return (
      <EditPropertySectionInsuranceInfoHistory
        insuranceType={selectedInsuranceType}
        onClickPageBack={onClickPageBackFromHistory}
      />
    );
  }

  return (
    <PropertyOperationTemplate title="保険情報" onBack={onClickPageBack}>
      <Box>
        <Box className={classes.content}>
          {/* 火災保険 */}
          <LatestHistoryContent
            title="火災保険"
            contents={[
              { label: "火災保険料", value: fireInsuranceCost },
              { label: "火災保険期間", value: fireInsurancePeriod },
            ]}
            onClickShowMoreHistory={() => handleClickShowMoreHistory("火災保険")}
          />

          {/* 地震保険 */}
          <LatestHistoryContent
            title="地震保険"
            contents={[
              { label: "地震保険料", value: earthquakeInsuranceCost },
              { label: "地震保険期間", value: earthquakeInsurancePeriod },
            ]}
            onClickShowMoreHistory={() => handleClickShowMoreHistory("地震保険")}
          />
        </Box>
      </Box>
    </PropertyOperationTemplate>
  );
};
