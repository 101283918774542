import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  typography: {
    h1: {
      fontWeight: 700,
      fontSize: "1.5rem",
      lineHeight: "100%",
    },
    h2: {
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "100%",
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: "1.125rem",
      lineHeight: "100%",
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: "1.875rem",
      lineHeight: "100%",
    },
    caption: {
      lineHeight: "100%",
    },
    fontFamily: ["Noto Sans JP", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#5772FF",
    },
    secondary: {
      main: "#FF6B7D",
    },
    text: {
      primary: "#424750",
      secondary: "#737585",
    },
    background: {
      default: "#f7f8fb",
      paper: "#FFFFFF",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768, // タブレットでの見た目をPCと同じにするため、mdのみ値をデフォルトから変更
      lg: 1280,
      xl: 1920,
    },
  },
  // MUI components override styles
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          boxSizing: "border-box",
        },
      },
    },
    MuiCard: {
      root: {
        borderRadius: 16,
        boxShadow: "0px 0px 12px rgba(128, 131, 210, 0.08)",
      },
    },
    MuiCardContent: {
      root: {
        padding: 24,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: "#D5DAE4",
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "0.875rem",
        color: "#737585",
      },
    },
    MuiButton: {
      root: {
        fontSize: "0.875rem",
        fontWeight: 700,
        borderRadius: 8,
      },
      contained: {
        color: "#5772FF",
        backgroundColor: "white",
        boxShadow: "none",
      },
      containedPrimary: {
        color: "white",
        boxShadow: "none",
      },
      containedSecondary: {
        color: "white",
        boxShadow: "none",
      },
      textPrimary: {
        backgroundColor: "rgba(87, 104, 255, 0.08)",
        "&:hover": {
          color: "#5772FF",
        },
      },
      textSecondary: {
        backgroundColor: "rgba(255, 107, 125, 0.08)",
        color: "#FF6B7D",
        "&:hover": {
          color: "#FF6B7D",
        },
      },
      sizeLarge: {
        minWidth: 256,
      },
      sizeSmall: {
        padding: "8px 16px",
        fontSize: "0.75rem",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "0.8rem",
      },
    },
    MuiBottomNavigation: {
      root: {
        position: "fixed",
        width: "100%",
        bottom: 0,
        height: "74px", // default 56px
        paddingBottom: "18px",
        borderTop: "1px solid #E0E0E0",
        zIndex: 1150,
      },
    },
    MuiBottomNavigationAction: {
      label: {
        fontSize: "12px",
        "&$selected": {
          fontSize: "12px",
        },
      },
    },
  },
});
