import { atom, selector } from "recoil";

export const investmentUnreadCount = atom<{ unread_count: number }>({
  key: "investmentUnreadCount",
  default: null,
});

export const assessmentUnreadCount = atom<{ unread_count: number }>({
  key: "assessmentUnreadCount",
  default: null,
});

export const hasUnread = selector({
  key: "hasUnread",
  get: ({ get }) => {
    const investmentUnread = get(investmentUnreadCount);
    const assessmentUnread = get(assessmentUnreadCount);

    return investmentUnread?.unread_count > 0 || assessmentUnread?.unread_count > 0;
  },
});
