import { Typography, Box, Grid, makeStyles } from "@material-ui/core";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useRecoilState } from "recoil";

import { CustomButton } from "../../../scripts/components/renewal_v1/button";
import { CustomForm } from "../../../scripts/components/renewal_v1/form";
import { EntranceTemplate } from "../../../scripts/components/renewal_v1/template/entrance_template";
import { TextLink } from "../../../scripts/components/renewal_v1/text_link";
import { useDevice } from "../../../utilities/sp/use_device";
import { UserRegistrationsFormState } from "../../../view_models/atoms";

import { PostRegisterRequestBody } from "./types";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    padding: theme.spacing(1),
  },
  span: {
    fontSize: theme.typography.body1.fontSize,
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.typography.body2.fontSize,
    },
    fontWeight: theme.typography.body1.fontWeight,
    lineHeight: theme.typography.body1.lineHeight,
  },
}));

interface RegisterBasicPageProps {
  onNext: () => void;
  validate: () => Promise<boolean>;
}

const RegisterBasicPage: React.FC<RegisterBasicPageProps> = ({ onNext, validate }) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
    getValues,
    setError,
  } = useFormContext<PostRegisterRequestBody>();
  const [formData, setFormData] = useRecoilState(UserRegistrationsFormState);
  const { isMobile } = useDevice();

  const handleNext = React.useCallback(async () => {
    const data = getValues();
    if (data.password !== data.password_confirmation) {
      setError("password_confirmation", { type: "manual", message: "※パスワードが一致しません。" });
      return;
    }
    const isValid = await validate();
    if (isValid) {
      setFormData(data);
      onNext();
    }
  }, [getValues, validate, onNext]);

  return (
    <EntranceTemplate title="会員登録">
      <Box mt={4} mb={isMobile ? 0 : 15}>
        <Grid container spacing={2}>
          <Grid item xs={6} className={classes.gridItem}>
            <Controller
              name="family_name"
              control={control}
              defaultValue={formData.family_name || ""}
              rules={{ required: "姓を入力してください。" }}
              render={({ ...field }) => (
                <CustomForm
                  {...field}
                  label="姓"
                  type="text"
                  required
                  error={!!errors.family_name}
                  errorText={errors.family_name?.message}
                  placeHolder="山田"
                />
              )}
            />
          </Grid>
          <Grid item xs={6} className={classes.gridItem}>
            <Controller
              name="first_name"
              control={control}
              defaultValue={formData.first_name || ""}
              rules={{ required: "名を入力してください。" }}
              render={({ ...field }) => (
                <CustomForm
                  {...field}
                  label="名"
                  type="text"
                  required
                  error={!!errors.first_name}
                  errorText={errors.first_name?.message}
                  placeHolder="太郎"
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} className={classes.gridItem}>
            <Controller
              name="family_name_kana"
              control={control}
              defaultValue={formData.family_name_kana || ""}
              rules={{
                required: "姓（カナ）を入力してください。",
                pattern: {
                  value: /^[ァ-ンヴー]*$/,
                  message: "※全角カタカナで入力してください。",
                },
              }}
              render={({ ...field }) => (
                <CustomForm
                  {...field}
                  label="姓（カナ）"
                  type="text"
                  required
                  error={!!errors.family_name_kana}
                  errorText={errors.family_name_kana?.message}
                  placeHolder="ヤマダ"
                />
              )}
            />
          </Grid>
          <Grid item xs={6} className={classes.gridItem}>
            <Controller
              name="first_name_kana"
              control={control}
              defaultValue={formData.first_name_kana || ""}
              rules={{
                required: "名（カナ）を入力してください。",
                pattern: {
                  value: /^[ァ-ンヴー]*$/,
                  message: "※全角カタカナで入力してください。",
                },
              }}
              render={({ ...field }) => (
                <CustomForm
                  {...field}
                  label="名（カナ）"
                  type="text"
                  required
                  error={!!errors.first_name_kana}
                  errorText={errors.first_name_kana?.message}
                  placeHolder="タロウ"
                />
              )}
            />
          </Grid>
        </Grid>
        <Controller
          name="email"
          control={control}
          defaultValue={formData.email || ""}
          rules={{
            required: "メールアドレスを入力してください。",
            maxLength: {
              value: 80,
              message: "80文字以内で入力してください",
            },
            pattern: {
              value: /^\S+@\S+\.\S+$/, // メール形式バリデーション
              message: "※メールアドレスの形式が正しくありません。", // 形式エラー時のメッセージ
            },
          }}
          render={({ ...field }) => (
            <CustomForm
              {...field}
              label="メールアドレス"
              type="text"
              required
              autoComplete="email"
              error={!!errors.email}
              errorText={Boolean(errors.email) && errors.email.message}
              placeHolder="propally@gmail.com"
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          defaultValue={formData.password || ""}
          rules={{
            required: "パスワードを入力してください。",
            pattern: {
              value: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d!"#$%&'()\-\^@[\];:]{8,72}$/,
              message:
                "※8～72文字の間で設定してください。<br/>※半角英字、半角数字の2種類を使用してください。<br/>【使用可能な記号 】 !\"#$%&''()-^@[;:",
            },
          }}
          render={({ ...field }) => (
            <CustomForm
              {...field}
              label="パスワード"
              type="password"
              required
              autoComplete="new-password"
              error={!!errors.password}
              helperText="半角英数を含めた８文字以上で設定してください。"
              errorText={
                Boolean(errors.password) && <span dangerouslySetInnerHTML={{ __html: errors.password.message }} />
              }
              placeHolder="半角英数８文字"
            />
          )}
        />
        <Controller
          name="password_confirmation"
          control={control}
          defaultValue={formData.password_confirmation || ""}
          rules={{
            required: "パスワード（確認用）を入力してください。",
            validate: (value) => value === control.getValues("password") || "パスワードが一致しません。",
          }}
          render={({ ...field }) => (
            <CustomForm
              {...field}
              label="パスワード（確認用）"
              type="password"
              required
              autoComplete="new-password"
              error={!!errors.password_confirmation}
              errorText={Boolean(errors.password_confirmation) && errors.password_confirmation.message}
              placeHolder="半角英数８文字"
            />
          )}
        />
        <Box display="flex" my={3} mx="auto" justifyContent="center">
          <Typography variant="body1" align="center" noWrap>
            <TextLink href="https://lp.propally.jp/termsofuse.html" bold targetBlank>
              <span className={classes.span}>利用規約</span>
            </TextLink>
            と
            <TextLink href="https://propally.co.jp/privacypolicy.html" bold targetBlank>
              <span className={classes.span}>プライバシーポリシー</span>
            </TextLink>
            に<br />
            同意の上ご利用ください
          </Typography>
        </Box>
        <CustomButton type="button" customVariant="filled" onClick={handleNext}>
          <Typography variant="button">同意して次へ</Typography>
        </CustomButton>
        <Box display="flex" margin="40px auto" justifyContent="center">
          <Typography variant="body1">
            アカウントをお持ちですか？
            <TextLink href="/login" bold>
              <span className={classes.span}>ログイン</span>
            </TextLink>
          </Typography>
        </Box>
      </Box>
    </EntranceTemplate>
  );
};

export default RegisterBasicPage;
