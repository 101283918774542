import { Box, ClickAwayListener, IconButton, Tooltip, Typography, makeStyles } from "@material-ui/core";
import * as React from "react";

import { useDevice } from "../../../utilities/sp/use_device";

import { QuestionCircleIcon } from "./icon";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
}));

interface CustomFormLabelProps {
  label: string;
  labelLight?: string;
  required?: boolean;
  helperText?: string;
  info?: string;
}

const CustomFormLabel: React.FC<CustomFormLabelProps> = ({
  label,
  labelLight,
  required,
  helperText = "",
  info = "",
}) => {
  const classes = useStyles();
  const { isMobile } = useDevice();
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center">
        <Typography variant="h5" color={"textPrimary"}>
          {label}
        </Typography>
        {labelLight && (
          <Typography variant="body2" color={"textSecondary"}>
            {labelLight}
          </Typography>
        )}
        {required && (
          <Typography color="error" variant="caption">
            *
          </Typography>
        )}
        {info &&
          (isMobile ? (
            // モバイルではクリックで表示
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={info}
              >
                <IconButton className={classes.icon} size="small" onClick={handleTooltipOpen}>
                  <QuestionCircleIcon width="13.5" height="13.5" />
                </IconButton>
              </Tooltip>
            </ClickAwayListener>
          ) : (
            <Tooltip title={info}>
              <IconButton className={classes.icon} size="small">
                <QuestionCircleIcon width="13.5" height="13.5" />
              </IconButton>
            </Tooltip>
          ))}
      </Box>
      {helperText && (
        <Typography variant="caption" color="textSecondary">
          {helperText}
        </Typography>
      )}
    </Box>
  );
};

export default CustomFormLabel;
