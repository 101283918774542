import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useState } from "react";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";

import { PropallyApiFactory } from "../../module/custom_api_factory";
import { Card } from "../../scripts/components/card";
import { UserState } from "../../view_models/atoms";

export const UpdateEmail: React.FC<any> = () => {
  const { register, handleSubmit, errors } = useForm();
  const user = useRecoilValue(UserState);
  const [isSubmit, setIsSubmit] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [serverError, setSeverError] = useState<boolean>(false);
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const onSubmit = async (data) => {
    const fp = PropallyApiFactory();
    setDisabled(true);
    try {
      const userPatch = await fp.v1UserPatch({ ...user, ...data }, { withCredentials: true });
      if (userPatch.status === 200) {
        setIsSubmit(true);
        setNewEmail(data.email);
      }
    } catch (e) {
      if (e.response?.status === 401) {
        setMessage("更新に失敗しました。画面をリロードし、再度ログインしてください");
        return;
      }
      setSeverError(true);
    }
    setDisabled(false);
  };

  return (
    <Card>
      <Box alignItems="center" justifyContent="center" display="flex" my={7}>
        <Snackbar
          open={Boolean(message)}
          autoHideDuration={3000}
          message={message}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          {...(serverError && {
            action: (
              <Box>
                更新に失敗しました。以下をお試しください。
                <ul>
                  <li>インターネット接続をご確認ください。</li>
                  <li>
                    ページを
                    <a href="/">更新</a>
                    してください。
                  </li>
                </ul>
                上記でも解決しない場合は
                <a href="https://form.run/@propally-1619963249">報告フォーム</a>
                にてご報告ください。
              </Box>
            ),
          })}
        />
        {isSubmit ? (
          <Box textAlign="center">
            <Typography variant="h5" component="div">
              <Box mb={3} fontWeight="bold">
                確認メール送信完了
              </Box>
            </Typography>
            <Typography variant="body2" component="div">
              新しいメールアドレスに、確認メールを送信しました。
              <br />
              メール内のリンクをクリックして、メールアドレスを更新してください。
              <Box fontWeight="bold">リンクをクリックするまでは、メールアドレスは古いままで更新されません。</Box>
              <Box my={3}>
                <Typography variant="subtitle1">{newEmail}</Typography>
              </Box>
              しばらくお待ちいただいてもメールが届かない場合は、迷惑メールボックスもご確認ください。
              <br />
              それでもメールが見つからない場合は、恐れ入りますがもう一度メールアドレスの変更をお試しください。
            </Typography>
          </Box>
        ) : (
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Box display="flex" mb={5}>
              <Typography variant="body2" component="div">
                <Box mr={3} fontWeight="fontWeightBold">
                  現在のメールアドレス
                </Box>
              </Typography>
              <Box width="240px">
                <Typography variant="body2">{user.email}</Typography>
              </Box>
            </Box>

            <Box display="flex" mb={5}>
              <Typography variant="body2" component="div">
                <Box mr={3} fontWeight="fontWeightBold">
                  新しいメールアドレス
                </Box>
              </Typography>
              <Box width="240px">
                <TextField
                  error={Boolean(errors.email)}
                  placeholder="sample@example.com"
                  name="email"
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                  inputRef={register({
                    required: "入力してください",
                    maxLength: {
                      value: 80,
                      message: "80文字以内で入力してください",
                    },
                    pattern: {
                      value: emailRegex,
                      message: "形式が正しくありません",
                    },
                  })}
                  helperText={Boolean(errors.email) && errors.email.message}
                />
              </Box>
            </Box>
            <Box justifyContent="center" display="flex">
              <Button type="submit" variant="contained" color="primary" size="large" disabled={disabled}>
                更新
              </Button>
            </Box>
          </form>
        )}
      </Box>
    </Card>
  );
};
