import { Box } from "@material-ui/core";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import RegisterBasicPage from "./basic";
import RegisterPhoneNumberPage from "./phone_number";
import RegisterSmsVerificationCodePage from "./sms_code";
import { PostRegisterRequestBody } from "./types";

const RegisterPage: React.FC = () => {
  const methods = useForm<PostRegisterRequestBody>();
  const [currentStep, setCurrentStep] = React.useState(0);

  const validate = React.useCallback(async () => {
    const isValid = await methods.trigger();
    return isValid;
  }, [methods]);

  const steps = [
    <RegisterBasicPage key="step1" onNext={() => setCurrentStep((prev) => prev + 1)} validate={validate} />,
    <RegisterPhoneNumberPage
      key="step2"
      onNext={() => setCurrentStep((prev) => prev + 1)}
      onBack={() => setCurrentStep((prev) => prev - 1)}
      validate={validate}
    />,
    <RegisterSmsVerificationCodePage key="step3" onBack={() => setCurrentStep((prev) => prev - 1)} />,
  ];

  return (
    <FormProvider {...methods}>
      <Box>{steps[currentStep]}</Box>
    </FormProvider>
  );
};

export default RegisterPage;
