import { makeStyles, Box } from "@material-ui/core";
import dayjs from "dayjs";
import * as React from "react";
import { useHistory } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { MonthlyCashflowResult, MonthlyCashflowResultUpdate } from "../../../../../../api/generated";
import { PropallyApiFactory } from "../../../../../../module/custom_api_factory";
import { EditPropertyHistoryItem } from "../../../../../../scripts/components/renewal_v1/property/edit_property_history_item";
import { PropertyManagementDetailSnackbarState, useRequestInitialLoading } from "../../../../../../view_models/atoms";
import { SelectedProperty } from "../../../../../../view_models/property_selectors";

import { loanType } from "./history";
import { LoanInfoHistoryEditor } from "./history_editor";

const useStyles = makeStyles((theme) => ({
  background: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
    padding: 16,
    marginBottom: 8,
  },
  iconRoot: {
    padding: 0,
  },
  textLink: {
    width: 80,
    textAlign: "center",
  },
}));

interface props {
  loanType: loanType;
  monthlyCashflowResult: MonthlyCashflowResult;
}

export const LoanInfoHistoryItem: React.FC<props> = ({ monthlyCashflowResult, loanType }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isShowEdior, setIsShowEditor] = React.useState(false);
  const requestInitialLoading = useRequestInitialLoading();
  // 物件詳細でのスナックバー表示
  const setManagementDetailSnackBarState = useSetRecoilState(PropertyManagementDetailSnackbarState);
  // 選択済み物件取得
  const selected_property = useRecoilValue(SelectedProperty);

  // 編集ボタンクリック時の処理
  const onClickEdit = React.useCallback(() => {
    setIsShowEditor(true);
  }, []);
  // 削除ボタンクリック時の処理
  const onClickDelete = React.useCallback(async () => {
    const fp = PropallyApiFactory();
    let sendData: MonthlyCashflowResultUpdate;
    switch (loanType) {
      case "借入利率変動":
        sendData = {
          month: monthlyCashflowResult.month,
          loan_interest_rate_percentage: null,
        };
        break;
      case "繰上返済":
        sendData = {
          month: monthlyCashflowResult.month,
          loan_prepayment: null,
          loan_prepayment_method: null,
        };
        break;
      default:
        console.error("Invalid loan type");
        return;
    }
    try {
      const response = await fp.v1PropertiesPropertyIdMonthlyCashflowResultsPatch(selected_property.id, sendData, {
        withCredentials: true,
      });
      if (response.status === 200) {
        requestInitialLoading();
      }
    } catch (e) {
      console.error(e);
      setManagementDetailSnackBarState({
        message: "物件情報を変更できませんでした",
        status: "error",
        isOpen: true,
      });
      history.push("/management/detail");
    }
  }, [loanType, monthlyCashflowResult.month, selected_property.id]);

  // 返済方式map
  const loanPrepaymentMethodMap = {
    ReduceLoanAmount: "返済額軽減型",
    ReduceLoanPaymentPeriod: "返済期間短縮型",
  };

  const getHistoryContents = (loanType: loanType, data: MonthlyCashflowResult) => {
    const startMonth = dayjs(data.month).format("YYYY/MM");
    switch (loanType) {
      case "借入利率変動":
        if (!data.loan_interest_rate_percentage) return [];
        return [
          { label: "支払開始月", value: startMonth },
          {
            label: "借入利率 (年利) ",
            value: data.loan_interest_rate_percentage ? `${data.loan_interest_rate_percentage}%` : "-%",
          },
        ];
      case "繰上返済":
        if (!data.loan_prepayment && !data.loan_prepayment_method) return [];
        return [
          { label: "支払開始月", value: startMonth },
          {
            label: "返済金額",
            value: data.loan_prepayment ? `${data.loan_prepayment.toLocaleString()}円` : "-円",
          },
          {
            label: "返済方式",
            value: data.loan_prepayment_method ? loanPrepaymentMethodMap[data.loan_prepayment_method] : "-",
          },
        ];
      default:
        return [];
    }
  };

  if (getHistoryContents(loanType, monthlyCashflowResult).length === 0) {
    return null;
  }

  return (
    <Box className={classes.background}>
      {isShowEdior ? (
        <LoanInfoHistoryEditor
          monthlyCashflowResult={monthlyCashflowResult}
          loanType={loanType}
          onCancel={() => setIsShowEditor(false)}
          onSave={() => setIsShowEditor(false)}
        />
      ) : (
        <EditPropertyHistoryItem
          contents={getHistoryContents(loanType, monthlyCashflowResult)}
          onEdit={onClickEdit}
          onDelete={onClickDelete}
        />
      )}
    </Box>
  );
};
