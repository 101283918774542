import { Box, makeStyles } from "@material-ui/core";
import { useResizeObserver } from "@wojtekmaj/react-hooks";
import * as React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { DocumentCallback } from "react-pdf/dist/cjs/shared/types";

import { Loading } from "../../../../pages/loading";

const useStyles = makeStyles(() => ({
  pdfContainer: {
    display: "flex",
    justifyContent: "center",
    height: "calc(100vh - 64px)", // Adjust height to fill the screen minus the AppBar height
    overflow: "scroll",
    padding: 16,
  },
}));

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const options = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
  cMapPacked: true,
};
const resizeObserverOptions = {};

const maxWidth = 800;

type props = { fileUrl: string; fileBlob?: never } | { fileUrl?: never; fileBlob: Blob };

export const PdfViewerComponent: React.FC<props> = ({ fileUrl, fileBlob }) => {
  const classes = useStyles();

  const [numPages, setNumPages] = React.useState<number>(null);
  const [containerRef, setContainerRef] = React.useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = React.useState<number>();
  const [pdfData, setPdfData] = React.useState<string | null>(null);

  const onDocumentLoadSuccess = (pdf: DocumentCallback): void => {
    setNumPages(pdf.numPages);
  };
  const onResize = React.useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);
  useResizeObserver(containerRef, resizeObserverOptions, onResize);

  React.useEffect(() => {
    if (fileBlob) {
      const url = URL.createObjectURL(fileBlob);
      setPdfData(url);
      return;
    } else if (fileUrl) {
      fetch(fileUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          setPdfData(url);
        })
        .catch((error) => console.error("Error fetching PDF:", error));
    }
  }, [fileUrl, fileBlob]);

  const memoizedFile = React.useMemo(() => {
    return pdfData ? { url: pdfData } : null;
  }, [pdfData]);

  return (
    <div className={classes.pdfContainer} ref={setContainerRef}>
      {pdfData ? (
        <Document file={memoizedFile} onLoadSuccess={onDocumentLoadSuccess} options={options} loading={<Loading />}>
          {pdfData &&
            numPages &&
            Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                loading={<Loading />}
              />
            ))}
        </Document>
      ) : (
        <Loading />
      )}
    </div>
  );
};
