import dayjs from "dayjs";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useRecoilState, useSetRecoilState } from "recoil";

import { Base64EncodedImage, PropertyTemporary, PropertyType } from "../../../../../api/generated";
import { PropallyApiFactory } from "../../../../../module/custom_api_factory";
import { CircularProgressWithLabel } from "../../../../../scripts/components/renewal_v1/property/circular_progress";
import { PropertyOperationTemplate } from "../../../../../scripts/components/renewal_v1/template/property_operation_template";
import { AppEventTokenEnum, sendEventToAppMeasurementTool } from "../../../../../utilities/capacitor_logic";
import { castFieldInputs } from "../../../../../utilities/cast_field_inputs";
import { deleteUnacceptableKeys } from "../../../../../utilities/delete_unacceptable_keys";
import { fileToBase64EncodedImage } from "../../../../../utilities/file_to_base64_encoded_image";
import {
  formDataMappingToLeaseContract,
  formDataMappingToLeaseContractForUpdate,
  formDataMappingToProperty,
  handleFirstRegistration,
  postProperty,
} from "../../../../../utilities/renewal_v1/property_management";
import {
  getStoragePropertyRegistrationState,
  removeStoragePropertyRegistrationState,
} from "../../../../../utilities/storage";
import {
  PropertyManagementListSnackbarState,
  PropertyRegistrationState,
  UserDataFirstRegisteredStatusState,
  useRequestInitialLoading,
} from "../../../../../view_models/atoms";
import { Loading } from "../../../../loading";
import { DEFAULT_ADD_NEW_PROPERTY_FORM_SCHEMA, DateFieldNames, DoubleFieldNames } from "../const";
import { DraftPropertyModal } from "../draft_modal";
import { DraftPropertyReuseModal } from "../draft_modal_reuse";
import { AddNewPropertyFormSchema } from "../type";

import AddNewOwnPropertyBasicPage from "./basic";
import AddNewOwnPropertyCashFlowPage from "./cashflow";
import AddNewOwnPropertyLoanPage from "./loan";

const AddNewOwnPropertyPage: React.FC = () => {
  const isSubmittingRef = React.useRef(false);
  const methods = useForm<AddNewPropertyFormSchema>({ mode: "onChange" });
  const history = useHistory();
  const requestInitialLoading = useRequestInitialLoading();

  const setManagementListSnackBarState = useSetRecoilState(PropertyManagementListSnackbarState);
  const setUserDataFirstRegisteredStatus = useSetRecoilState(UserDataFirstRegisteredStatusState);
  const [isOpenReuseTempoModal, setIsOpenReuseTempoModal] = React.useState(false);

  // LocalStorage
  const [formPersistData, setFormPersistData] = useRecoilState(PropertyRegistrationState);
  const localStorageFormData = getStoragePropertyRegistrationState();
  // フォームの状態
  const [formData, setFormData] = React.useState<AddNewPropertyFormSchema>(formPersistData);

  const [currentStep, setCurrentStep] = React.useState(0);

  const [isClickedPageBack, setIsClickedPageBack] = React.useState(false);
  // 一時保存の状態
  const [propertyTempInfo, setPropertyTempInfo] = React.useState<PropertyTemporary>(null);

  // 初期レンダリング
  // ローカルストレージのデータがある場合は確認モーダルの表示
  React.useEffect(() => {
    const fp = PropallyApiFactory();
    const fetchPropertyRegistrationTemporary = async () => {
      try {
        const response = await fp.v1PropertyTemporaryGet({ withCredentials: true });
        if (response.status === 200) {
          if (response.data?.updated_at && localStorageFormData !== null) {
            setIsOpenReuseTempoModal(true);
            setPropertyTempInfo(response.data);
          }
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchPropertyRegistrationTemporary();
  }, []);

  // 一時保存関数
  const saveTemporary = async () => {
    await methods.trigger();
    // DBに一時保存データがあるかどうかフラグを保存
    const fp = PropallyApiFactory();
    fp.v1PropertyTemporaryPost({ withCredentials: true }).then(() => {
      console.log("一時保存しました");
    });

    // ローカルストレージの更新
    setFormPersistData({ ...formData, ...methods.getValues() });
  };

  const onClickPageNext = React.useCallback(() => {
    window.scrollTo(0, 0);
    setCurrentStep((prev) => prev + 1);
  }, []);

  const onClickPageBack = React.useCallback(() => {
    window.scrollTo(0, 0);
    setCurrentStep((prev) => prev - 1);
  }, []);

  const onClickBack = React.useCallback(() => {
    window.scrollTo(0, 0);
    setIsClickedPageBack(true);
  }, []);

  // ==== 一時保存情報の利用 ====
  const onClickReuseTempo = async () => {
    setIsOpenReuseTempoModal(false);
  };
  // ==== 物件一時保存情報削除 ====
  const deletePropertyTemporary = async () => {
    const fp = PropallyApiFactory();
    try {
      const response = await fp.v1PropertyTemporaryDelete({ withCredentials: true });
      if (response.status === 200) {
        // フォームのデフォルト値を削除
        setFormData(DEFAULT_ADD_NEW_PROPERTY_FORM_SCHEMA);
        setFormPersistData(DEFAULT_ADD_NEW_PROPERTY_FORM_SCHEMA);
        // ローカルストレージの削除
        removeStoragePropertyRegistrationState();
        // フォームの値をリセット
        methods.reset(DEFAULT_ADD_NEW_PROPERTY_FORM_SCHEMA);
        // 一時保存情報の削除
        setPropertyTempInfo(null);
      }
    } catch (e) {
      console.error(e);
    }
    setIsOpenReuseTempoModal(false);
  };

  const saveTemporaryAndGoBack = async () => {
    await saveTemporary();
    history.push("/management");
  };
  const cancelSaveTemporaryAndGoBack = async () => {
    await deletePropertyTemporary();
    history.push("/management");
  };
  // 各ページでの処理
  const validate = async () => {
    const isValid = await methods.trigger();
    if (isValid) {
      const saveFormData = {
        ...formData,
        ...methods.getValues(),
      };
      console.log("saveFormData", saveFormData);
      console.log("image", saveFormData.property_image);
      setFormData(saveFormData);
      // LocalStorage
      setFormPersistData(saveFormData);
      console.log("valid");

      let base64_encoded_image: Base64EncodedImage;
      const image = saveFormData.property_image;

      if (image instanceof File) {
        base64_encoded_image = await fileToBase64EncodedImage(image);
      }
      // delete saveFormData.property_image;
      console.log("base64_encoded_image", base64_encoded_image);
    }
    return isValid;
  };

  const onSubmit = React.useCallback(async () => {
    console.log("submit");
    // 二重送信防止
    if (isSubmittingRef.current) return;
    isSubmittingRef.current = true;

    // LocalStorage
    setFormPersistData(formPersistData);

    console.log("saved");

    const fp = PropallyApiFactory();

    let data = {
      ...methods.getValues(),
      ...formData,
    };
    console.log("data", data);

    let base64_encoded_image: Base64EncodedImage;
    // 画像のアップロード処理
    const image = data.property_image;

    if (image) {
      base64_encoded_image = await fileToBase64EncodedImage(image);
    }
    delete data.property_image;

    // cut off the undefined/non-entered values
    data = deleteUnacceptableKeys(data);

    // cast date & double fields
    data = castFieldInputs(data, { dateFieldNames: DateFieldNames, doubleFieldNames: DoubleFieldNames });

    // Property型に変換
    const property = formDataMappingToProperty(data, PropertyType.NUMBER_0);

    console.log("property", property);

    // LeaseContract型に変換
    const lease_contract = formDataMappingToLeaseContract(data);

    // PropertyBuilder型に変換
    const property_builder = formDataMappingToLeaseContractForUpdate(data, property, lease_contract);

    console.log("property_builder", property_builder);

    try {
      const postResponse = await postProperty(property_builder);
      console.log("postResponse", postResponse);
      if (base64_encoded_image) {
        await fp.v1PropertiesPropertyIdImagesPost(postResponse.data.id, base64_encoded_image, {
          withCredentials: true,
        });
      }

      // 物件一時保存情報削除
      await fp.v1PropertyTemporaryDelete({ withCredentials: true });

      await handleFirstRegistration(setUserDataFirstRegisteredStatus);
    } catch (e) {
      console.error(e);
      // 物件一覧ページでのスナックバー表示用
      setManagementListSnackBarState({ isOpen: true, message: "物件を追加できませんでした", status: "error" });
      history.push("/management");
      return;
    }

    // if(!isEdit && String(property.property_type) === String(PROPERTY_TYPE_VALUES.owned)) {
    //   // 新規登録 且つ 保有物件の場合のみエージェントに新着通知
    //   await fp.v1NotificationsToAgentForNewPropertyPost( { withCredentials: true });
    // }

    // データの初期化
    setFormData(DEFAULT_ADD_NEW_PROPERTY_FORM_SCHEMA);
    setFormPersistData(DEFAULT_ADD_NEW_PROPERTY_FORM_SCHEMA);
    // ローカルストレージの物件情報の削除
    removeStoragePropertyRegistrationState();
    console.log("success");
    // 物件一覧ページでのスナックバー表示用
    setManagementListSnackBarState({ isOpen: true, message: "物件を追加しました", status: "success" });
    requestInitialLoading();

    sendEventToAppMeasurementTool(AppEventTokenEnum.OwnPropertyCompleted);

    history.push("/management");

    isSubmittingRef.current = false; // 送信完了後、二重送信管理フラグをリセット
    console.log("complete");
  }, [formData]);

  const steps = [
    <AddNewOwnPropertyBasicPage key="basic" onNext={onClickPageNext} validate={validate} />,
    <AddNewOwnPropertyLoanPage key="loan" onNext={onClickPageNext} onBack={onClickPageBack} validate={validate} />,
    <AddNewOwnPropertyCashFlowPage key="cashflow" onBack={onClickPageBack} validate={validate} />,
  ];
  const stepText = [
    {
      title: "物件概要の入力",
      message: "物件に関する情報を記入してください",
    },
    {
      title: "価格・ローン",
      message: "価格・ローンに関する情報を記入してください",
    },
    {
      title: "月間収支",
      message: "月間収支に関する情報を記入してください",
    },
  ];

  // ローカルストレージのデータをフォームにセットしてレンダリングをする前にモーダルで確認
  if (isOpenReuseTempoModal) {
    return (
      <PropertyOperationTemplate title="物件登録" onBack={onClickBack}>
        <Loading />
        <DraftPropertyReuseModal
          onClose={() => setIsOpenReuseTempoModal(false)}
          onClickUse={onClickReuseTempo}
          onClickDelete={deletePropertyTemporary}
          lastSavedDate={dayjs(propertyTempInfo?.updated_at).format("YYYY年MM月DD日 HH:mm")}
        />
      </PropertyOperationTemplate>
    );
  }

  return (
    <PropertyOperationTemplate title="物件登録" onBack={onClickBack}>
      <CircularProgressWithLabel
        value={currentStep + 1}
        stepName={stepText[currentStep].title}
        stepMessage={stepText[currentStep].message}
      />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>{steps[currentStep]}</form>
      </FormProvider>
      {isClickedPageBack ? (
        <DraftPropertyModal
          onClose={() => setIsClickedPageBack(false)}
          onClickSave={saveTemporaryAndGoBack}
          onClickCancel={cancelSaveTemporaryAndGoBack}
        />
      ) : null}
    </PropertyOperationTemplate>
  );
};

export default AddNewOwnPropertyPage;
