export const ColorScheme = {
  PrBlue: "#526DC0",
  Strawberry: "#FF684A",
  Orange: "#F98B1A",

  Mint: "#309CFF",
  LightGray: "#D5DAE4",
  Emerald: "#26EA95",
  Black: "#424750",
};
