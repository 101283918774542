import { makeStyles, Box, Typography } from "@material-ui/core";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    borderLeft: `3px solid ${theme.palette.primary.main}`,
    padding: "4px 0 4px 8px",
    marginBottom: theme.spacing(2),
  },
}));

interface props {
  text: string;
}

export const OfferDetailHeadline: React.FC<props> = ({ text }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography variant="h4" color="primary">
        {text}
      </Typography>
    </Box>
  );
};
