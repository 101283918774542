import { Box, Typography, makeStyles } from "@material-ui/core";
import React, { FC, useState, useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useRecoilState } from "recoil";

import { GetMasterResidenceTypes } from "../../../api/get_master_data";
import { PREFECTURES } from "../../../api/options/account";
import { CustomButton } from "../../../scripts/components/renewal_v1/button";
import { CustomForm } from "../../../scripts/components/renewal_v1/form";
import { CustomSelector } from "../../../scripts/components/renewal_v1/selecter";
import { RegistrationTemplate } from "../../../scripts/components/renewal_v1/template/registration_template";
import { UserRegistrationsAccoutFormState } from "../../../view_models/atoms";

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      borderBottom: "none",
    },
  },
  errorMessage: {
    // color: theme.palette.error.main,
    color: "#DE351F",
    marginTop: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
  },
}));

interface RegisterResidencePageProps {
  totalSteps: number;
  currentStep: number;
  onNext: () => void;
  onBack: () => void;
  validate: () => Promise<boolean>;
}

const RegisterResidencePage: FC<RegisterResidencePageProps> = ({
  totalSteps,
  currentStep,
  onNext,
  onBack,
  validate,
}) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
    setError,
  } = useFormContext();
  const watchValues = useWatch({
    name: ["current_residence_type_id"],
    control: control,
  });
  const [masterResidenceTypes, setMasterResidenceTypes] = useState<Array<any>>([]);
  const [serverError, setSeverError] = useState<string>("");
  const [formData] = useRecoilState(UserRegistrationsAccoutFormState);
  // 現在のお住まい入力内容により表示フォームを変更するために必要な判定用変数
  const [selectedCurrentResidenceTypeId, setSelectedCurrentResidenceTypeId] = useState<number>(0);

  const handleNext = async () => {
    const addressPrefecture = control.getValues("address_prefecture");
    const currentResidenceTypeId = control.getValues("current_residence_type_id");
    if (!addressPrefecture) {
      setError("address_prefecture", { type: "manual", message: "お住まいの地域（都道府県）を選択してください。" });
    }
    if (currentResidenceTypeId === 0) {
      setError("current_residence_type_id", { type: "manual", message: "お住まいの家を選択してください。" });
    }
    const isValid = await validate();
    if (isValid && errors.address_prefecture === undefined && errors.current_residence_type_id === undefined) {
      onNext();
    }
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      {
        const r = await GetMasterResidenceTypes();
        if (isMounted) {
          if (!r.success) {
            return setSeverError(r.error);
          }
          setMasterResidenceTypes(r.masterResidenceTypes);
        }
      }

      return undefined;
    })();
    // formDataが存在する場合に初期値を設定
    control.setValue("address_prefecture", formData.address_prefecture);
    control.setValue("current_residence_type_id", formData.current_residence_type_id);
    return () => {
      isMounted = false;
    };
  }, []);

  // 現在のお住まい入力内容により表示フォームを変更するためのuseEffect
  useEffect(() => {
    setSelectedCurrentResidenceTypeId(watchValues.current_residence_type_id);
  }, [watchValues.current_residence_type_id]);

  // selectedCurrentResidenceTypeIdが変更された際に、home_loan_amountとmonthly_rent_payment_amountの初期値を設定する
  useEffect(() => {
    control.setValue("home_loan_amount", formData.home_loan_amount);
    control.setValue("monthly_rent_payment_amount", formData.monthly_rent_payment_amount);
  }, [selectedCurrentResidenceTypeId]);

  const prefectureOptions = PREFECTURES.map((prefecture) => ({ value: prefecture, label: prefecture }));
  const residenceTypeOptions = masterResidenceTypes.map((residenceType) => ({
    value: residenceType.id,
    label: residenceType.name,
  }));

  return (
    <RegistrationTemplate
      title="現在のお住まいについて教えてください"
      totalSteps={totalSteps}
      currentStep={currentStep}
    >
      {/* エラーメッセージ表示用 */}
      {serverError && (
        <Typography variant="caption" className={classes.errorMessage}>
          {serverError}
        </Typography>
      )}
      <Box mt={4}>
        <Controller
          name="address_prefecture"
          control={control}
          defaultValue={formData.address_prefecture || ""}
          rules={{ required: "お住まいの地域（都道府県）を選択してください。" }}
          render={({ ...field }) => (
            <CustomSelector
              {...field}
              label="お住まいの地域（都道府県）"
              error={!!errors.address_prefecture}
              errorText={errors.address_prefecture?.message}
              onChange={field.onChange}
              options={prefectureOptions}
              placeHolder="選択してください"
            />
          )}
        />
        <Controller
          name="current_residence_type_id"
          control={control}
          defaultValue={formData.current_residence_type_id || ""}
          rules={{ required: "お住まいの家を選択してください。" }}
          render={({ ...field }) => (
            <CustomSelector
              {...field}
              label="お住まいの家"
              error={!!errors.current_residence_type_id}
              errorText={errors.current_residence_type_id?.message}
              onChange={field.onChange}
              options={residenceTypeOptions}
              placeHolder="選択してください"
            />
          )}
        />
        {selectedCurrentResidenceTypeId === 1 && (
          <Controller
            name="home_loan_amount"
            control={control}
            defaultValue={formData.home_loan_amount || ""}
            rules={{
              required: "住宅ローン残高を入力してください。",
              pattern: {
                value: /^[0-9]+$/,
                message: "半角数字で入力してください。",
              },
            }}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="住宅ローン残高"
                error={!!errors.home_loan_amount}
                errorText={errors.home_loan_amount?.message}
                onChange={field.onChange}
                placeHolder="入力してください"
                unitSuffix="万円"
              />
            )}
          />
        )}
        {selectedCurrentResidenceTypeId === 2 && (
          <Controller
            name="monthly_rent_payment_amount"
            control={control}
            defaultValue={formData.monthly_rent_payment_amount || ""}
            rules={{
              required: "家賃を入力してください。",
              pattern: {
                value: /^[0-9]+$/,
                message: "半角数字で入力してください。",
              },
            }}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="家賃"
                error={!!errors.monthly_rent_payment_amount}
                errorText={errors.monthly_rent_payment_amount?.message}
                onChange={field.onChange}
                placeHolder="入力してください"
                unitSuffix="円"
              />
            )}
          />
        )}
      </Box>
      <Box mt={3}>
        <CustomButton type="button" customVariant="filled" onClick={handleNext}>
          次へ
        </CustomButton>
        <Box display="flex" margin="32px auto" justifyContent="center">
          <Typography variant="button" className={classes.link} onClick={onBack}>
            戻る
          </Typography>
        </Box>
      </Box>
    </RegistrationTemplate>
  );
};

export default RegisterResidencePage;
