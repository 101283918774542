import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  circleButton: {
    borderRadius: "50%",
    border: `1px solid ${theme.palette.grey[300]}`,
    width: 48,
    height: 48,
    margin: theme.spacing(1),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  pageNumber: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    border: `1px solid ${theme.palette.grey[300]}`,
    width: 48,
    height: 48,
    margin: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: "1.25rem",
    backgroundColor: theme.palette.background.paper,
  },
}));

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (_page: number) => void;
}
const CustomPagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const classes = useStyles();

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  return (
    <Box className={classes.root}>
      <IconButton className={classes.circleButton} onClick={() => handlePageChange(1)} disabled={currentPage === 1}>
        <FirstPageIcon />
      </IconButton>
      <IconButton
        className={classes.circleButton}
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <ChevronLeftIcon />
      </IconButton>
      <Box className={classes.pageNumber}>
        <Typography>{String(currentPage)}</Typography>
      </Box>
      <IconButton
        className={classes.circleButton}
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <ChevronRightIcon />
      </IconButton>
      <IconButton
        className={classes.circleButton}
        onClick={() => handlePageChange(totalPages)}
        disabled={currentPage === totalPages}
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
};

export default CustomPagination;
