import { useEffect, useState } from "react";

import { Property } from "../api/generated";
import { PropallyApiFactory } from "../module/custom_api_factory";

export const useGetProperties = () => {
  const [properties, setProperties] = useState<Property[]>([]);

  const fetchProperties = async () => {
    const fp = PropallyApiFactory();
    try {
      const data = await fp.v1PropertiesGet({ withCredentials: true });
      if (data.status === 200) {
        setProperties(data.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchProperties();
  }, []);

  return { properties };
};
