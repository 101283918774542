import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import * as React from "react";

import { StyledModal } from "../../../../scripts/components/renewal_v1/styled_modal";

const useStyles = makeStyles((theme) => ({
  messageContent: {
    padding: "24px 24px 0 24px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
    borderTop: "1px solid #ccc",
    padding: theme.spacing(2, 3, 2, 0),
  },
  button: {
    minWidth: 100,
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    borderRadius: 8,
    backgroundColor: theme.palette.background.default,
  },
  description: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  deleteButton: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.main,
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#f8bbd0",
    },
  },
  link: {
    textDecoration: "none",
    cursor: "pointer",
    padding: "8px 7px",
    "&:hover": {
      borderBottom: "none",
    },
  },
}));

interface DraftPropertyReuseModalProps {
  onClose: () => void;
  onClickUse: () => Promise<void>;
  onClickDelete: () => void;
  lastSavedDate: string;
}
export const DraftPropertyReuseModal: React.FC<DraftPropertyReuseModalProps> = ({
  onClose,
  onClickUse,
  onClickDelete,
  lastSavedDate,
}) => {
  const classes = useStyles();
  const [isClickedDeleteIcon, setIsClickedDeleteIcon] = React.useState(false);
  const handleClick = React.useCallback(() => {
    document.body.style.overflow = "auto"; // Enable scrolling
    onClose();
  }, [onClose]);

  // スクロール制御
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "hidden"; // Disable scroll when modal is open
    return () => {
      document.body.style.overflow = "auto"; // Enable scroll when modal is unmounted
    };
  }, []);

  const handleDelete = React.useCallback(() => {
    onClickDelete();
  }, [onClickDelete]);

  return (
    <StyledModal onClickOutside={handleClick}>
      <Box className={classes.messageContent}>
        <Box textAlign="center" mb={2}>
          <Typography variant="h3">
            前回保存した下書きを
            <br />
            利用しますか？
          </Typography>
        </Box>
        <Box className={classes.container}>
          <Typography variant="body2" className={classes.description}>
            前回下書きを保存した日時：
            <br />
            {lastSavedDate}
          </Typography>
          <IconButton className={classes.deleteButton} onClick={() => setIsClickedDeleteIcon(true)}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.buttonContainer}>
        {isClickedDeleteIcon ? (
          <>
            <Typography variant="button" color="primary" className={classes.link} onClick={onClose}>
              キャンセル
            </Typography>
            <Typography variant="button" color="error" className={classes.link} onClick={handleDelete}>
              削除する
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="button" color="primary" className={classes.link} onClick={handleDelete}>
              利用しない
            </Typography>
            <Typography variant="button" color="primary" className={classes.link} onClick={onClickUse}>
              利用する
            </Typography>
          </>
        )}
      </Box>
    </StyledModal>
  );
};
