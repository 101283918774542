import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import React = require("react");
import { Control, Controller, FieldValues, ErrorOption } from "react-hook-form";

type Props = {
  configured: boolean;
  control: Control<FieldValues>;
  name: string;
  rules?: Object;
  defaultValue: any;
  errorField: any;
  onChangePlus?: () => void;
  setValue: any;
  setError: (_name: string, _error: ErrorOption) => void;
  clearErrors: (_name?: string | string[]) => void;
};

const useStyles = makeStyles(() => ({
  image: {
    maxHeight: "250px",
    width: "auto",
    maxWidth: "500px",
  },
  image_input: {
    opacity: "0",
    appearance: "none",
    position: "absolute",
  },
}));

// 制限サイズ
const sizeLimit = 1024 * 1024 * 20;

export const UploadImage: React.FC<Props> = ({
  configured,
  control,
  name,
  rules,
  defaultValue,
  setValue,
  errorField,
  setError,
  clearErrors,
}) => {
  const classes = useStyles();

  const deleteImage = () => {
    setValue(name, "");
  };

  return (
    <>
      <Controller
        defaultValue={defaultValue}
        control={control}
        name={name}
        rules={rules}
        render={({ onChange, value }) => (
          <>
            {configured ? (
              <Box m={1}>
                <Typography variant="caption">設定済。新しく選択すると更新できます。</Typography>
              </Box>
            ) : (
              ""
            )}
            <Box m={1}>
              <Button size="small" variant="text" color="primary" startIcon={<CloudUploadIcon />}>
                画像を選択
                <Input
                  className={classes.image_input}
                  type="file"
                  name="image"
                  inputProps={{ accept: "image/*" }}
                  fullWidth
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files[0]) {
                      if (e.target.files[0].size > sizeLimit) {
                        setError(name, {
                          type: "manual",
                          message: "20MB以下のファイルを選択してください。",
                        });
                        // この処理を行わないとリロードしないと再アップロードできなくなる
                        e.target.value = "";
                        return;
                      }
                      onChange(e.target.files[0]);
                      clearErrors(name);
                      // この処理を行わないとリロードしないと再アップロードできなくなる
                      e.target.value = "";
                    }
                  }}
                />
              </Button>
            </Box>
            {value === "" ? (
              <></>
            ) : (
              <>
                <Box m={1}>
                  <Typography variant="subtitle1">{value.name}</Typography>
                </Box>
                <Box m={1}>
                  <img className={classes.image} src={URL.createObjectURL(value)} alt="選択したお写真" />
                </Box>
                <Box m={1}>
                  <Button
                    startIcon={<DeleteIcon />}
                    size="small"
                    variant="text"
                    color="secondary"
                    disabled={false}
                    onClick={() => {
                      deleteImage();
                    }}
                  >
                    写真を削除
                  </Button>
                </Box>
              </>
            )}
          </>
        )}
      />
      {!!errorField && errorField.message && <FormHelperText error>{errorField.message}</FormHelperText>}
    </>
  );
};
