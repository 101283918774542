import { makeStyles, Box, Typography } from "@material-ui/core";
import * as React from "react";
import { useRecoilValue } from "recoil";

import { CustomButton } from "../../../../../../scripts/components/renewal_v1/button";
import { PropertyOperationTemplate } from "../../../../../../scripts/components/renewal_v1/template/property_operation_template";
import { SelectedProperty } from "../../../../../../view_models/property_selectors";

import { ManagementCostHistoryEditor } from "./history_editor";
import { ManagementCostHistoryItem } from "./histroy_item";

const useStyles = makeStyles((theme) => ({
  content: {
    width: 640,
    [theme.breakpoints.down("sm")]: {
      width: 342,
    },
    margin: "16px auto 0px auto",
  },

  background: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
    padding: 16,
    marginBottom: 8,
  },
}));

export type costType = "賃貸管理費" | "建物管理費" | "修繕積立金" | "その他経費";

interface EditPropertySectionManagementCostProps {
  costType: costType;
  onClickPageBack: () => void;
}

export const EditPropertySectionManagementCostHistory: React.FC<EditPropertySectionManagementCostProps> = ({
  costType,
  onClickPageBack,
}) => {
  const classes = useStyles();
  // 選択済み物件取得
  const selected_property = useRecoilValue(SelectedProperty);
  const [isShowNewHistory, setIsShowNewHistory] = React.useState(false);

  /**
   * useEffect
   */

  /*
   関数定義
  */

  const handleAddNewHistory = () => {
    setIsShowNewHistory(true);
  };

  const handleOnCreate = () => {
    setIsShowNewHistory(false);
  };

  return (
    <PropertyOperationTemplate title="過去履歴" onBack={onClickPageBack}>
      <Box>
        <Box className={classes.content}>
          <Box mb={4}>
            <Box mb={2}>
              <Typography variant="h2">{costType}</Typography>
            </Box>
            {selected_property?.user_input_monthly_cashflow_results.map((result, index) => {
              return <ManagementCostHistoryItem key={index} monthlyCashflowResult={result} costType={costType} />;
            })}
            {isShowNewHistory && (
              <Box className={classes.background}>
                <ManagementCostHistoryEditor
                  costType={costType}
                  onCancel={() => setIsShowNewHistory(false)}
                  onCreate={handleOnCreate}
                />
              </Box>
            )}
          </Box>
        </Box>
        <CustomButton type="button" customVariant="outlinedPrimary" onClick={handleAddNewHistory}>
          新規追加
        </CustomButton>
      </Box>
    </PropertyOperationTemplate>
  );
};
