import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";

const useStyles = makeStyles<Theme, TextLinkStyleProps>((theme) => ({
  link: {
    color: (props) => (props.bold ? theme.palette.info.main : theme.palette.text.primary),
    textDecoration: "none",
    cursor: "pointer",
    fontWeight: (props) => (props.bold ? "bold" : "normal"),
    borderBottom: (props) => (props.underline ? `1px solid ${theme.palette.text.primary}` : "none"),
    "&:hover": {
      borderBottom: (props) => (props.underline ? `1px solid ${theme.palette.text.primary}` : "none"),
    },
  },
}));

interface TextLinkStyleProps {
  underline?: boolean;
  bold?: boolean;
}
interface TextLinkProps {
  href: string;
  children: React.ReactNode;
  onClick?: () => void;
  targetBlank?: boolean;
}

export const TextLink: React.FC<TextLinkProps & TextLinkStyleProps> = ({
  href,
  children,
  underline = false,
  bold = false,
  onClick,
  targetBlank = false,
}) => {
  const classes = useStyles({ underline, bold });

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) {
      event.preventDefault();
      onClick();
    }
  };
  if (targetBlank) {
    return (
      <Typography
        component="a"
        href={href}
        className={classes.link}
        onClick={handleClick}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </Typography>
    );
  }
  return (
    <Typography component="a" href={href} className={classes.link} onClick={handleClick}>
      {children}
    </Typography>
  );
};
